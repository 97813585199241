<template>
  <v-select
    :value="value"
    @input="value => $emit('input', value)"
    :items="items"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    item-text="name"
    multiple
    return-object
    outlined
    hide-details
    dense
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggle">
        <v-list-item-action>
          <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">{{
            icon
          }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
            allSelected ? 'Deselect all' : 'Select All'
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>
<script>
export default {
  name: 'MultiSelect',
  props: {
    value: [Array],
    items: [Array],
    label: [String],
    loading: [Boolean],
    disabled: [Boolean],
  },
  data() {
    return {};
  },
  computed: {
    allSelected() {
      return this.items.length === this.value.length;
    },
    someSelected() {
      return this.value.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box';
      if (this.someSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.$emit('input', []);
        } else {
          // Emit a copy of the input items.
          this.$emit('input', this.items.slice());
        }
      });
    },
  },
};
</script>
