<template>
  <v-row justify="center">
    <v-col cols="12">
      <multi-select
        label="Power brand"
        v-model="powerBrandSelection"
        :items="powerBrandCatalog"
        :loading="loadingCatalogs"
        :disabled="loadingCatalogs || disabled"
      ></multi-select>
    </v-col>
    <v-col v-show="showMarketTree" cols="12">
      <hierarchy-tree
        label="Market selection"
        v-model="marketSelection"
        :catalog="marketCatalog"
        :catalogLevels="marketCatalogLevels"
        :loading="loadingCatalogs"
        :disabled="loadingCatalogs || disabled"
      ></hierarchy-tree>
    </v-col>
    <v-col cols="12">
      <multi-select
        label="Pillar"
        v-model="pillarSelection"
        :items="pillarCatalog"
        :loading="loadingCatalogs"
        :disabled="loadingCatalogs || pillarCatalog.length === 0 || disabled"
      ></multi-select>
    </v-col>
    <v-col cols="12">
      <multi-select
        label="Category"
        v-model="categorySelection"
        :items="categoryCatalog"
        :loading="loadingCatalogs"
        :disabled="loadingCatalogs || categoryCatalog.length === 0 || disabled"
      ></multi-select>
    </v-col>
    <v-col cols="12">
      <multi-select
        label="Segment"
        v-model="segmentSelection"
        :items="segmentCatalog"
        :loading="loadingCatalogs"
        :disabled="loadingCatalogs || segmentCatalog.length === 0 || disabled"
      ></multi-select>
    </v-col>
    <v-col cols="12">
      <multi-select
        label="Subsegment"
        v-model="subsegmentSelection"
        :items="subsegmentCatalog"
        :loading="loadingCatalogs"
        :disabled="
          loadingCatalogs || subsegmentCatalog.length === 0 || disabled
        "
      ></multi-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { GLOBAL, AREA, REGION, COUNTRY } from '@/global/filters';
import MultiSelect from './MultiSelect';
import HierarchyTree from './HierarchyTree';

const productFilterNames = [
  'powerBrand',
  'pillar',
  'category',
  'segment',
  'subsegment',
];

/**
 * For hierarchical items, merge a selection of parent objects into unique child objects.
 * E.g. A selection of pillars is merged to return unique categories whose children are
 *      segments: categoryCatalog = mergeCatalogs(pillars, 'category', 'segment')
 */
function mergeCatalogs(selectedParents, mergedProp, childrenProp = null) {
  const objectsToMerge = selectedParents
    .flatMap(parent => parent[mergedProp])
    .filter(mergedObj => mergedObj.name !== null);

  const uniqueNames = [...new Set(objectsToMerge.map(obj => obj.name))].sort();

  if (childrenProp) {
    return uniqueNames.map(uniqueName => {
      // Children don't have to recursively merge because next catalog will do it.
      const nonUniqueChildren = objectsToMerge
        .filter(obj => obj.name === uniqueName)
        .flatMap(obj => obj[childrenProp]);
      return { name: uniqueName, [childrenProp]: nonUniqueChildren };
    });
  } else return uniqueNames.map(name => ({ name }));
}

export default {
  props: ['filters', 'projectConfig', 'disabled'],
  components: {
    MultiSelect,
    HierarchyTree,
  },
  data() {
    return {
      powerBrandCatalog: [],
      marketCatalog: [],
      marketSelection: {},
      // Product selection models
      powerBrandSelection: [],
      pillarSelection: [],
      categorySelection: [],
      segmentSelection: [],
      subsegmentSelection: [],
      // Sync helpers
      filtersOutUnwatch: null,
      oldConfig: {
        gbu: this.projectConfig.gbu,
        scope: this.projectConfig.scope,
        marketDimension: this.projectConfig.marketDimension,
        productDimension: this.projectConfig.productDimension,
        dumpDate: this.projectConfig.dumpDate,
      },
    };
  },
  computed: {
    ...mapState('filterCatalog', ['loadingCatalogs']),
    ...mapGetters('filterCatalog', ['getPowerBrandCatalog', 'getAreaCatalog']),
    marketCatalogLevels() {
      const index = !this.projectConfig.marketDimension
        ? 9 // if marketDimension is undefined, show every level
        : [AREA, REGION, COUNTRY].indexOf(this.projectConfig.marketDimension);
      return ['area', 'region', 'country'].slice(0, index + 1);
    },
    showMarketTree() {
      return (
        !this.projectConfig.marketDimension ||
        this.projectConfig.marketDimension !== GLOBAL
      );
    },
    pillarCatalog() {
      this.cleanUpPillar();
      const powerBrandSource =
        this.powerBrandSelection.length > 0
          ? this.powerBrandSelection
          : this.powerBrandCatalog;
      return mergeCatalogs(powerBrandSource, 'pillar', 'category');
    },
    categoryCatalog() {
      this.cleanUpCategory();
      return mergeCatalogs(this.pillarSelection, 'category', 'segment');
    },
    segmentCatalog() {
      this.cleanUpSegment();
      return mergeCatalogs(this.categorySelection, 'segment', 'subsegment');
    },
    subsegmentCatalog() {
      this.cleanUpSubsegment();
      return mergeCatalogs(this.segmentSelection, 'subsegment');
    },
    filtersOut() {
      const filtersOut = productFilterNames.reduce((filters, name) => {
        filters[name] = this[`${name}Selection`].map(p => p.name);
        return filters;
      }, {});
      return {
        ...filtersOut,
        area: [],
        region: [],
        country: [],
        ...this.marketSelection,
      };
    },
  },
  watch: {
    projectConfig: {
      handler(newConfig) {
        if (this.isValidConfig(newConfig)) {
          if (
            newConfig.scope !== this.oldConfig.scope ||
            newConfig.productDimension !== this.oldConfig.productDimension ||
            newConfig.dumpDate !== this.oldConfig.dumpDate
          ) {
            // After reloading catalogs, trigger selection updates to
            // propagate new product catalogs down the hierarchy.
            this.reloadCatalogs().then(() =>
              this.updateSelections(this.filters)
            );
          } else if (
            newConfig.marketDimension !== this.oldConfig.marketDimension &&
            newConfig.marketDimension !== GLOBAL &&
            ![AREA, REGION, COUNTRY].includes(this.oldConfig.marketDimension)
          ) {
            this.marketSelection = this.makeMarketSugestion();
          }
        }
        // Maintaining old config manually because it's not deep copied.
        this.oldConfig = {
          scope: newConfig.scope,
          marketDimension: newConfig.marketDimension,
          productDimension: newConfig.productDimension,
          dumpDate: newConfig.dumpDate,
        };
      },
      deep: true,
    },
    filters(newFilters) {
      const filtersAreEqual = [
        ...productFilterNames,
        'area',
        'region',
        'country',
      ].every(
        name =>
          newFilters[name] &&
          this.filtersOut[name] &&
          newFilters[name].length === this.filtersOut[name].length &&
          newFilters[name].every(e => this.filtersOut[name].includes(e))
      );
      if (!filtersAreEqual) {
        this.updateSelections(newFilters);
      }
    },
  },
  created() {
    if (this.isValidConfig(this.projectConfig)) {
      this.updateSelections(this.filters);
    }
  },
  methods: {
    ...mapActions('filterCatalog', ['getFilters']),
    isValidConfig(config) {
      // Check configurations needed to load catalogs.
      return (
        config != null &&
        config.gbuId != null &&
        config.scopeId != null &&
        config.dumpDate != null &&
        config.productDimension != null
      );
    },
    makeMarketSugestion() {
      return {
        area: this.marketCatalog.map(area => area.name),
        region: [],
        country: [],
      };
    },
    async reloadCatalogs() {
      await this.getFilters(this.projectConfig);
      this.powerBrandCatalog = this.getPowerBrandCatalog(this.projectConfig);
      this.marketCatalog = this.getAreaCatalog(this.projectConfig);
    },
    async updateSelections(newFilters) {
      // Unwatch filtersOut while updating the selections.
      if (this.filtersOutUnwatch) this.filtersOutUnwatch();

      if (this.powerBrandCatalog.length == 0) {
        await this.reloadCatalogs();
      }

      if (newFilters.area && newFilters.area.length == 0) {
        newFilters = { ...newFilters, ...this.makeMarketSugestion() };
      }
      // Update selections based on the intersection of catalogs and newFilters.
      productFilterNames.forEach(name => {
        if (newFilters[name])
          this[`${name}Selection`] = this[`${name}Catalog`].filter(f =>
            newFilters[name].includes(f.name)
          );
        else this[`${name}Selection`] = [];
      });
      this.marketSelection = {
        area: newFilters.area,
        region: newFilters.region,
        country: newFilters.country,
      };

      this.filtersOutUnwatch = this.$watch('filtersOut', newFiltersOut => {
        this.$emit('update:filters', newFiltersOut);
      });
    },
    cleanUpPillar() {
      // If no powerbrand is selected, pillar can be any available one. Else:
      if (this.powerBrandSelection.length > 0) {
        const allowedPillars = this.powerBrandSelection
          .flatMap(p => p.pillar)
          .flatMap(c => c.name);

        this.pillarSelection = this.pillarSelection.filter(c =>
          allowedPillars.includes(c.name)
        );
      }
    },
    cleanUpCategory() {
      const allowedCategories = this.pillarSelection
        .flatMap(p => p.category)
        .flatMap(c => c.name);

      this.categorySelection = this.categorySelection.filter(c =>
        allowedCategories.includes(c.name)
      );
    },
    cleanUpSegment() {
      const allowedSegments = this.categorySelection
        .flatMap(c => c.segment)
        .flatMap(s => s.name);

      this.segmentSelection = this.segmentSelection.filter(s =>
        allowedSegments.includes(s.name)
      );
    },
    cleanUpSubsegment() {
      const allowedSubsegments = this.segmentSelection
        .flatMap(s => s.subsegment)
        .flatMap(s => s.name);

      this.subsegmentSelection = this.subsegmentSelection.filter(s =>
        allowedSubsegments.includes(s.name)
      );
    },
  },
};
</script>
