<template>
  <main-layout>
    <h1 class="display-1 mb-5">Projects</h1>
    <v-card>
      <v-data-table
        class="elevation-1"
        :loading="loading"
        :headers="headers"
        :items="loading ? [] : projects"
        item-key="projectId"
        :options.sync="viewOptions"
        :server-items-length="projectsTotal"
      >
        <template v-slot:top>
          <v-container fluid>
            <v-row align="end" justify="space-between">
              <v-col cols="2"
                ><v-btn to="/guidedanalysis/wizard" color="primary" small
                  ><v-icon left>mdi-shape-rectangle-plus</v-icon>Create new
                  project</v-btn
                ></v-col
              >
            </v-row></v-container
          ></template
        >

        <template v-slot:item.name="props">
          <router-link :to="projectUrl(props.item)">{{
            props.item.name
          }}</router-link>
        </template>

        <template v-slot:item.created="{ item }">
          {{ item.created | toHumanDateTime }}
        </template>

        <template v-slot:item.updated="{ item }">
          {{ item.updated | toHumanDateTime }}
        </template>

        <template v-slot:item.user="{ item }">
          {{ item.user.name ? item.user.name : item.user.id }}
        </template>

        <template v-slot:item.published="{ item }">
          <v-icon class="mr-5">
            {{ item.published ? 'mdi-check-bold' : 'mdi-circle-small' }}
          </v-icon>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon class="mr-2" v-on="on" @click="editProject(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit project</span>
          </v-tooltip>
          <v-tooltip left>
            <template class="mr-2" v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openCopyDialog(item)">
                mdi-content-copy
              </v-icon>
            </template>
            <span>Copy project</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="showConfirmDelete(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete project</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="copyOpened"
      max-width="500px"
      @keydown.stop="dialogKeydown"
    >
      <v-card>
        <v-card-title>
          <span class="headline"
            >Copy {{ copyingItem ? copyingItem.name : '' }}</span
          >
        </v-card-title>
        <v-card-text>
          Do you want to create a copy of this project and its active scenarios?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="confirmCopyDialog(copyingItem)"
            :loading="copyLoading"
            >Copy</v-btn
          >
          <v-btn text @click="copyOpened = false" :disabled="copyLoading"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirmation-message
      :show="confirmDelete"
      icon="warning"
      title="Delete permanently"
      :message="
        `Are you sure you want to delete the project '${projectName}'?.\n This action cannot not be undone.`
      "
      button1-text="Cancel"
      @button1="confirmDelete = false"
      button2-text="Delete"
      button2-color="error darken-1"
      button2-type="solid"
      @button2="removeProject"
    />
  </main-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ConfirmationMessage from '@/components/common/ConfirmationMessage';
import moment from 'moment';

export default {
  name: 'ForecastProjectList',
  components: {
    ConfirmationMessage,
  },
  props: ['fineTuningEnabled'],
  data: function() {
    return {
      viewOptions: {
        page: 0,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      copyOpened: false,
      copyLoading: false,
      copyingItem: null,
      confirmDelete: false,
      projectName: '',
      projectToDelete: null,
    };
  },
  created() {
    this.setFineTuning(this.fineTuningEnabled);
  },
  computed: {
    headers() {
      // Constant headers for data table
      return [
        { text: 'Name', value: 'name' },
        { text: 'Created', value: 'created' },
        { text: 'Last modified', value: 'updated' },
        { text: 'Author', value: 'user' },
        {
          text: 'Shared',
          value: 'published',
          filterable: false,
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          align: 'center',
        },
      ];
    },
    ...mapState({
      loading: state => state.projectList.loading,
      projects: state => state.projectList.all.content,
      projectsTotal: state => state.projectList.all.totalElements,
    }),
  },
  filters: {
    toHumanDateTime(date) {
      return moment(date).format('MMM Do YY, h:mm');
    },
  },
  methods: {
    ...mapActions('app', ['setFineTuning']),
    ...mapActions('projectList', {
      getProjects: 'get',
      copyProject: 'copyProject',
    }),
    ...mapActions('project', ['deleteProject']),
    projectUrl: function(item) {
      return `/guidedanalysis/${item.projectId}`;
    },
    refresh: function() {
      this.$store.dispatch('projectList/get', this.viewOptions);
    },
    editProject: function(item) {
      this.$router.push(this.projectUrl(item));
    },
    showConfirmDelete(item) {
      this.projectName = item.name;
      this.projectToDelete = item.projectId;
      this.confirmDelete = true;
    },
    removeProject: function() {
      this.deleteProject(this.projectToDelete).then(() => {
        this.refresh();
        this.projectName = null;
        this.projectToDelete = null;
        this.confirmDelete = false;
      });
    },
    confirmCopyDialog: function(item) {
      this.copyLoading = true;
      this.copyProject(item.projectId).then(newProject => {
        this.copyLoading = this.copyOpened = false;
        this.editProject(newProject);
      });
    },
    openCopyDialog: function(item) {
      this.copyLoading = false;
      this.copyingItem = item;
      this.copyOpened = true;
    },
    dialogKeydown: function(keyEvent) {
      if (keyEvent.key == 'Enter') {
        this.confirmCopyDialog(this.copyingItem);
      }
    },
  },
  watch: {
    viewOptions: {
      handler: function() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>
