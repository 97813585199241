import { projectService } from '@/services/';
import { publicMutations as scenarioMT } from './scenario.module';
import { MAT, MONTHLY } from '@/global/groundForecast';
import _ from 'lodash';

const NEW_PROJECT = 'NEW_PROJECT';
const SET_PROJECT = 'SET_PROJECT';
const SET_PROJECT_CONFIGURATION = 'SET_PROJECT_CONFIGURATION';
const POP_PROJECT_CONFIGURATION_TO_STACK = 'POP_PC_TO_STACK';
const SET_LINECHART_COLS = 'SET_LINECHART_COLS';
const SET_LINECHART_ROWS = 'SET_LINECHART_ROWS';
const STORING_PROJECT = 'STORING_PROJECT';
const SET_FORECAST = 'SET_FORECAST';
const SET_TABLE_VIEW = 'SET_TABLE_VIEW';
const SET_TABLE_DATA = 'SET_TABLE_DATA';
const SET_LINECHART_VIEW = 'SET_LINECHART_VIEW';
const SET_LINECHART_DATA = 'SET_LINECHART_DATA';
const SET_GROUND_FORECAST_ID = 'SET_GROUND_FORECAST_ID';
const SET_ON_DIRTY = 'SET_ON_DIRTY';
const SET_OFF_DIRTY = 'SET_OFF_DIRTY';
const FORECAST_CHANGED = 'FORECAST_CHANGED';
const LOADING_PROJECT = 'LOADING_PROJECT';
const LOADING_FORECAST = 'LOADING_FORECAST';
const LOADING_TABLE = 'LOADING_TABLE';
const LOADING_LINECHART = 'LOADING_LINECHART';
const APPPLYING_CONFIGURATION = 'APPPLYING_CONFIGURATION';
const EXPORTING_FINETUNING_TO_CSV = 'EXPORTING_FINETUNING_TO_CSV';
const EXPORTING_TO_CSV = 'EXPORTING_TO_CSV';
const EXPORTING_TO_EXCEL = 'EXPORTING_TO_EXCEL';

export const mutationTypes = {
  NEW_PROJECT,
  SET_PROJECT,
  SET_ON_DIRTY,
  SET_OFF_DIRTY,
  SET_TABLE_VIEW: SET_TABLE_VIEW,
  SET_GROUND_FORECAST_ID,
  FORECAST_CHANGED,
  SET_LINECHART_COLS,
  SET_LINECHART_ROWS,
  SET_LINECHART_VIEW,
};

const createProject = () => ({
  projectId: null,
  name: null,
  published: false,
  groundForecastId: null,
  config: {
    preferences: {
      gbuId: null,
      scopeId: null,
      dumpDate: null,
      gdpOutlookId: null,
      newbornOutlookId: null,
      factId: null,
      marketDimension: '',
      productDimension: '',
      area: [],
      region: [],
      country: [],
      powerBrand: [],
      pillar: [],
      category: [],
      segment: [],
      subsegment: [],
    },
    historyStack: [],
    tableView: MAT,
    lineChartCols: [],
    lineChartRows: [],
    lineChartView: MONTHLY,
  },
});

const createDataStructure = () => {
  return {
    data: {
      groundForecast: [],
      forecast: [],
      scenarios: {},
    },
    monthsShift: null,
    firstMonth: null,
    lastMonth: null,
    // groundForecastId: null,
    timeSeries: [],
  };
};

const state = {
  project: createProject(),
  forecast: createDataStructure(),
  tableData: createDataStructure(),
  lineChartData: createDataStructure(),
  dirty: false,
  forecastChanged: false,
  applyingConfiguration: false,
  storing: false,
  loading: false,
  loadingForecast: false,
  loadingTable: false,
  loadingLineChart: false,
  exportingToCSV: false,
  exportingToExcel: false,
  exportingFinetuningToCSV: false,
};

const baseSettings = (state, rootGetters) => {
  return {
    projectId: state.project.projectId,
    groundForecastId: state.project.groundForecastId,
    appliedScenarios: rootGetters['scenario/activeScenarioIds'],
    config: state.project.config.preferences,
  };
};

const getters = {
  heatMapSettings: (state, getters, rootState, rootGetters) => {
    return {
      ...baseSettings(state, rootGetters),
      viewType: MAT,
    };
  },
  fineTuningTemplateSettings: (state, getters, rootState, rootGetters) => {
    return {
      ...baseSettings(state, rootGetters),
      viewType: MONTHLY,
    };
  },
  tableSettings: (state, getters, rootState, rootGetters) => {
    return {
      ...baseSettings(state, rootGetters),
      viewType: state.project.config.tableView,
    };
  },
  lineChartSettings: (state, getters, rootState, rootGetters) => {
    return {
      ...baseSettings(state, rootGetters),
      viewType: state.project.config.lineChartView,
    };
  },
  fineTuningSettings: state => {
    return {
      scopeId: state.project.config.preferences.scopeId,
      factId: state.project.config.preferences.factId,
      dumpDate: state.project.config.preferences.dumpDate,
      viewType: state.project.config.tableView,
    };
  },
};

const actions = {
  async createNewProject({ commit }, project) {
    commit(NEW_PROJECT);
    if (project) commit(SET_PROJECT, project);
    commit(`scenario/${scenarioMT.CLEAR_SCENARIOS}`, null, {
      root: true,
    });
    commit(SET_OFF_DIRTY);
  },
  async deleteProject({ state, dispatch }, projectId) {
    const projectToDelete = projectId ? projectId : state.project.projectId;

    return projectService.remove(projectToDelete).then(async () => {
      return dispatch('createNewProject');
    });
  },
  async storeProject({ dispatch, state, commit }) {
    commit(STORING_PROJECT, true);
    let result;
    try {
      if (!state.project.projectId) {
        // Create project
        result = await projectService.create(state.project);
      } else {
        // Update project
        result = await projectService.update(state.project);
      }

      await dispatch('scenario/updateAll', result.projectId, {
        root: true,
      });
    } catch (e) {
      result = state.project;
      commit(STORING_PROJECT, false);
      throw e;
    }

    commit(SET_PROJECT, result);
    commit(SET_OFF_DIRTY);
    commit(STORING_PROJECT, false);

    return result;
  },
  async loadProject({ commit, dispatch }, projectId) {
    commit(LOADING_PROJECT, true);

    try {
      const project = await projectService.get(projectId);
      const { gbuId, scopeId, dumpDate } = project.config.preferences;
      const lastestGroundForecastId = await projectService.getLatestGroundForecastId(
        { gbuId, scopeId, dumpDate }
      );

      if (!project.groundForecastId) {
        project.groundForecastId = lastestGroundForecastId;
        commit(SET_GROUND_FORECAST_ID, lastestGroundForecastId);
      } else {
        if (project.groundForecastId !== lastestGroundForecastId) {
          commit(FORECAST_CHANGED, true);
          commit(SET_GROUND_FORECAST_ID, lastestGroundForecastId);
        } else {
          commit(FORECAST_CHANGED, false);
        }
      }

      //resort the stack in the right order
      project.config.historyStack.sort((a, b) => a.id - b.id);

      commit(SET_PROJECT, project);
      commit(SET_OFF_DIRTY);
      commit(LOADING_PROJECT, false);
      //-----------LOADING FORECAST------------------
      await dispatch('scenario/getCurrentProjectScenarios', null, {
        root: true,
      });
      await dispatch('loadForecast');
      //---------------------------------------------
    } finally {
      commit(LOADING_PROJECT, false);
    }
  },

  async loadForecast({ state, dispatch, commit, getters }, customConfig) {
    commit(LOADING_FORECAST, true);
    commit(SET_FORECAST, createDataStructure());
    commit(SET_LINECHART_DATA, createDataStructure());
    commit(SET_TABLE_DATA, createDataStructure());

    const { viewType, config, ...restSettings } = getters.heatMapSettings;

    let [forecastData] = await Promise.all([
      projectService.getForecastTable({
        ...restSettings,
        viewType,
        config: {
          ...config,
          ...customConfig,
        },
      }),
      dispatch('loadLineChartData'),
      dispatch('loadTableData'),
    ]);

    commit(SET_FORECAST, forecastData);
    commit(LOADING_FORECAST, false);

    if (viewType === state.project.config.lineChartView) {
      dispatch('loadLineChartData');
    }

    if (
      state.project.config.tableView === viewType ||
      state.project.config.tableView === state.project.config.lineChartView
    ) {
      dispatch('loadTableData');
    }
  },

  async loadTableData({ commit, state: { project }, getters }) {
    commit(LOADING_TABLE, true);
    commit(SET_TABLE_DATA, createDataStructure());

    switch (project.config.tableView) {
      case getters.heatMapSettings.viewType:
        commit(SET_TABLE_DATA, state.forecast);
        if (!state.loadingForecast) commit(LOADING_TABLE, false);
        break;
      case project.config.lineChartView:
        commit(SET_TABLE_DATA, state.lineChartData);
        if (!state.loadingLineChart) commit(LOADING_TABLE, false);
        break;
      default:
        var tableData = await projectService.getForecastTable({
          ...getters.tableSettings,
        });
        commit(SET_TABLE_DATA, tableData);
        commit(LOADING_TABLE, false);
        break;
    }
  },

  async loadLineChartData({ state, commit, getters }) {
    commit(LOADING_LINECHART, true);
    commit(SET_LINECHART_DATA, createDataStructure());

    try {
      if (
        getters.lineChartSettings.viewType === getters.heatMapSettings.viewType
      )
        commit(SET_LINECHART_DATA, state.forecast);
      else {
        const data = await projectService.getForecastTable({
          ...getters.lineChartSettings,
        });
        commit(SET_LINECHART_DATA, data);
      }
    } finally {
      commit(LOADING_LINECHART, false);
    }
  },

  setViewType({ commit, dispatch }, viewType) {
    commit(SET_TABLE_VIEW, viewType);
    return dispatch('loadTableData');
  },

  setLineChartViewType({ commit, dispatch }, viewType) {
    commit(SET_LINECHART_VIEW, viewType);
    return dispatch('loadLineChartData');
  },

  async applyConfiguration({ commit, dispatch }, preferences) {
    try {
      commit(APPPLYING_CONFIGURATION, true);
      commit(SET_PROJECT_CONFIGURATION, preferences);

      await dispatch('loadForecast', preferences);
    } catch (e) {
      commit(POP_PROJECT_CONFIGURATION_TO_STACK, preferences);
    } finally {
      commit(APPPLYING_CONFIGURATION, false);
    }
  },

  async restoreLastConfiguration({ commit, dispatch }) {
    try {
      commit(APPPLYING_CONFIGURATION, true);
      commit(POP_PROJECT_CONFIGURATION_TO_STACK);

      await dispatch('loadForecast' /*, state.project.config.preferences*/);
    } finally {
      commit(APPPLYING_CONFIGURATION, false);
    }
  },

  async setFinetuning({ commit, dispatch, getters }, { list, view }) {
    try {
      commit(LOADING_TABLE, true);

      var fts = getters.fineTuningSettings;

      await projectService.setFinetuning(
        fts.scopeId,
        fts.factId,
        fts.dumpDate,
        {
          list: list,
          view: view ? view : fts.viewType,
        }
      );

      await dispatch('loadForecast');
    } finally {
      commit(LOADING_TABLE, false);
    }
  },

  async deleteFineTuning({ commit, getters, dispatch }) {
    try {
      commit(LOADING_TABLE, true);

      var fts = getters.fineTuningSettings;

      await projectService.removeFinetuning(
        fts.scopeId,
        fts.factId,
        fts.dumpDate
      );

      await dispatch('loadForecast');
    } finally {
      commit(LOADING_TABLE, false);
    }
  },

  exportFineTuningToCSV({ commit, state, getters }) {
    commit(EXPORTING_FINETUNING_TO_CSV, true);

    const fileName = generateFileName(state.project, 'csv');

    return projectService
      .getFineTuningCSV(getters.fineTuningTemplateSettings)
      .then(exposeFile(fileName, 'text/csv'))
      .finally(() => commit(EXPORTING_FINETUNING_TO_CSV, false));
  },

  exportForecastToCSV({ commit, state, getters }) {
    commit(EXPORTING_TO_CSV, true);

    const fileName = generateFileName(state.project, 'zip');

    return projectService
      .getForecastTableZIP(getters.tableSettings)
      .then(exposeFile(fileName, 'text/csv'))
      .finally(() => commit(EXPORTING_TO_CSV, false));
  },

  exportForecastToExcel({ commit, state, getters }, appliedScenarios) {
    commit(EXPORTING_TO_EXCEL, true);

    const fileName = generateFileName(state.project, 'xlsx');

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    return projectService
      .getForecastTableExcel({
        ...getters.tableSettings,
        appliedScenarios: appliedScenarios,
      })
      .then(exposeFile(fileName, fileType))
      .finally(() => commit(EXPORTING_TO_EXCEL, false));
  },
};

const mutations = {
  [NEW_PROJECT](state) {
    state.project = createProject();
    state.forecast = createDataStructure();
    state.tableData = createDataStructure();
    state.lineChartData = createDataStructure();
    state.dirty = false;
    state.forecastChanged = false;
    // If we leave an empty scenarios list here, it will hunt us
    // back and overwrite an already populated list.
    if (state.project.scenarios && state.project.scenarios.length == 0) {
      delete state.project.scenarios;
    }
  },

  [SET_PROJECT](state, project) {
    state.project = _.merge(state.project, project);
    // If we leave an empty scenarios list here, it will hunt us
    // back and overwrite an already populated list.
    if (state.project.scenarios && state.project.scenarios.length == 0) {
      delete state.project.scenarios;
    }
    state.dirty = true;
  },

  [SET_PROJECT_CONFIGURATION](state, preferences) {
    state.project.config.historyStack.push({
      ...state.project.config.preferences,
      id: state.project.config.historyStack.length,
    });
    state.project.config.preferences = {
      ...state.project.config.preferences,
      ...preferences,
    };
    state.project.config.lineChartCols = [];
    state.project.config.lineChartRows = [];
    state.dirty = true;
  },

  [POP_PROJECT_CONFIGURATION_TO_STACK](state) {
    const config = state.project.config;
    const prevConfig = config.historyStack.pop();
    state.project.config.preferences = prevConfig;
    state.project.config.lineChartCols = [];
    state.project.config.lineChartRows = [];
    state.dirty = true;
  },

  [SET_LINECHART_COLS](state, cols) {
    state.project.config.lineChartCols = cols;
  },

  [SET_LINECHART_ROWS](state, cols) {
    state.project.config.lineChartRows = cols;
  },

  [STORING_PROJECT](state, storing) {
    state.storing = storing;
  },

  [SET_FORECAST](state, forecast) {
    state.forecast = forecast;
  },

  [SET_GROUND_FORECAST_ID](state, groundForecastId) {
    state.project.groundForecastId = groundForecastId;
  },

  [LOADING_FORECAST](state, loading) {
    state.loadingForecast = loading;
  },

  [LOADING_PROJECT](state, status) {
    state.loading = status;
  },

  [LOADING_TABLE](state, loading) {
    state.loadingTable = loading;
  },

  [LOADING_LINECHART](state, loading) {
    state.loadingLineChart = loading;
  },

  [SET_ON_DIRTY](state) {
    state.dirty = true;
  },

  [SET_OFF_DIRTY](state) {
    state.dirty = false;
  },

  [SET_TABLE_VIEW](state, viewType) {
    state.project.config.tableView = viewType;
  },

  [SET_TABLE_DATA](state, data) {
    state.tableData = data;
  },

  [SET_LINECHART_VIEW](state, viewType) {
    state.project.config.lineChartView = viewType;
    state.project.config.lineChartCols = [];
    state.project.config.lineChartRows = [];
    state.dirty = true;
  },

  [SET_LINECHART_DATA](state, data) {
    state.lineChartData = data;
  },

  [EXPORTING_FINETUNING_TO_CSV](state, loading) {
    state.exportingFinetuningToCSV = loading;
  },

  [EXPORTING_TO_CSV](state, loading) {
    state.exportingToCSV = loading;
  },

  [EXPORTING_TO_EXCEL](state, loading) {
    state.exportingToExcel = loading;
  },

  [APPPLYING_CONFIGURATION](state, applying) {
    state.applyingConfiguration = applying;
  },

  [FORECAST_CHANGED](state, changed) {
    state.forecastChanged = changed;
  },
};

export const project = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const generateFileName = (project, extension) => {
  const currentDate = new Date();

  const fileName = `${
    project.name ? project.name : 'unnamed_project_'
  }_${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDay()}.${extension}`;

  return fileName;
};

const exposeFile = (fileName, type) => data => {
  var fileURL = window.URL.createObjectURL(
    new Blob([data], {
      type,
    })
  );
  var fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
};
