import Vue from 'vue';

/**
 * Bubble registered events up (native or custom).
 * E.g. <my-component v-bubble.input.focus>
 */
Vue.directive('bubble', {
  bind: (el, binding, vnode) => {
    Object.keys(binding.modifiers).forEach(event => {
      // Bubble events of Vue components
      if (vnode.componentInstance) {
        vnode.componentInstance.$on(event, (...args) => {
          vnode.context.$emit(event, ...args);
        });
        // Bubble events of native DOM elements
      } else {
        el.addEventListener(event, payload => {
          vnode.context.$emit(event, payload);
        });
      }
    });
  },
});
