<template>
  <v-dialog v-model="showDialog" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Export to Excel</span>
      </v-card-title>
      <v-card-text>
        Please select a source:
        <v-select
          v-model="dataSource"
          :items="dataSourceList"
          item-value="id"
          item-text="name"
          hide-details
          outlined
          dense
        >
          <template #selection="{item}">
            <span v-if="item.id == GROUND_FORECAST">
              {{ item.name }}
            </span>
            <span
              v-if="item.id !== GROUND_FORECAST && item.id !== FORECAST"
              :style="`color:${scenarioColorHash(item)}`"
            >
              {{ `${item.id}-${item.name}` }}
            </span>
            <span v-if="item.id === FORECAST" class="blue-grey--text">
              {{ item.name }}
            </span>
          </template>
          <template #item="{item}">
            <span v-if="item.id == GROUND_FORECAST">
              {{ item.name }}
            </span>
            <span
              v-if="item.id !== GROUND_FORECAST && item.id !== FORECAST"
              :style="`color:${scenarioColorHash(item)}`"
            >
              {{ `${item.id}-${item.name}` }}
            </span>
            <span v-if="item.id === FORECAST" class="blue-grey--text">
              {{ item.name }}
            </span>
          </template>
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close" :disabled="exportingToExcel">Cancel</v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="export2Excel()"
          :loading="exportingToExcel"
          >Export</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { reactive, toRefs, watch, computed } from '@vue/composition-api';
import { GROUND_FORECAST, FORECAST } from '@/global/guideAnalysis';
import { scenarioColorHash } from '@/global/scenarios';
import store from '@/store';

export default {
  name: 'ExportToExcel',
  props: {
    show: Boolean,
    dataSourceList: Array,
  },
  setup(props, { emit }) {
    const {
      state: { project: sto },
    } = store;

    const state = reactive({
      showDialog: false,
      dataSource: false,
      exportingToExcel: computed(() => sto.exportingToExcel),
    });

    watch(
      () => props.show,
      value => {
        state.showDialog = value;
      }
    );

    const exportForecastToExcel = appliedScenarios =>
      store
        .dispatch('project/exportForecastToExcel', appliedScenarios)
        .then(() => close());

    const export2Excel = () => {
      switch (state.dataSource) {
        case GROUND_FORECAST:
          //we applied without scenarios
          exportForecastToExcel([]);
          break;
        case FORECAST:
          //we applied all the  scenarios
          {
            const scenarios = props.dataSourceList
              .filter(f => ![FORECAST, GROUND_FORECAST].includes(f.id))
              .map(i => i.id);
            exportForecastToExcel(scenarios);
          }
          break;
        default:
          //we applied the selected sceneario
          exportForecastToExcel([state.dataSource]);
      }
    };

    const close = () => {
      state.showDialog = false;
      emit('closed');
    };

    return {
      ...toRefs(state),
      GROUND_FORECAST,
      FORECAST,
      scenarioColorHash,
      export2Excel,
      close,
    };
  },
};
</script>
