<template>
  <v-container class="mb-5">
    <!-- HEADER  -->
    <v-row justify="end">
      <v-col v-if="fineTuningEnabled">
        <div class="d-flex flex-row ml-5">
          <div v-if="!fineTuningSwitch">
            <v-btn
              @click="showFineTuning(true)"
              color="blue darken-3"
              class="mr-3"
              text
            >
              <v-icon left>edit</v-icon>
              Fine Tuning
            </v-btn>
          </div>
          <div v-if="fineTuningSwitch">
            <span class="font-weight-bold font-italic mt-1 mr-3"
              >Fine Tuning:</span
            >
            <v-btn
              class="pr-10 pl-10 mr-1 button"
              color="blue darken-3"
              :disabled="!fineTuningEnabled"
              text
              @click="applyFineTuning"
              ><v-icon left>mdi-check-circle-outline</v-icon>Apply</v-btn
            >
            <v-btn
              class="pr-10 pl-10 mr-3 button"
              color="red darken-3"
              text
              :disabled="!fineTuningEnabled"
              @click="removeFineTuning"
              ><v-icon left>mdi-minus-circle-outline</v-icon>Remove</v-btn
            >
            <v-btn
              class="pr-10 pl-10 mr-3 button"
              text
              :loading="exportingFinetuningToCSV"
              :disabled="!fineTuningEnabled"
              @click="generateTemplate(false)"
              ><v-icon left>mdi-dots-grid</v-icon>Template</v-btn
            >
            <v-btn
              color="teal darken-3"
              text
              class="pr-10 pl-10 mr-1 button"
              @click="showUploadFT = !showUploadFT"
              ><v-icon left>mdi-file-upload-outline</v-icon>Upload</v-btn
            >
            <v-btn
              class="pr-10 pl-10 button"
              color="blue-grey darken-1"
              text
              :disabled="!fineTuningEnabled"
              @click="showFineTuning(false)"
              ><v-icon left>mdi-cancel</v-icon>cancel</v-btn
            >
          </div>
        </div>
        <v-row
          ><div class="fine-tuning-divider">
            <v-divider />
          </div>
        </v-row>
      </v-col>
      <v-col>
        <v-row justify="end">
          <div class="viewType mr-5">
            <v-select
              v-model="viewType"
              :items="getViewTypeList"
              item-value="key"
              item-text="value"
              label="View type"
              :disabled="!viewTableEnabled"
              hide-details
              outlined
              dense
            ></v-select>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <!-- TABLE  -->
    <v-row>
      <v-layout child-flex>
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :items="dataTable"
          :expanded="expanded"
          hide-default-footer
          class=" myclass"
          height="40vh"
          fixed-header
          calculate-widths
          dense
          disable-pagination
          @click:row="expandRow"
        >
          <template v-slot:item.powerbrand="{ item }">
            <check-type-alerts
              v-if="item[`checkType`].length > 0"
              :data="getCheckTypeDecriptions(item[`checkType`])"
            />
            {{ item.powerbrand }}
          </template>
          <!-- DYNAMIC VALUES -->
          <template
            v-slot:[`item.`+di]="{ item }"
            v-for="di in dynamicValueItems"
          >
            <v-layout
              justify-end
              :key="di"
              :class="{
                forecasted: item[`${di}-isForecasted`],
              }"
              :style="
                item[`${di}-imputed`] != 0
                  ? 'background-color:' +
                    calculateImputedColor(item[`${di}-imputed`])
                  : ''
              "
            >
              <span
                :class="{
                  'value-forecast':
                    getValueIfDifferent(item[di], item[`${di}-forecast`]) !=
                    null,
                  'font-italic': item[`${di}-tuned`],
                  'font-weight-medium': item[`${di}-tuned`],
                  'cyan--text': item[`${di}-tuned`],
                  'text--darken-1': item[`${di}-tuned`],
                }"
                >{{ item[di] | numberFormat(0) }}
              </span>
            </v-layout>
          </template>
          <!-- GROWTH & CAGRS % -->
          <template
            v-slot:[`item.`+dpi]="{ item }"
            v-for="dpi in dynamicPercentageItems"
          >
            <v-layout justify-end :key="dpi">
              {{ item[dpi] | percentage }}
            </v-layout>
          </template>
          <!-- EXPAND ROW -->
          <template #expanded-item="{ item }">
            <td :colspan="calculateHeaderSpan">
              <!-- EXPAND ROW - DESCRIPTION COLUMN-->
              <div
                v-if="fineTuning"
                class="d-flex indigo-lighten-1--text font-italic fine-tuning-cell"
              >
                Fine tuning
              </div>
              <div
                class="d-flex"
                v-for="s in item.scenarios"
                :key="s.id"
                :style="`color:${scenarioColorHash(s)};`"
              >
                {{ `${s.id} - ${s.name}` }}
              </div>
              <div
                v-if="item.scenarios.length > 0"
                class="d-flex blue-grey--text"
              >
                All combined
              </div>
            </td>
            <!-- EXPAND ROW - DYNAMIC VALUES - SCENARIOS-->
            <td v-for="(di, di_index) in dynamicValueItems" :key="di">
              <!----- FINE TUNING ----->
              <div
                v-if="fineTuning && item[`${di}-isForecasted`]"
                class="d-flex blue-grey--text"
              >
                {{ setFineTuningRow(item, di, di_index) }}
                <v-text-field
                  type="number"
                  v-model="
                    fineTuningValues[item.id].values[di_index].percentage
                  "
                  :key="di"
                  @keydown="numbersOnly"
                  suffix="%"
                  class="finetuning-test"
                  dense
                ></v-text-field>
              </div>
              <div
                v-else-if="fineTuning"
                class="d-flex blue-grey--text fine-tuning-cell"
              >
                <v-layout justify-end>-</v-layout>
              </div>
              <!----- SCENARIOS ----->
              <div
                class="d-flex"
                v-for="s in item.scenarios"
                :key="`di-${s.id}`"
              >
                <v-layout justify-end :style="`color:${scenarioColorHash(s)}`">
                  {{
                    getValueIfDifferent(
                      item[`${di}-scenario-${s.id}`],
                      item[di]
                    ) | numberFormat(0)
                  }}
                </v-layout>
              </div>
              <!-- EXPAND ROW - DYNAMIC VALUES - FORECAST-->
              <div
                v-if="item.scenarios.length > 0"
                class="d-flex blue-grey--text"
              >
                <v-layout justify-end>
                  {{
                    (item[`${di}-forecast`] != null
                      ? getValueIfDifferent(item[`${di}-forecast`], item[di])
                      : '-') | numberFormat(0)
                  }}
                </v-layout>
              </div>
            </td>
            <!-- EXPAND ROW - GROWTH & CAGRS % - SCENARIOS-->
            <td v-for="dpi in dynamicPercentageItems" :key="dpi">
              <!-- FINE TUNING %-->
              <div
                v-if="fineTuning"
                class="d-flex blue-grey--text fine-tuning-cell"
              >
                <v-layout justify-end>-</v-layout>
              </div>
              <div
                class="d-flex"
                v-for="s in item.scenarios"
                :key="`dpi-${s.id}`"
              >
                <v-layout justify-end :style="`color:${scenarioColorHash(s)}`">
                  {{
                    getValueIfDifferent(
                      item[`${dpi}-scenario-${s.id}`],
                      item[dpi]
                    ) | percentage
                  }}
                </v-layout>
              </div>
              <!-- EXPAND ROW - GROWTH & CAGRS % - forecast-->
              <div v-if="item.scenarios.length > 0" class="d-flex">
                <v-layout justify-end>
                  {{
                    (item[`${dpi}-forecast`] != null
                      ? getValueIfDifferent(item[`${dpi}-forecast`], item[dpi])
                      : '-') | percentage
                  }}
                </v-layout>
              </div>
            </td>
          </template>
          <!-- SUMMARY -->
          <template #body.prepend="{headers}" v-if="!loadingTable">
            <tr class="font-weight-medium font-italic">
              <td :colspan="calculateHeaderSpan">
                Totals Summary
              </td>
            </tr>
            <tr class="font-weight-medium font-italic">
              <td :colspan="calculateHeaderSpan">
                {{ isTuned ? 'Fine tuned forecast' : 'Ground forecast' }}
              </td>
              <td v-for="di in dynamicValueItems" :key="di">
                <v-layout justify-end>
                  {{ summary[di] | numberFormat(0) }}
                </v-layout>
              </td>
              <td v-for="dpi in dynamicPercentageItems" :key="dpi">
                <v-layout justify-end>
                  {{ summary[dpi] | percentage }}
                </v-layout>
              </td>
            </tr>
            <!-- -------------- -->
            <tr
              class="font-weight-medium font-italic"
              v-for="(s, sIndex) in summary.scenarios"
              :key="`s-${s.id}`"
            >
              <td
                :colspan="calculateHeaderSpan"
                :style="`color:${scenarioColorHash(s)};`"
              >
                {{ `${s.id} - ${s.name}` }}
              </td>
              <td v-for="di in dynamicValueItems" :key="di + sIndex">
                <v-layout justify-end :style="`color:${scenarioColorHash(s)}`">
                  {{
                    getValueIfDifferent(
                      summary[`${di}-scenario-${s.id}`],
                      summary[di]
                    ) | numberFormat(0)
                  }}
                </v-layout>
              </td>
              <td v-for="dpi in dynamicPercentageItems" :key="dpi + sIndex">
                <v-layout justify-end :style="`color:${scenarioColorHash(s)}`">
                  {{
                    getValueIfDifferent(
                      summary[`${dpi}-scenario-${s.id}`],
                      summary[dpi]
                    ) | percentage
                  }}
                </v-layout>
              </td>
            </tr>
            <tr
              v-if="summary.hasDetails"
              class="font-weight-medium font-italic"
            >
              <td :colspan="calculateHeaderSpan" class="blue-grey--text">
                All combined
              </td>
              <td v-for="(di, index) in dynamicValueItems" :key="'f' + index">
                <v-layout justify-end class="blue-grey--text">
                  {{
                    getValueIfDifferent(summary[`${di}-forecast`], summary[di])
                      | numberFormat(0)
                  }}
                </v-layout>
              </td>
              <td
                v-for="(dpi, index) in dynamicPercentageItems"
                :key="'gf-' + index"
              >
                <v-layout justify-end class="blue-grey--text">
                  {{
                    getValueIfDifferent(
                      summary[`${dpi}-forecast`],
                      summary[dpi]
                    ) | percentage
                  }}
                </v-layout>
              </td>
            </tr>
            <tr>
              <td :colspan="headers.length" class="summary-bottom-line"></td>
            </tr>
          </template>
          <!-- FOOTER -->
          <template #footer>
            <v-main fluid class="pa-0">
              <v-row justify="space-between">
                <v-col>
                  <table class="footer">
                    <tr>
                      <td>
                        <div
                          class="forecasted-block float-left  float-left"
                        ></div>
                        <span>Forecast</span>
                      </td>
                      <td>
                        <div class="imputed-block float-left  float-left"></div>
                        <span>Imputed</span>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col>
                  <v-row justify="end" class="pr-7">
                    <v-btn
                      color="blue-grey lighten-1"
                      class="ml-3"
                      outlined
                      :loading="exportingToCSV"
                      :disabled="!exportEnabled"
                      @click="exportToCSV"
                      ><v-icon left>mdi-file-delimited-outline</v-icon>Export to
                      CSV</v-btn
                    >
                    <v-btn
                      color="green darken-1"
                      class="ml-3"
                      outlined
                      :loading="exportingToExcel"
                      :disabled="!exportEnabled"
                      @click="showExport2Excel = true"
                      ><v-icon left>mdi-file-excel</v-icon>Export to
                      Excel</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-main>
          </template>
        </v-data-table>

        <export-to-excel
          :show="showExport2Excel"
          :data-source-list="dataSourceList"
          @closed="showExport2Excel = false"
        />
      </v-layout>
    </v-row>
    <upload-fine-tuning :show="showUploadFT" @upload="importFineTuningCSV" />
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { colorHash, scenarioColorHash } from '@/global/scenarios';
import ExportToExcel from '@/components/guidedAnalysis/partials/Export2Excel';
import UploadFineTuning from './UploadFineTuning';
import { catalogService } from '@/services';
import CheckTypeAlerts from './CheckTypeAlerts';
import {
  filterDataPoint,
  filterDataItem,
  dataItemFilter,
  valueLookup,
} from '@/global/common';
import _ from 'lodash';
import {
  viewTypeList,
  MAT,
  HALF_MAT,
  QUARTERLY,
  calculateCagr,
  calculateGrowth,
  calculateImputedColor,
} from '@/global/groundForecast';
import { GROUND_FORECAST, FORECAST } from '@/global/guideAnalysis';

export default {
  name: 'ProjectTable',
  components: {
    ExportToExcel,
    CheckTypeAlerts,
    UploadFineTuning,
  },
  data: function() {
    return {
      expanded: [],
      scenarios: [],
      dynamicValueItems: [],
      dynamicPercentageItems: [],
      summary: {},
      showExport2Excel: false,
      fineTuningValues: [],
      fineTuning: false,
      fineTuningSwitch: false,
      checkTypeCatalog: null,
      showUploadFT: false,
    };
  },
  created: async function() {
    catalogService.get('check_type').then(catalogs => {
      var descriptions = {};
      catalogs.forEach(i => (descriptions[i.key] = i.value));
      this.checkTypeCatalog = descriptions;
    });
  },
  computed: {
    calculateHeaderSpan() {
      return (
        this.headers.length -
        this.dynamicValueItems.length -
        this.dynamicPercentageItems.length
      );
    },
    getViewTypeList() {
      return viewTypeList;
    },
    viewType: {
      get() {
        return this.tableView;
      },
      set(value) {
        this.setViewType(value);
      },
    },
    dataTable() {
      return this.normalizeData();
    },
    headers() {
      return this.calculateHeaders();
    },
    lastMonth() {
      return _.isEmpty(this.forecast.lastMonth)
        ? ''
        : moment(this.forecast.lastMonth, 'yyyy-MM-dd').format('MMMM');
    },
    fineTuningEnabled() {
      return _.isNumber(this.project.projectId) && !this.loadingTable;
    },
    exportEnabled() {
      return _.isNumber(this.project.projectId) && !this.loadingTable;
    },
    scenariosData() {
      if (
        !_.isEmpty(this.forecast) &&
        !_.isEmpty(this.forecast.data) &&
        !_.isEmpty(this.forecast.data.scenarios)
      )
        return this.forecast.data.scenarios;
      else return {};
    },
    allScenariosIDs() {
      return Object.keys(this.scenariosData);
    },
    scenariosDataPoints() {
      return this.getScenariosDataPoints(this.scenariosData);
    },
    dataSourceList() {
      let list = [];

      list.push({
        id: GROUND_FORECAST,
        name: this.isTuned ? 'Fine Tuned forecast' : 'Ground Forecast',
      });

      this.getScenariosIdAndName(this.forecast.data.scenarios).forEach(s => {
        list.push(s);
      });
      if (
        !_.isEmpty(this.forecast) &&
        !_.isEmpty(this.forecast.data) &&
        !_.isEmpty(this.forecast.data.scenarios)
      )
        list.push({ id: FORECAST, name: 'All Scenarios Combined' });

      return list;
    },
    isTuned() {
      if (!_.isEmpty(this.forecast)) return this.forecast.tuned;
      return false;
    },
    viewTableEnabled() {
      return (
        !_.isEmpty(this.configuration) &&
        !this.loadingForecast &&
        !this.loadingTable
      );
    },
    ...mapState({
      fineTuningEnabled: state => state.app.fineTuning,
    }),
    ...mapGetters('scenario', ['getScenarioName']),
    ...mapState('project', {
      project: state => state.project,
      configuration: state => state.project.config.preferences,
      forecast: state => state.tableData,
      tableView: state => state.project.config.tableView,
      preferences: state => state.project.config.preferences,
      loadingForecast: state => state.loadingForecast,
      loadingTable: state => state.loadingTable,
      exportingToCSV: state => state.exportingToCSV,
      exportingToExcel: state => state.exportingToExcel,
      exportingFinetuningToCSV: state => state.exportingFinetuningToCSV,
    }),
  },
  methods: {
    colorHash,
    scenarioColorHash,
    calculateImputedColor,
    applyFineTuning() {
      var normalized = _.cloneDeep(this.fineTuningValues)
        .map(f => {
          f.values = f.values.filter(
            v => v != null && v.percentage != v.percentage_orig
          );
          return f;
        })
        .filter(f2 => f2.values.length > 0);

      this.setFinetuning({ list: normalized }).then(() => {
        this.fineTuning = false;
        this.fineTuningSwitch = false;
      });
    },
    removeFineTuning() {
      this.deleteFineTuning().then(() => {
        this.fineTuning = false;
        this.fineTuningSwitch = false;
      });
    },
    numbersOnly(event) {
      if (
        event.keyCode == 46 ||
        event.keyCode == 8 ||
        event.keyCode == 9 ||
        event.keyCode == 27 ||
        event.keyCode == 13 ||
        // Allow: Ctrl+A
        (event.keyCode == 65 && event.ctrlKey === true) ||
        // Allow: home, end, left, right, down
        (event.keyCode >= 35 && event.keyCode <= 40) ||
        //Allow . and -
        event.keyCode == 173 ||
        event.keyCode == 190
      ) {
        // let it happen, don't do anything
        return;
      } else {
        // Ensure that it is a number and stop the keypress
        if (
          event.shiftKey ||
          ((event.keyCode < 48 || event.keyCode > 57) &&
            (event.keyCode < 96 || event.keyCode > 105))
        ) {
          event.preventDefault();
        }
      }
    },
    setFineTuningRow(item, column, index) {
      var points = item[`${column}-points`];

      var day = '01';
      var month = '01';
      var year = points[0];

      if (points.length > 1) month = points[1].toString().padStart(2, '0');

      var lastMonth = null;

      if (this.viewType === MAT || this.viewType === HALF_MAT) {
        lastMonth = moment(this.forecast.lastMonth, 'yyyy-MM-dd').format('MM');
      }
      var percentage = 0;

      if (item[`${column}-tuned`]) {
        percentage =
          (item[`${column}`] * 100) / item[`${column}-original`] - 100;
      }

      this.fineTuningValues[item.id] = {
        area: item.area,
        region: item.region,
        country: item.country,
        powerbrand: item.powerbrand,
        gbu: item.gbu,
        pillar: item.pillar,
        category: item.category,
        segment: item.segment,
        subsegment: item.subsegment,
        lastMonth: lastMonth,
        values: [],
        ...this.fineTuningValues[item.id],
      };
      this.fineTuningValues[item.id].values[index] = {
        percentage: percentage.toFixed(1),
        percentage_orig: percentage.toFixed(1),
        date: `${year}-${month}-${day}`,
      };
    },
    calculateHeaders() {
      let headers = [];
      this.dynamicValueItems = [];
      this.dynamicPercentageItems = [];

      headers.push({ text: 'Power Brand', value: 'powerbrand', width: 180 });

      if (
        !this.forecast.data ||
        !this.forecast.data.groundForecast ||
        this.forecast.data.groundForecast.length <= 0
      ) {
        // Just to display something
        return headers;
      }

      const i = this.forecast.data.groundForecast[0];

      if (i.pillar != null) {
        headers.push({
          text: 'Pillar',
          value: 'pillar',
          width: 120,
          sortable: true,
        });
      }
      if (i.category != null) {
        headers.push({
          text: 'Category',
          value: 'category',
          width: 120,
          sortable: true,
        });
      }
      if (i.segment != null) {
        headers.push({
          text: 'Segment',
          value: 'segment',
          width: 100,
          sortable: true,
        });
      }
      if (i.subsegment != null) {
        headers.push({
          text: 'Subsegment',
          value: 'subsegment',
          width: 120,
          sortable: true,
        });
      }
      if (i.area != null) {
        headers.push({
          text: 'Area',
          value: 'area',
          width: 100,
          sortable: true,
        });
      }
      if (i.region != null) {
        headers.push({
          text: 'Region',
          value: 'region',
          width: 100,
          sortable: true,
        });
      }
      if (i.country != null) {
        headers.push({
          text: 'Country',
          value: 'country',
          width: 100,
          sortable: true,
        });
      }

      let growths = [];

      for (const [index, v] of i.values.entries()) {
        let text =
          v.time.points.length > 1
            ? `${v.time.points[1]}`.padStart(2, '0') + `/${v.time.points[0]}`
            : v.time.points[0];

        text = (this.viewType === QUARTERLY ? 'Q' : '') + text;

        const propName = `v${v.time.points[0]}-${index}`;

        headers.push({
          text,
          value: propName,
          align: 'end',
          width: 90,
          sortable: true,
        });

        this.dynamicValueItems.push(propName);

        if (this.viewType === MAT) {
          if (index > 0) {
            const sufix =
              !v.complete || !i.values[index - 1].complete ? '-P' : '';

            growths.push({
              text: `Growth ${v.time.points[0]}${sufix}`,
              value: `yoyg${index}`,
              width: 100,
              align: 'end',
              sortable: false,
            });
            this.dynamicPercentageItems.push(`yoyg${index}`);
          }
        }
      }

      headers = headers.concat(growths);

      if (this.viewType === MAT) {
        headers.push({
          text: 'Hist. CAGR',
          value: 'histCagr',
          align: 'end',
          width: 90,
        });

        headers.push({
          text: '3Y CAGR',
          value: 't3Cagr',
          align: 'end',
          width: 100,
        });

        headers.push({
          text: '5Y CAGR',
          value: 't5Cagr',
          align: 'end',
          width: 100,
        });

        this.dynamicPercentageItems.push('histCagr');
        this.dynamicPercentageItems.push('t3Cagr');
        this.dynamicPercentageItems.push('t5Cagr');
      }
      return headers;
    },
    addSumary(propName, defaultValue, originalValue, forecastValue) {
      if (!this.summary[propName]) {
        this.summary[propName] = 0;
      }

      this.summary[propName] += defaultValue;

      if (!this.summary[`${propName}-original`]) {
        this.summary[`${propName}-original`] = 0;
      }

      this.summary[`${propName}-original`] += originalValue;

      if (!this.summary[`${propName}-forecast`])
        this.summary[`${propName}-forecast`] = 0;

      this.summary[`${propName}-forecast`] +=
        forecastValue != null ? forecastValue : defaultValue;

      if (forecastValue != null) this.summary.hasDetails = true;
    },
    showFineTuning(show) {
      this.fineTuningSwitch = show;
      this.fineTuning = show;
    },
    generateTemplate() {
      this.exportFineTuningToCSV();
    },
    importFineTuningCSV(tuning) {
      this.setFinetuning(tuning)
        .then(() => {
          this.fineTuning = false;
          this.fineTuningSwitch = false;
        })
        .catch(() => {
          this.$notify({
            title: this.title,
            text: `It was not possible to imrpot the CSV!`,
            type: 'error',
          });
        });
    },
    normalizeData() {
      if (
        !this.forecast.data ||
        !this.forecast.data.groundForecast ||
        this.forecast.data.groundForecast.length <= 0
      )
        return [];

      const newData = [];
      this.expanded = [];
      this.summary = {
        scenarios: this.getScenariosIdAndName(this.scenariosData),
        hasDetails: false,
      };

      this.fineTuningValues = [];

      this.forecast.data.groundForecast.forEach((r, index) => {
        this.fineTuningValues[index] = {};
        // eslint-disable-next-line no-unused-vars
        const { values, avgFit, ...rest } = r;

        let nr = {
          id: index, //for the grid
          ...rest, //adding powerbrand, gbu, pillar, category, etc...
        };

        const forecast = this.forecastLookup(this.forecast.data.forecast, r);
        const scenariosApplied = this.scenariosLookup(
          this.forecast.data.scenarios,
          r
        );

        nr.scenarios = scenariosApplied.map(s => {
          return {
            id: s.id,
            name: s.name,
          };
        });

        const yearsValues = [];
        let hasDetails = false;
        let hasValuesTuned = false;

        nr['checkType'] = [];

        values.forEach((v, index) => {
          const propName = `v${v.time.points[0]}-${index}`;
          let defaultValue = v.value;
          let originalValue = v.original_value;
          let forecastValue = valueLookup(v, forecast);

          //Assign properties----------------------------------------------------
          nr[propName] = defaultValue;
          nr[`${propName}-imputed`] = v.imputed;
          nr[`${propName}-original`] = originalValue;
          nr[`${propName}-points`] = v.time.points;
          nr[`${propName}-tuned`] = v.tuned;

          hasValuesTuned |= v.tuned;

          if (hasValuesTuned) nr['checkType'] = [];
          else nr['checkType'] = _.union(nr['checkType'], v.checkType);

          //for the expantion row
          hasDetails = hasDetails || forecastValue;

          nr[`${propName}-forecast`] = forecastValue;
          nr[`${propName}-isForecasted`] = v.forecast;
          //------------SUMMARY-------------------------------
          this.addSumary(propName, defaultValue, originalValue, forecastValue);
          //--------------------------------------------------------------------
          if (v.forecast) {
            this.addScenarios(scenariosApplied, propName, defaultValue, v, nr);
          }
          //------------------------------------------------------------------
          if (this.viewType === MAT) {
            this.addGrowth(
              propName,
              defaultValue,
              scenariosApplied,
              yearsValues,
              index,
              nr
            );
          }

          index++;
        });

        if (this.viewType === MAT) {
          this.addCagr(nr, yearsValues, scenariosApplied);
        }

        nr.hasDetails = hasDetails;

        if (hasDetails || this.fineTuning) this.expanded.push(nr);

        newData.push(nr);
      });

      if (this.viewType === MAT) {
        const years = Object.keys(this.summary).filter(i => {
          return (
            i !== 'scenario' &&
            i !== 'yearsValues' &&
            i !== 'hasDetails' &&
            !i.includes('scenario') &&
            !i.includes('original') &&
            !i.includes('-forecast')
          );
        });
        this.addSummaryGrowth(years);
        this.addSummaryCagr(years);
      }

      return newData;
    },
    addScenarios(scenariosApplied, propName, defaultValue, values, obj) {
      this.allScenariosIDs.forEach(id => {
        const s = scenariosApplied.filter(x => x.id === id)[0] || null;

        const scenarioProp = `${propName}-scenario-${id}`;

        var sum = this.summary[scenarioProp] || 0;

        if (s != null) {
          let scenarioValue = valueLookup(values, s.item);

          if (scenarioValue != null) {
            obj[scenarioProp] = scenarioValue;
            sum += scenarioValue;
          } else {
            if (filterDataPoint(this.scenariosDataPoints[id], values)) {
              sum += defaultValue;
            }
          }
        } else {
          //the data point exist in the scenario
          if (filterDataPoint(this.scenariosDataPoints[id], values))
            sum += defaultValue;
        }

        if (sum > 0) this.summary[scenarioProp] = sum;
      });
    },
    addGrowth(
      propName,
      defaultValue,
      scenariosApplied,
      yearsValues,
      index,
      obj
    ) {
      yearsValues.push({ prop: propName, value: defaultValue });

      if (index > 0) {
        const preYear = yearsValues[index - 1];
        const currYear = yearsValues[index];

        obj[`yoyg${index}`] = calculateGrowth(preYear.value, currYear.value);

        const calculateYearlyGrowth = suffix => {
          const startPropName = preYear.prop + suffix;
          const endPropName = currYear.prop + suffix;

          let start = preYear.value;
          let end = currYear.value;

          if (obj[startPropName]) start = obj[startPropName];
          if (obj[endPropName]) end = obj[endPropName];

          obj[`yoyg${index}${suffix}`] = calculateGrowth(start, end);
        };

        calculateYearlyGrowth('-original');
        calculateYearlyGrowth('-forecast');
        scenariosApplied.forEach(s =>
          calculateYearlyGrowth(`-scenario-${s.id}`)
        );
      }
    },
    addCagr(obj, yearsValues, scenariosApplied) {
      const lastNotForecastIndex = 2; //always 3rd year
      const lastForcast = yearsValues[lastNotForecastIndex];

      const getYearsValue = prop => {
        return yearsValues[prop] ? yearsValues[prop].value : 0;
      };

      const calculateMATCagr = (years, suffix) => {
        let startPropName = lastForcast.prop + suffix;
        let endPropName =
          yearsValues[lastNotForecastIndex + years].prop + suffix;

        let start = lastForcast.value;
        let end = getYearsValue(lastNotForecastIndex + years);

        if (obj[startPropName]) start = obj[startPropName];
        if (obj[endPropName]) end = obj[endPropName];

        obj[`t${years}Cagr-${suffix}`] = calculateCagr(start, end, years);
      };

      obj.histCagr = calculateCagr(
        getYearsValue(lastNotForecastIndex - 2),
        lastForcast.value,
        2
      );
      obj.t3Cagr = calculateCagr(
        lastForcast.value,
        getYearsValue(lastNotForecastIndex + 3),
        3
      );
      obj.t5Cagr = calculateCagr(
        lastForcast.value,
        getYearsValue(lastNotForecastIndex + 5),
        5
      );

      scenariosApplied.forEach(s => {
        calculateMATCagr(3, `scenario-${s.id}`);
        calculateMATCagr(5, `scenario-${s.id}`);
      });

      calculateMATCagr(3, `forecast`);
      calculateMATCagr(5, `forecast`);
    },
    addSummaryGrowth(years) {
      years.forEach((y, index) => {
        if (index < 1) return;

        this.summary[`yoyg${index}`] = calculateGrowth(
          this.summary[years[index - 1]],
          this.summary[y]
        );

        const startProp = years[index - 1];
        const endProp = y;

        const calculateSummaryGrowth = suffix => {
          const startPropName = startProp + suffix;
          const endPropName = endProp + suffix;

          let start = this.summary[startProp];
          let end = this.summary[end];

          if (this.summary[startPropName]) start = this.summary[startPropName];
          if (this.summary[endPropName]) end = this.summary[endPropName];

          if (!start) start = this.summary[startProp];
          if (!end) end = this.summary[endProp];

          this.summary[`yoyg${index}${suffix}`] = calculateGrowth(start, end);
        };

        calculateSummaryGrowth('-original');
        calculateSummaryGrowth('-forecast');

        this.summary.scenarios.forEach(s => {
          calculateSummaryGrowth(`-scenario-${s.id}`);
        });
      });
    },
    addSummaryCagr(years) {
      this.summary.histCagr = calculateCagr(
        this.summary[years[0]],
        this.summary[years[2]],
        2
      );
      this.summary.t3Cagr = calculateCagr(
        this.summary[years[2]],
        this.summary[years[2 + 3]],
        3
      );
      this.summary.t5Cagr = calculateCagr(
        this.summary[years[2]],
        this.summary[years[2 + 5]],
        5
      );

      const getCalculateCagr = (startIndex, yearsCagr, suffix) => {
        try {
          const startProp = years[startIndex];
          const endProp = years[startIndex + yearsCagr];
          let startPropName = startProp + suffix;
          let endPropName = endProp + suffix;

          let start = this.summary[startProp];
          let end = this.summary[endProp];

          if (this.summary[startPropName]) start = this.summary[startPropName];
          if (this.summary[endPropName]) end = this.summary[endPropName];

          this.summary[`t${yearsCagr}Cagr${suffix}`] = calculateCagr(
            start,
            end,
            yearsCagr
          );
        } catch {
          this.summary[`t${yearsCagr}Cagr${suffix}`] = 0;
        }
      };

      getCalculateCagr(2, 3, `-original`);
      getCalculateCagr(2, 5, `-original`);

      this.summary.scenarios.forEach(s => {
        getCalculateCagr(2, 3, `-scenario-${s.id}`);
        getCalculateCagr(2, 5, `-scenario-${s.id}`);
      });

      getCalculateCagr(2, 3, `-forecast`);
      getCalculateCagr(2, 5, `-forecast`);
    },
    exportToCSV() {
      this.exportForecastToCSV();
    },
    forecastLookup(forecast, item) {
      return filterDataItem(forecast, item);
    },
    getScenariosIdAndName(scenarios) {
      const result = [];
      Object.keys(scenarios).forEach(id => {
        result.push({ id, name: this.getScenarioName(id) });
      });

      return result;
    },
    scenariosLookup(scenarios, item) {
      const result = [];
      const scenariosIDs = Object.keys(scenarios);

      scenariosIDs.forEach(id => {
        const s = scenarios[id].filter(dataItemFilter(item));
        if (s != null && s.length > 0) {
          result.push({
            id,
            name: this.getScenarioName(id),
            item: s[0],
          });
        }
      });
      return result;
    },
    getScenariosDataPoints(scenarios) {
      const result = {};
      const scenariosIDs = Object.keys(scenarios);

      scenariosIDs.forEach(id => {
        let dataPoints = [];

        if (scenarios[id].length > 0) {
          dataPoints = scenarios[id][0].values.map(v => ({ time: v.time }));
        }

        result[id] = dataPoints;
      });
      return result;
    },
    getValueIfDifferent(value, valueToCompare) {
      if (
        !isNaN(value) &&
        value != null &&
        // value != 0 &&
        !isNaN(valueToCompare) &&
        valueToCompare != null &&
        // valueToCompare != 0 &&
        value.toFixed(3) != valueToCompare.toFixed(3)
      ) {
        return value;
      }

      return null;
    },
    expandRow(row, { isExpanded, expand }) {
      if (row.hasDetails) expand(!isExpanded);
    },
    getCheckTypeDecriptions(alerts) {
      if (this.checkTypeCatalog == null) return alerts;
      var descriptions = [];
      alerts.forEach(a => {
        descriptions.push(this.checkTypeCatalog[a] || a);
      });
      return descriptions;
    },
    ...mapActions('project', [
      'setViewType',
      'exportForecastToCSV',
      'exportFineTuningToCSV',
      'setFinetuning',
      'deleteFineTuning',
      'setViewType',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.viewType {
  width: 125px;
}

.fact,
.lastMonth {
  width: 110px;
}
.small {
  font-size: 12px;
}

.value-forecast {
  color: var(--v-info-base);
  font-weight: 500;
}
.forecasted {
  background-color: #e8eaf6;
}

.imputed-block,
.forecasted-block {
  background-color: #e8eaf6;
  border: 1px solid gray;
  height: 1em;
  width: 1em;
  margin-top: 2px;
  margin-right: 3px;
}
tr.font-weight-bold > td,
tr.font-weight-medium > td {
  border: none !important;
}
.imputed-block {
  background-color: #ffb300;
}

.footer {
  width: 220px;
  margin-top: 10px;
  margin-left: 15px;
}

.summary-bottom-line {
  border-bottom-color: var(--v-secondary-lighten4) !important;
  height: 2px !important;
}

.fine-tuning-cell {
  padding-top: 5px;
  height: 44px;
  vertical-align: middle;
}

.finetuning-test {
  text-align-last: center;
}

.fine-tuning-divider {
  width: 790px !important;
}

.button {
  width: 100px;
}

.viewType {
  width: 148px;
}

// .checkType-icon {
// //
// }
</style>
