<template>
  <div style="width:100%;">
    <v-layout child-flex>
      <v-data-table
        :headers="headers"
        :items="dataTable"
        :loading="loadingForecast"
        hide-default-header
        hide-default-footer
        class="elevation-1 myclass"
        fixed-header
        disable-pagination
      >
        <!-- TOP -->
        <template #top>
          <v-container fluid>
            <v-row>
              <h3 class="ml-5">
                MAT:
                <span class="ml-1 font-italic">{{
                  `${lastMonth} ${matYear}`
                }}</span>
              </h3>
            </v-row>
            <v-row>
              <v-col align-self="start">
                <v-select
                  v-model="dataSource"
                  :items="dataSourceList"
                  item-value="id"
                  item-text="name"
                  hide-details
                  outlined
                  dense
                >
                  <template #selection="{item}">
                    <span v-if="item.id == GROUND_FORECAST">
                      {{ item.name }}
                    </span>
                    <span
                      v-if="item.id !== GROUND_FORECAST && item.id !== FORECAST"
                      :style="`color:${scenarioColorHash(item)}`"
                    >
                      {{ `${item.id}-${item.name}` }}
                    </span>
                    <span v-if="item.id === FORECAST" class="blue-grey--text">
                      {{ item.name }}
                    </span>
                  </template>
                  <template #item="{item}">
                    <span v-if="item.id == GROUND_FORECAST">
                      {{ item.name }}
                    </span>
                    <span
                      v-if="item.id !== GROUND_FORECAST && item.id !== FORECAST"
                      :style="`color:${scenarioColorHash(item)}`"
                    >
                      {{ `${item.id}-${item.name}` }}
                    </span>
                    <span v-if="item.id === FORECAST" class="blue-grey--text">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <heat-map-meter />
              </v-col>
            </v-row>
          </v-container>
        </template>
        <!-- HEADERS -->
        <template #header="{ props: { headers } }">
          <thead>
            <tr class="header">
              <th v-if="!loadingForecast" class="text-center">
                <v-row justify="center" no-gutters>
                  <v-btn text @click="drillOut()" v-if="stack.length > 0">
                    <v-icon left>mdi-arrow-left-circle-outline</v-icon>Go back
                  </v-btn>
                </v-row>
              </th>
              <th v-for="h in headers" :key="h.value" class="text-center">
                <v-row justify="center" no-gutters>
                  <v-checkbox
                    v-if="h.value !== 'all' && !loadingForecast"
                    v-model="lineChartCols"
                    dense
                    hide-details
                    :value="h.value"
                    :key="h.value"
                    class="mt-0"
                    :disabled="checkDisableLineChartCols(h.value)"
                  >
                    <template v-slot:append>
                      <v-btn
                        v-if="drillDataCellEnabled(null, h.value)"
                        text
                        @click="drillDataCell(null, h.value)"
                        class="mt-n1"
                        >{{ h.text }}
                      </v-btn>
                      <span
                        v-else
                        style="white-space: nowrap ;"
                        class="font-weight-medium text-sm-body-2"
                        >{{ h.text }}
                      </span>
                    </template>
                  </v-checkbox>
                  <span
                    v-else-if="h.value === 'all'"
                    class="text-center font-weight-bold font-italic text-uppercase text-sm-body-2"
                    >{{ h.text }}</span
                  >
                </v-row>
              </th>
            </tr>
          </thead>
        </template>
        <!-- ITEMS -->
        <template #item="{ item, index }">
          <tr>
            <td :key="index">
              <v-checkbox
                v-model="lineChartRows"
                dense
                hide-details
                class="mt-0"
                :value="item[groupRowsBy]"
                :disabled="checkDisableLineChartRows(item[groupRowsBy])"
                :key="item[groupRowsBy]"
              >
                <template v-slot:append>
                  <v-row align="start">
                    <v-btn
                      v-if="drillDataCellEnabled(item[groupRowsBy])"
                      text
                      @click="drillDataCell(item[groupRowsBy])"
                    >
                      <span>{{ item[groupRowsBy] }} </span>
                    </v-btn>
                    <span
                      v-else
                      style="white-space: nowrap ;"
                      class="font-weight-medium text-sm-body-2"
                      >{{ item[groupRowsBy] }}
                    </span>
                  </v-row>
                </template>
              </v-checkbox>
            </td>
            <td
              v-for="dc in dynamicColumns"
              :key="dc"
              :style="
                ` text-align: center; background:${calculateHeatMapColor(
                  item[`${dc}-cagrs`].t3Cagr
                )};`
              "
            >
              <v-btn
                v-if="drillDataCellEnabled(item[groupRowsBy], dc)"
                text
                @click="drillDataCell(item[groupRowsBy], dc)"
              >
                <heat-map-cell :item="item" :property="dc" :key="dc" />
              </v-btn>
              <heat-map-cell v-else :item="item" :property="dc" :key="dc" />
            </td>

            <td class="text-center font-weight-medium font-italic">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on"> {{ item.all | numberFormat(0) }}</span>
                </template>
                <span>{{ item.all | numberFormat(0) }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <!-- SUMMARY -->
        <template #body.append>
          <tr>
            <td
              @click="drillDataCell()"
              class="font-weight-bold text-uppercase font-italic text--secondary pl-9"
            >
              {{ tableSumary[groupRowsBy] }}
            </td>
            <template v-if="!loadingForecast">
              <td
                v-for="dc in dynamicColumns"
                :key="dc"
                class="font-weight-medium font-italic"
              >
                <v-layout justify-center :key="dc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ tableSumary[dc] | numberFormat(0) }}
                      </span>
                    </template>
                    <span>{{ tableSumary[dc] | numberFormat(0) }}</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>

            <!-- SUMMARY ALL-->
            <td v-if="!loadingForecast" class="font-weight-medium font-italic">
              <v-layout justify-center>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{
                      tableSumary.all | numberFormat(0)
                    }}</span>
                  </template>
                  <span>{{ tableSumary.all | numberFormat(0) }}</span>
                </v-tooltip>
              </v-layout>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import { reactive, computed, toRefs } from '@vue/composition-api';
import HeatMapCell from './HeatMapCell';
import { scenarioColorHash } from '@/global/scenarios';
import {
  calculateHeatMapColor,
  drillProductDimension,
  drillMarketDimension,
  getProductDimProperty,
  getMarketDimProperty,
  calculateCagrs,
  groupBy,
  getGroupsBy,
  GROUND_FORECAST,
  FORECAST,
} from '@/global/guideAnalysis';
import { mutationTypes } from '@/store';
import HeatMapMeter from './HeatMapMeter';
import store from '@/store';
import { filterDataItem, filterDataPoint } from '@/global/common';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'HeatMap',
  components: {
    HeatMapCell,
    HeatMapMeter,
  },
  setup() {
    const {
      state: { project: sto },
    } = store;

    const { SET_LINECHART_COLS, SET_LINECHART_ROWS } = mutationTypes.project;

    const state = reactive({
      tableSumary: {},
      dynamicColumns: [],
      viewType: [],
      dataSource: GROUND_FORECAST,
      //-------------------------- COMPUTED ----------------------------------
      //----------------------------STORE-------------------------------------
      data: computed(() => {
        return !_.isEmpty(sto.forecast) ? sto.forecast.data : {};
      }),
      currentConfig: computed(() => sto.project.config.preferences),
      loadingForecast: computed(() => sto.loadingForecast),
      lastMonth: computed(() => {
        return _.isEmpty(sto.forecast) || _.isEmpty(sto.forecast.lastMonth)
          ? ''
          : moment(sto.forecast.lastMonth, 'yyyy-MM-dd').format('MMMM');
      }),
      matYear: computed(() => {
        const timeSeries = !_.isEmpty(sto.forecast)
          ? sto.forecast.timeSeries
          : [];

        if (timeSeries.length > 0) return timeSeries[2].points[0];

        return '';
      }),
      stack: computed(() => sto.project.config.historyStack),
      lineChartCols: computed({
        get() {
          return sto.project.config.lineChartCols;
        },
        set(value) {
          setTimeout(() => {
            store.commit(`project/${SET_LINECHART_COLS}`, value);
          }, 300);
        },
      }),

      lineChartRows: computed({
        get() {
          return sto.project.config.lineChartRows;
        },
        set(value) {
          setTimeout(() => {
            store.commit(`project/${SET_LINECHART_ROWS}`, value);
          }, 300);
        },
      }),
      //---------------------------------------------------------------------
      disableLineChartCols: computed(() => state.lineChartRows.length > 1),
      disableLineChartRows: computed(() => state.lineChartCols.length > 1),
      productDimension: computed(() => state.currentConfig.productDimension),
      marketDimension: computed(() => state.currentConfig.marketDimension),
      groupRowsBy: computed(() =>
        getProductDimProperty(state.productDimension)
      ),
      groupColsBy: computed(() => getMarketDimProperty(state.marketDimension)),
      groundForecastData: computed(() => state.data.groundForecast),
      forecastData: computed(() => {
        if (_.isEmpty(state.data) && _.isEmpty(state.data.forecast)) return {};
        return state.data.forecast;
      }),
      isTuned: computed(() => {
        return !_.isEmpty(sto.forecast) ? sto.forecast.tuned : false;
      }),
      scenariosData: computed(() => {
        if (_.isEmpty(state.data) && _.isEmpty(state.data.scenarios)) return {};
        return state.data.scenarios;
      }),
      scenariosIdAndName: computed(() => {
        const result = [];

        if (!state.scenariosData) return result;
        Object.keys(state.scenariosData).forEach(id => {
          result.push({
            id,
            name: store.getters['scenario/getScenarioName'](id),
          });
        });

        return result;
      }),
      dataSourceList: computed(() => {
        let list = [];

        list.push({
          id: GROUND_FORECAST,
          name: state.isTuned ? 'Fine tuned forecast' : 'Ground Forecast',
        });

        state.scenariosIdAndName.forEach(s => {
          list.push(s);
        });
        if (!_.isEmpty(state.forecastData))
          list.push({ id: FORECAST, name: 'All Scenarios Combined' });

        return list;
      }),
      alternativeDataSource: computed(() => {
        if (state.dataSource === FORECAST) {
          return state.forecastData;
        } else if (state.dataSource !== GROUND_FORECAST) {
          return state.scenariosData[state.dataSource];
        }

        return null;
      }),
      //---------------------------------------------------------------------
    });

    // COMPUTED
    // const enableDrillOut = computed(() => state.stack.length > 0);
    const headers = computed(() => buildHeaders());
    const dataTable = computed(() => normalizeData());

    // METHODS
    const buildHeaders = () => {
      let headers = [];

      if (!_.isEmpty(state.groundForecastData)) {
        state.dynamicColumns = getGroupsBy(
          state.groundForecastData,
          state.groupColsBy
        );
      }

      state.dynamicColumns.forEach(dc => {
        headers.push({ text: dc, align: 'center', value: dc, width: 120 });
      });

      headers.push({
        text: `All ${state.groupColsBy}`,
        align: 'center',
        value: 'all',
        width: 120,
      });
      return headers;
    };

    const normalizeData = () => {
      const grid = [];

      state.tableSumary = {};
      state.tableSumary[state.groupRowsBy] = `All ${state.groupRowsBy}`;

      if (_.isEmpty(state.groundForecastData)) return grid;

      const groupRows = groupBy(state.groundForecastData, state.groupRowsBy);

      let altGroupRows = null;

      if (state.alternativeDataSource != null)
        altGroupRows = groupBy(state.alternativeDataSource, state.groupRowsBy);
      //-----------------------------------------------------------------------

      // Init summary to 0
      state.dynamicColumns.forEach(dc => (state.tableSumary[dc] = 0));
      state.tableSumary.all = 0;

      Object.keys(groupRows).forEach(k => {
        let n = {};
        n[state.groupRowsBy] = k;
        n.all = 0;

        //Columns-------
        const groupCols = groupBy(groupRows[k], state.groupColsBy);

        const altGroupCols = !_.isEmpty(altGroupRows)
          ? groupBy(altGroupRows[k], state.groupColsBy)
          : null;

        state.dynamicColumns.forEach(dc => {
          let value = {};

          n[dc] = 0;

          if (!groupCols[dc]) {
            n[`${dc}-cagrs`] = {};
            return;
          }

          const sGroupCol = altGroupCols ? altGroupCols[dc] : null;

          value = groupCols[dc].reduce((acc, curr) => {
            let altCurr = filterDataItem(sGroupCol, curr);

            curr.values.forEach((v, index) => {
              if (!acc[`i${index}`]) acc[`i${index}`] = 0;

              let result = v.value;

              if (altCurr) {
                const point = filterDataPoint(altCurr.values, v);

                if (point && point.value) result = point.value;
              }
              acc[`i${index}`] += result;
            });

            return acc;
          }, {});

          const calculateFromindex = 2; //3rd year

          const cagrs = calculateCagrs(calculateFromindex, value);
          n[`${dc}-cagrs`] = cagrs;

          const finalValue = value[`i${calculateFromindex}`];

          n[dc] = finalValue; // cell
          n.all += finalValue; //total by row
          state.tableSumary[dc] += finalValue; //total by column
          state.tableSumary.all += finalValue; //global total
        });

        grid.push(n);
      });

      return grid;
    };

    const drillDataCell = (rowValue, colValue) => {
      const nexMarketDimension = state.marketDimension
        ? drillMarketDimension(state.marketDimension).next
        : null;
      const nextProductDimension = state.productDimension
        ? drillProductDimension(state.productDimension).next
        : null;

      const newPref = {};

      if (rowValue) newPref[state.groupRowsBy] = [rowValue];
      if (colValue) newPref[state.groupColsBy] = [colValue];

      if (colValue && nexMarketDimension) {
        newPref.marketDimension = nexMarketDimension.dimension;
      }
      if (rowValue && nextProductDimension) {
        newPref.productDimension = nextProductDimension.dimension;
      }

      applyConfiguration(newPref);
    };

    const drillDataCellEnabled = (rowValue, colValue) => {
      const nexMarketDimension = state.marketDimension
        ? drillMarketDimension(state.marketDimension).next
        : null;
      const nextProductDimension = state.productDimension
        ? drillProductDimension(state.productDimension).next
        : null;

      if (
        (colValue && nexMarketDimension) ||
        (rowValue && nextProductDimension)
      ) {
        return true;
      }

      return (
        !state.currentConfig[state.groupRowsBy].includes(rowValue) &&
        !state.currentConfig[state.groupColsBy].includes(colValue)
      );
    };

    const drillOut = () => {
      if (state.stack.length <= 0) return;
      restoreLastConfiguration();
    };

    const applyConfiguration = payload =>
      store.dispatch('project/applyConfiguration', payload);

    const restoreLastConfiguration = payload =>
      store.dispatch('project/restoreLastConfiguration', payload);

    const checkDisableLineChartCols = value => {
      if (state.lineChartCols.includes(value)) return false;
      return state.lineChartRows.length > 1 && state.lineChartCols.length > 0;
    };
    const checkDisableLineChartRows = value => {
      if (state.lineChartRows.includes(value)) return false;
      return state.lineChartCols.length > 1 && state.lineChartRows.length > 0;
    };

    return {
      ...toRefs(state),
      drillDataCell,
      drillOut,
      calculateHeatMapColor,
      drillDataCellEnabled,
      buildHeaders,
      normalizeData,
      headers,
      dataTable,
      scenarioColorHash,
      GROUND_FORECAST,
      FORECAST,
      checkDisableLineChartCols,
      checkDisableLineChartRows,
    };
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.header > th {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
