import { scenarioService } from '@/services';
import { mutationTypes as projectMutations } from '@/store/guideAnalysis.module';

const state = {
  loadingPredefined: false,
  loadingScenarios: false,
  loadingCreate: false,
  loadingUpdate: false,
  predefined: new Map(),
  all: [],
  private: {
    predefinedScenariosPromise: null,
  },
};

const SET_SCENARIO_ACTIVE = 'SET_SCENARIO_ACTIVE';
const CLEAR_SCENARIOS = 'CLEAR_SCENARIOS';

export const publicMutations = { SET_SCENARIO_ACTIVE, CLEAR_SCENARIOS };

const PREDEFINED_REQUESTED = 'PREDEFINED_REQUESTED';
const PREDEFINED_RECEIVED = 'PREDEFINED_RECEIVED';
const PREDEFINED_FAILED = 'PREDEFINED_FAILED';

const PROJECT_SCENARIOS_REQUEST = 'PROJECT_SCENARIOS_REQUEST';
const PROJECT_SCENARIOS_SUCCESS = 'PROJECT_SCENARIOS_SUCCESS';
const PROJECT_SCENARIOS_FAILURE = 'PROJECT_SCENARIOS_FAILURE';

const CREATE_REQUEST = 'CREATE_REQUEST';
const CREATE_SUCCESS = 'CREATE_SUCCESS';
const CREATE_FAILURE = 'CREATE_FAILURE';

const UPDATE_REQUEST = 'UPDATE_REQUEST';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
const UPDATE_FAILURE = 'UPDATE_FAILURE';

const DELETE_REQUEST = 'DELETE_REQUEST';
const DELETE_SUCCESS = 'DELETE_SUCCESS';
const DELETE_FAILURE = 'DELETE_FAILURE';

const getters = {
  activeScenarioIds: state => {
    return state.all
      .filter(scenario => scenario.active)
      .map(scenario => scenario.scenarioId);
  },
  getScenarioName: state => id => {
    const scenario = state.all.find(scenario => scenario.scenarioId == id);
    return scenario && scenario.name ? scenario.name : 'not found';
  },
};

const actions = {
  getPredefined({ commit, state }) {
    if (!state.private.predefinedScenariosPromise) {
      const predefinedScenariosPromise = scenarioService
        .getPredefined()
        .then(allPredefined => commit(PREDEFINED_RECEIVED, allPredefined))
        .catch(error => {
          commit(PREDEFINED_FAILED);
          throw error;
        });
      commit(PREDEFINED_REQUESTED, predefinedScenariosPromise);
    }
    return state.private.predefinedScenariosPromise;
  },
  async getCurrentProjectScenarios({ dispatch, commit, rootState }) {
    try {
      commit(PROJECT_SCENARIOS_REQUEST);
      // Scenario's predefinitions must be available in the store

      await dispatch('getPredefined');

      const scenarios = rootState.project.project.scenarios
        ? rootState.project.project.scenarios
        : [];
      commit(PROJECT_SCENARIOS_SUCCESS, scenarios);
    } catch (error) {
      commit(PROJECT_SCENARIOS_FAILURE);
      throw error;
    }
  },
  async updateAll({ commit, state }, projectId) {
    try {
      const updates = state.all.map(updatePayloadExtractor(projectId));
      if (updates.length > 0) {
        commit(UPDATE_REQUEST);
        await Promise.all(
          updates.map(updatePayload =>
            scenarioService
              .update(updatePayload)
              .then(updatedScenario => commit(UPDATE_SUCCESS, updatedScenario))
          )
        );
        commitScenariosToProject(commit, state);
      }
    } catch (error) {
      commit(UPDATE_FAILURE);
      throw error;
    }
  },
  create({ commit, state }, createPayload) {
    commit(CREATE_REQUEST);
    return scenarioService
      .create(createPayload)
      .then(createdScenario => {
        commit(CREATE_SUCCESS, createdScenario);
        commitScenariosToProject(commit, state);
      })
      .catch(error => {
        commit(CREATE_FAILURE);
        throw error;
      });
  },
  update({ commit, state }, updatePayload) {
    commit(UPDATE_REQUEST);
    return scenarioService
      .update(updatePayload)
      .then(updatedScenario => {
        commit(UPDATE_SUCCESS, updatedScenario);
        commitScenariosToProject(commit, state);
      })
      .catch(error => {
        commit(UPDATE_FAILURE);
        throw error;
      });
  },
  delete({ commit, state }, scenarioId) {
    commit(DELETE_REQUEST);
    return scenarioService
      .erase(scenarioId)
      .then(() => {
        commit(DELETE_SUCCESS, scenarioId);
        commitScenariosToProject(commit, state);
      })
      .catch(error => {
        commit(DELETE_FAILURE);
        throw error;
      });
  },
};

const mutations = {
  [PREDEFINED_REQUESTED](state, predefinedScenariosPromise) {
    state.loadingPredefined = true;
    state.private.predefinedScenariosPromise = predefinedScenariosPromise;
  },
  [PREDEFINED_RECEIVED](state, predefinedScenarios) {
    let predefined = new Map();
    for (const scenario of predefinedScenarios) {
      predefined.set(scenario.id, scenario);
    }
    state.predefined = predefined;
    state.loadingPredefined = false;
  },
  [PREDEFINED_FAILED](state) {
    state.predefined = new Map();
    state.loadingPredefined = false;
    state.private.predefinedScenariosPromise = null;
  },
  [CLEAR_SCENARIOS](state) {
    state.all = [];
  },
  [PROJECT_SCENARIOS_REQUEST](state) {
    state.loadingScenarios = true;
  },
  [PROJECT_SCENARIOS_SUCCESS](state, scenarios) {
    state.all = scenarios.map(scenario => {
      let name = getPredefinedName(state, scenario);
      return { ...scenario, name };
    });
    state.loadingScenarios = false;
  },
  [PROJECT_SCENARIOS_FAILURE](state) {
    state.all = [];
    state.loadingScenarios = false;
  },
  [SET_SCENARIO_ACTIVE](state, { scenarioId, active }) {
    state.all = state.all.map(item =>
      item.scenarioId === scenarioId ? { ...item, active } : item
    );
  },
  [CREATE_REQUEST](state) {
    state.loadingCreate = true;
  },
  [CREATE_SUCCESS](state, createdScenario) {
    state.all.push({
      ...createdScenario,
      name: getPredefinedName(state, createdScenario),
    });
    state.loadingCreate = false;
  },
  [CREATE_FAILURE](state) {
    state.loadingCreate = false;
  },
  [UPDATE_REQUEST](state) {
    state.loadingUpdate = true;
  },
  [UPDATE_SUCCESS](state, updatedScenario) {
    state.all = state.all.map(item => {
      if (item.scenarioId === updatedScenario.scenarioId) {
        let name = getPredefinedName(state, updatedScenario);
        return { ...item, ...updatedScenario, name };
      } else {
        return item;
      }
    });
    state.loadingUpdate = false;
  },
  [UPDATE_FAILURE](state) {
    state.loadingUpdate = false;
  },
  [DELETE_REQUEST](state) {
    state.loadingScenarios = true;
  },
  [DELETE_SUCCESS](state, deletedScenarioId) {
    state.all = state.all.filter(item => item.scenarioId !== deletedScenarioId);
    state.loadingScenarios = false;
  },
  [DELETE_FAILURE](state) {
    state.loadingScenarios = false;
  },
};

function getPredefinedName(state, scenario) {
  let predefined = state.predefined.get(scenario.predefinedScenarioId);
  return predefined ? predefined.title : 'Unknown';
}

function updatePayloadExtractor(projectId) {
  // The scenario model loaded from the API comes with more info than
  // needed to update or create it (e.g. user id, timestamps).
  return ({
    scenarioId,
    active,
    config,
    filters,
    predefinedScenarioId,
    scope,
  }) => {
    return {
      projectId,
      scenarioId,
      active,
      config,
      filters,
      predefinedScenarioId,
      scope,
    };
  };
}

/**
 * Grab the list of scenarios in this vuex module and set in project module.
 * TODO: remove this state duplication.
 */
function commitScenariosToProject(commit, state) {
  commit(
    `project/${projectMutations.SET_PROJECT}`,
    { scenarios: state.all },
    {
      root: true,
    }
  );
}

export const scenario = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
