import API from './API.service';

export const scenarioService = {
  getPredefined,
  create,
  get,
  update,
  erase,
  getCurve,
};

function getPredefined() {
  let endpoint = `/scenario/predefined`;
  return API.get(endpoint);
}

function create(scenario) {
  let endpoint = `/scenario`;
  return API.post(endpoint, scenario);
}

function get(scenarioId) {
  let endpoint = `/scenario/${scenarioId}`;
  return API.get(endpoint);
}

function update(scenario) {
  let endpoint = `/scenario/${scenario.scenarioId}`;
  return API.post(endpoint, scenario);
}

function erase(scenarioId) {
  let endpoint = `/scenario/${scenarioId}`;
  return API.delete(endpoint);
}

function getCurve(scenario) {
  let endpoint = '/scenario/curve';
  return API.post(endpoint, scenario);
}
