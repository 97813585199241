import { projectListService } from '@/services';

const state = {
  loading: true,
  all: {},
};

const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
const SET_PROJECTS = 'SET_PROJECTS';
const UPDATE_NAMES = 'UPDATE_NAMES';
const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

const COPY_PROJECT_REQUEST = 'COPY_PROJECT_REQUEST';
const COPY_PROJECT_SUCCESS = 'COPY_PROJECT_SUCCESS';
const COPY_PROJECT_FAILURE = 'COPY_PROJECT_FAILURE';

const actions = {
  async get({ commit, state, dispatch, rootState }, paginationOptions) {
    commit(GET_PROJECTS_REQUEST);

    await projectListService.getPaginated(paginationOptions).then(
      paginated => commit(SET_PROJECTS, paginated),
      error => commit(GET_PROJECTS_FAILURE, error)
    );

    // Get cached (or not) user names from the user module.
    if (state.all.content) {
      const userIds = state.all.content.map(proj => proj.user.id);
      await dispatch('users/get', userIds, { root: true });
      commit(UPDATE_NAMES, rootState.users.all);
    }
    commit(GET_PROJECTS_SUCCESS);
  },
  copyProject({ commit }, projectId) {
    commit(COPY_PROJECT_REQUEST);

    return projectListService.copyProject(projectId).then(
      newProject => {
        commit(COPY_PROJECT_SUCCESS, newProject);
        return newProject;
      },
      error => commit(COPY_PROJECT_FAILURE, error)
    );
  },
};

const mutations = {
  [GET_PROJECTS_REQUEST](state) {
    state.loading = true;
  },
  [SET_PROJECTS](state, paginatedProjects) {
    state.all = paginatedProjects;
  },
  [UPDATE_NAMES](state, usersMap) {
    if (state.all.content instanceof Array) {
      state.all.content = state.all.content.map(project => {
        const name = usersMap.get(project.user.id);
        if (name) {
          const user = { ...project.user, name };
          return { ...project, user };
        } else return project;
      });
    }
  },
  [GET_PROJECTS_SUCCESS](state) {
    state.loading = false;
  },
  [GET_PROJECTS_FAILURE](state, error) {
    state.loading = false;
    state.error = error;
    state.all = {};
  },
  [COPY_PROJECT_REQUEST](state) {
    state.loading = true;
  },
  [COPY_PROJECT_SUCCESS](state, newProject) {
    state.loading = false;
    // We should have loaded at least one project in order to have copied it.
    if (state.all.content instanceof Array) {
      state.all.content.push(newProject);
      state.all.totalElements++;
    }
  },
  [COPY_PROJECT_FAILURE](state, error) {
    state.loading = false;
    state.error = error;
  },
};

export const projectList = {
  namespaced: true,
  state,
  actions,
  mutations,
};
