var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{staticClass:"panels",attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Scenarios")]),_c('v-col',{staticClass:"text--secondary",attrs:{"cols":"4"}},[_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(!open)?_c('span',{key:"0"},[_vm._v(" Active: "+_vm._s(_vm.scenariosActive)+" ")]):_vm._e()])],1),_c('v-col',{staticClass:"text--secondary",attrs:{"cols":"4"}},[_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(!open)?_c('span',{key:"0"},[_vm._v(" Inactive: "+_vm._s(_vm.scenariosInactive)+" ")]):_vm._e()])],1)],1)]}}])}),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1 space mb-4",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.scenarios,"item-key":"id","no-data-text":_vm.noScenariosText,"hide-default-footer":"","dense":""},on:{"click:row":_vm.openEditDialog},scopedSlots:_vm._u([{key:"item.scenarioId",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.active ? ("color:" + (_vm.scenarioColor(item)) + ";") : '')},[_vm._v(_vm._s(item.scenarioId))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.active ? ("color:" + (_vm.scenarioColor(item)) + ";") : '')},[_vm._v(_vm._s(item.name))])]}},{key:"item.filters.powerBrand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncateArray")(item.filters.powerBrand))+" ")]}},{key:"item.filters.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncateArray")(item.filters.country))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticClass:"mr-4",attrs:{"value":item.active,"ripple":false,"disabled":!_vm.userOwnsProject || _vm.loadingUpdate},on:{"input":function($event){return _vm.setScenarioActive(Object.assign({}, item, {active: $event}))}}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteDialog(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete scenario")])])]}}])}),(_vm.userOwnsProject)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","disabled":!_vm.applyEnabled},on:{"click":_vm.emitApply}},[_vm._v("Apply")]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","outlined":"","disabled":!_vm.addScenarioEnabled},on:{"click":_vm.openCreateDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add_circle_outline")]),_vm._v("Add scenario")],1)],1):_vm._e(),_c('scenario-dialog-form',{attrs:{"show":_vm.showScenarioDialog,"scenario":_vm.editingScenario,"filtersSuggestion":_vm.projectConfiguration,"readOnly":!_vm.userOwnsProject},on:{"update:show":function($event){_vm.showScenarioDialog=$event}}})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Delete "+_vm._s(_vm.deletingScenario ? _vm.deletingScenario.name : '')+" scenario")])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Do you want to "),_c('u',[_vm._v("permanently delete")]),_vm._v(" this scenario? ")]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.deletingScenario && _vm.deletingScenario.filters.powerBrand.length
              ),expression:"\n                deletingScenario && deletingScenario.filters.powerBrand.length\n              "}],staticClass:"subtitle-2",attrs:{"cols":"12"}},[_vm._v("Brands")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm._f("arrayToString")(_vm.deletingScenario ? _vm.deletingScenario.filters.powerBrand : [])))]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.deletingScenario && _vm.deletingScenario.filters.country.length
              ),expression:"\n                deletingScenario && deletingScenario.filters.country.length\n              "}],staticClass:"subtitle-2",attrs:{"cols":"12"}},[_vm._v("Countries")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm._f("arrayToString")(_vm.deletingScenario ? _vm.deletingScenario.filters.country : [])))])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.deleteLoading},on:{"click":function($event){return _vm.confirmDelete(_vm.deletingScenario)}}},[_vm._v("Delete")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.deleteLoading},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }