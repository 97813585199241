import API from './API.service';

export const dataSourceService = {
  getFilters,
  getDumpDates,
  getAll,
  getCatalogs,
};

function getDumpDates(scope) {
  let endpoint = `/data-source/dump-dates?scope=${scope}`;
  return API.get(endpoint);
}

function getCatalogs({ gbuId, scopeId, dumpDate }) {
  let endpoint = `/data-source/catalogs?gbuId=${gbuId}&scopeId=${scopeId}&dumpDate=${dumpDate}`;
  return API.get(endpoint);
}

function getAll() {
  let endpoint = `/data-source`;
  return API.get(endpoint);
}

function getFilters({
  gbuId,
  scopeId,
  dumpDate,
  groundForecastId,
  productDimension,
}) {
  let endpoint = `/data-source/filters?gbuId=${gbuId}&scopeId=${scopeId}&dumpDate=${dumpDate}&groundForecastId=${groundForecastId}&productDimension=${productDimension}`;
  return API.get(endpoint);
}
