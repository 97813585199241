import { dataSourceService } from '@/services';

const state = {
  loadingDumpDates: false,
  loadingParameters: false,

  loadingDataSources: false,
  dataSources: null,
  loadingCatalogs: false,
  catalogs: null,

  dumpDates: [],
  cachedDumpDatesScope: null,
  cachedCatalogs: new Map(),
  private: {
    dumpDatePromise: null,
    catalogPromises: new Map(),
    catalogsTracker: 1,
  },
};

const getters = {
  getPowerBrandCatalog: state => {
    return makeCatalogGetter(state, 'powerBrandCatalog');
  },
  getAreaCatalog: state => {
    return makeCatalogGetter(state, 'areaCatalog');
  },
  getGdpOutlookCatalog: state => {
    return makeCatalogGetter(state, 'gdpOutlookCatalog');
  },
  getNewbornOutlookCatalog: state => {
    return makeCatalogGetter(state, 'newbornOutlookCatalog');
  },
  // eslint-disable-next-line no-unused-vars
  getProject: (_, __, rootState) => {
    return rootState.project.project.groundForecastId;
  },
};

function makeCacheKey({ scope, productDimension, dumpDate }) {
  return `${scope}-${productDimension}-${dumpDate}`;
}

function makeCatalogGetter(state, catalogName) {
  return ({ scope, productDimension, dumpDate }) => {
    const cacheKey = makeCacheKey({ scope, productDimension, dumpDate });
    if (state.private.catalogsTracker && state.cachedCatalogs.has(cacheKey)) {
      const catalog = state.cachedCatalogs.get(cacheKey)[catalogName];
      return catalog ? catalog : [];
    }
    return [];
  };
}

const DATA_SOURCES_LOADING = 'DATA_SOURCES_LOADING';
const DATA_SOURCES_RECEIVED = 'DATA_SOURCES_RECEIVED';
const DATA_SOURCES_FAILED = 'DATA_SOURCES_FAILED';

const CATALOGS_LOADING = 'CATALOGS_LOADING';
const CATALOGS_RECEIVED = 'OUTLOOK_CATALOGS_RECEIVEDRECEIVED';
const CATALOGS_FAILED = 'CATALOGS_FAILED';

const FILTER_CATALOGS_REQUESTED = 'FILTER_CATALOGS_REQUESTED';
const FILTER_CATALOGS_RECEIVED = 'FILTER_CATALOGS_RECEIVED';
const FILTER_CATALOGS_FAILED = 'FILTER_CATALOGS_FAILED';

const actions = {
  getDataSources({ commit, state }) {
    if (!state.dataSources) {
      commit(DATA_SOURCES_LOADING);
      // const dumpDatePromise =
      dataSourceService
        .getAll()
        .then(({ dataSources }) => commit(DATA_SOURCES_RECEIVED, dataSources))
        .catch(() => commit(DATA_SOURCES_FAILED));
      // commit(DATA_SOURCES_REQUESTED, { scope, dumpDatePromise });
    }
    // return state.private.dumpDatePromise;
  },
  clearCatalogs({ commit }) {
    commit(CATALOGS_RECEIVED, []);
  },
  getCatalogs({ commit }, { gbuId, scopeId, dumpDate }) {
    commit(CATALOGS_LOADING);
    dataSourceService
      .getCatalogs({ gbuId, scopeId, dumpDate })
      .then(data => commit(CATALOGS_RECEIVED, data))
      .catch(() => commit(CATALOGS_FAILED));
  },

  getFilters(
    { commit, state, getters },
    { gbuId, scopeId, dumpDate, productDimension }
  ) {
    const cacheKey = makeCacheKey({
      gbuId,
      scopeId,
      dumpDate,
      groundForecastId,
      productDimension,
    });
    if (!state.private.catalogPromises.has(cacheKey)) {
      var groundForecastId = getters.getProject;
      const catalogPromise = dataSourceService
        .getFilters({
          gbuId,
          scopeId,
          dumpDate,
          groundForecastId,
          productDimension,
        })
        .then(payload =>
          commit(FILTER_CATALOGS_RECEIVED, { cacheKey, payload })
        )
        .catch(() => commit(FILTER_CATALOGS_FAILED, cacheKey));

      commit(FILTER_CATALOGS_REQUESTED, { cacheKey, catalogPromise });
    }
    return state.private.catalogPromises.get(cacheKey);
  },
};

const mutations = {
  [DATA_SOURCES_LOADING](state) {
    state.loadingDataSources = true;
    state.dataSources = [];
  },
  [DATA_SOURCES_RECEIVED](state, dataSources) {
    state.dataSources = dataSources;
    state.loadingDataSources = false;
  },
  [DATA_SOURCES_FAILED](state) {
    state.dataSources = [];
    state.loadingCatalogs = false;
  },
  [CATALOGS_LOADING](state) {
    state.loadingCatalogs = true;
    state.catalogs = [];
  },
  [CATALOGS_RECEIVED](state, catalogs) {
    state.catalogs = catalogs;
    state.loadingCatalogs = false;
  },
  [CATALOGS_FAILED](state) {
    state.catalogs = [];
    state.loadingCatalogs = false;
  },

  [FILTER_CATALOGS_REQUESTED](state, { cacheKey, catalogPromise }) {
    state.loadingParameters = true;
    state.private.catalogPromises.set(cacheKey, catalogPromise);
  },
  [FILTER_CATALOGS_RECEIVED](state, { cacheKey, payload }) {
    // Trim the cache if it's getting big
    if (state.cachedCatalogs.size > 6) {
      const oldestKey = state.cachedCatalogs.keys().next().value;
      state.cachedCatalogs.delete(oldestKey);
      state.private.catalogPromises.delete(oldestKey);
    }
    const catalogs = {
      powerBrandCatalog: normalizeCatalog(
        payload.filters.filter(p => p.name !== null)
      ),
      areaCatalog: normalizeCatalog(payload.areas.filter(r => r.name !== null)),
    };
    state.cachedCatalogs.set(cacheKey, catalogs);
    // Trigger Vue reactivity for the adition in the Map (since Vue supports
    // Map for version > 3.0 only)
    state.private.catalogsTracker = 1 + (state.private.catalogsTracker % 2);
    state.loadingParameters = false;
  },
  [FILTER_CATALOGS_FAILED](state, cacheKey) {
    state.cachedCatalogs.delete(cacheKey);
    state.private.catalogPromises.delete(cacheKey);
    state.loadingParameters = false;
  },
};

const CHILDREN_NAMES_MAP = new Map([
  ['areas', 'area'],
  ['regions', 'region'],
  ['countries', 'country'],
  ['pillars', 'pillar'],
  ['categories', 'category'],
  ['segments', 'segment'],
  ['subsegments', 'subsegment'],
]);

function normalizeCatalog(catalog) {
  return catalog.map(item => {
    for (const [apiTerm, normalTerm] of CHILDREN_NAMES_MAP) {
      if (Object.prototype.hasOwnProperty.call(item, apiTerm)) {
        item[normalTerm] = item[apiTerm];
        delete item[apiTerm];
        normalizeCatalog(item[normalTerm]);
      }
    }
    return item;
  });
}

export const filterCatalog = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
