<template>
  <div class="container pb-12">
    <v-expansion-panels v-model="panels" flat accordion multiple class="panels">
      <v-expansion-panel>
        <v-expansion-panel-header class="py-0">
          Parameters
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-0">
          <v-container fluid class="py-0 px-0">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="gbu"
                  :items="gbuList"
                  item-value="id"
                  item-text="name"
                  label="Global BU"
                  @change="gbuChanged"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="scope"
                  :items="scopeList"
                  item-value="id"
                  item-text="name"
                  label="Scope"
                  :disabled="!gbu"
                  :loading="loadingDS"
                  @change="scopeChanged"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="dumpDate"
                  :items="dumpDateList"
                  :disabled="!scope"
                  :loading="loadingDS"
                  @change="dumpDateChanged"
                  label="Nielsen GTC date"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                v-if="gdpOutlook != null && gdpOutlook != gdpOutlookUnified"
                cols="12"
              >
                <v-select
                  v-model="gdpOutlook"
                  :items="gdpOutlookList"
                  :loading="loadingCatalogsDS"
                  :disabled="true"
                  item-value="id"
                  item-text="name"
                  label="GDP Outlook"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                v-if="
                  newbornOutlook != null &&
                    newbornOutlook != newbornOutlookUnified
                "
                cols="12"
              >
                <v-select
                  v-model="newbornOutlook"
                  :items="newbornOutlookList"
                  :loading="loadingCatalogsDS"
                  :disabled="true"
                  item-value="id"
                  item-text="name"
                  label="Newborn Outlook"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="fact"
                  :items="factList"
                  item-value="id"
                  item-text="name"
                  label="Fact"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="marketDimension"
                  :items="mktDimCatalog"
                  item-value="key"
                  item-text="value"
                  label="Market Aggregation Level"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="productDimension"
                  :items="prdDimCatalog"
                  item-value="key"
                  item-text="value"
                  label="Product Aggregation Level"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="py-0">
          Filters
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-0">
          <v-container fluid class="py-0 px-0">
            <hierarchy-filters
              :disabled="!isDataSourceSelected"
              :filters="filters"
              :projectConfig="project"
              @update:filters="updateFiltersFromSelection"
            ></hierarchy-filters>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-footer color="white" padless>
      <v-row justify="center" no-gutters class="mt-4 pb-12 pl-6 pr-6">
        <v-col cols="12" class="ma-2">
          <v-btn
            color="primary"
            :loading="applyingConfiguration"
            :disabled="!applyEnabled"
            @click="apply"
            block
          >
            Apply
          </v-btn>
        </v-col>
        <v-col class="ma-2">
          <v-btn
            color="primary"
            outlined
            @click="cancel"
            block
            :disabled="applyingConfiguration"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api';
import useDataSource from '@/composables/data-sources';
import { staticCatalogs } from '@/global/filters';
import HierarchyFilters from '@/components/custom/HierarchyFilters';
import { filterCatalog } from '@/store-mapping';
import store from '@/store';
import _ from 'lodash';

export default {
  name: 'ProjectConfiguration',
  components: {
    HierarchyFilters,
  },
  setup(props, ctx) {
    const { getFilters } = filterCatalog;
    const {
      productDimension: prdDimCatalog,
      marketDimension: mktDimCatalog,
    } = staticCatalogs;

    const {
      gbu,
      scope,
      dumpDate,
      gdpOutlook,
      newbornOutlook,
      fact,
      gbuList,
      scopeList,
      dumpDateList,
      gdpOutlookList,
      newbornOutlookList,
      factList,
      loading: loadingDS,
      loadingCatalogs: loadingCatalogsDS,
      isDataSourceSelected,
      gbuChanged,
      scopeChanged,
      dumpDateChanged,
    } = useDataSource();

    const panels = ref([0]); // first accordion open
    const marketDimension = ref(null);
    const productDimension = ref(null);

    const currentConfig = computed(
      () => store.state.project.project.config.preferences
    );

    const applyingConfiguration = computed(
      () => store.state.project.applyingConfiguration
    );

    const gdpOutlookUnified = computed(() => {
      if (gdpOutlookList.value.length > 0) {
        return gdpOutlookList.value.filter(
          o => o.name.toLowerCase() === 'unified'
        )[0].id;
      }
    });

    const newbornOutlookUnified = computed(() => {
      if (gdpOutlookList.value.length > 0) {
        return newbornOutlookList.value.filter(
          o => o.name.toLowerCase() === 'unified'
        )[0].id;
      }
    });

    const project = computed(() => {
      return {
        gbuId: gbu.value,
        scopeId: scope.value,
        dumpDate: dumpDate.value,
        gdpOutlookId: gdpOutlookUnified.value,
        newbornOutlookId: newbornOutlookUnified.value,
        factId: fact.value,
        marketDimension: marketDimension.value,
        productDimension: productDimension.value,
      };
    });

    const filters = ref({
      powerBrand: [],
      area: [],
      region: [],
      country: [],
      pillar: [],
      category: [],
      segment: [],
      subsegment: [],
    });

    const config = computed(() => {
      return { ...project.value, ...filters.value };
    });

    const loadCurrentConfiguration = () => {
      var clone = _.cloneDeep(currentConfig.value);

      // add my-component component in DOM
      // this.renderComponent = true;
      gbu.value = clone.gbuId;
      scope.value = clone.scopeId;
      dumpDate.value = clone.dumpDate;
      gdpOutlook.value = clone.gdpOutlookId;
      newbornOutlook.value = clone.newbornOutlookId;
      fact.value = clone.factId;

      marketDimension.value = clone.marketDimension;
      productDimension.value = clone.productDimension;

      filters.value = {
        powerBrand: clone.powerBrand,
        area: clone.area,
        region: clone.region,
        country: clone.country,
        pillar: clone.pillar,
        category: clone.category,
        segment: clone.segment,
        subsegment: clone.subsegment,
      };

      if (isDataSourceSelected.value) {
        getFilters(project.value);
      } else {
        filters.value = ctx.options.data().filters;
      }
    };

    watch(
      currentConfig,
      () => {
        loadCurrentConfiguration();
      },
      { deep: true }
    );

    loadCurrentConfiguration();

    const isDirty = computed(() => {
      return !_.isEqual(currentConfig.value, config.value);
    });

    const applyEnabled = computed(
      () =>
        isDataSourceSelected.value &&
        isDirty.value &&
        !applyingConfiguration.value
    );

    const updateFiltersFromSelection = selectedFilters => {
      filters.value = selectedFilters;
    };
    const apply = () => {
      ctx.emit('apply', config.value);
    };

    const cancel = () => {
      loadCurrentConfiguration();
      ctx.emit('cancel');
    };

    return {
      panels,
      // config,
      project,
      filters,
      marketDimension,
      productDimension,
      // catalogs,
      prdDimCatalog,
      mktDimCatalog,
      isDirty,
      applyEnabled,
      applyingConfiguration,
      updateFiltersFromSelection,
      apply,
      cancel,
      //Data sources
      gbu,
      scope,
      dumpDate,
      gdpOutlook,
      newbornOutlook,
      fact,
      gbuList,
      scopeList,
      dumpDateList,
      gdpOutlookList,
      newbornOutlookList,
      factList,
      loadingDS,
      loadingCatalogsDS,
      isDataSourceSelected,
      gbuChanged,
      scopeChanged,
      dumpDateChanged,
      gdpOutlookUnified,
      newbornOutlookUnified,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.container {
  width: 100%;
}

.panels > .v-expansion-panel {
  border-bottom: 1px solid var(--v-primary-base);
}

.panels > .v-expansion-panel:first-child {
  border-top: 1px solid var(--v-primary-base);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.panels > .v-expansion-panel:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
