import { adGraphService } from '@/services';

const state = {
  loading: false,
  all: new Map(),
};

const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

const actions = {
  async get({ commit, state }, userIds) {
    const uniqueIds = [...new Set(userIds)];
    const missingUsers = uniqueIds.filter(id => !state.all.has(id));
    if (missingUsers.length > 0) {
      try {
        commit(GET_USERS_REQUEST);
        const retrievedUsers = await Promise.all(
          missingUsers.map(id => adGraphService.getUser(id))
        );
        commit(GET_USERS_SUCCESS, retrievedUsers);
      } catch {
        commit(GET_USERS_FAILURE);
      }
    }
  },
};

const mutations = {
  [GET_USERS_REQUEST](state) {
    state.loading = true;
  },
  [GET_USERS_SUCCESS](state, users) {
    for (const user of users) {
      if (user.objectId && user.displayName) {
        state.all.set(user.objectId, user.displayName);
      }
    }
  },
  [GET_USERS_FAILURE](state) {
    state.loading = false;
  },
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
};
