<template>
  <v-expansion-panels flat class="panels">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="4">Scenarios</v-col>
            <v-col cols="4" class="text--secondary">
              <v-fade-transition leave-absolute>
                <span v-if="!open" key="0">
                  Active: {{ scenariosActive }}
                </span>
              </v-fade-transition>
            </v-col>
            <v-col cols="4" class="text--secondary">
              <v-fade-transition leave-absolute>
                <span v-if="!open" key="0">
                  Inactive:
                  {{ scenariosInactive }}
                </span>
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="scenarios"
          item-key="id"
          :no-data-text="noScenariosText"
          @click:row="openEditDialog"
          hide-default-footer
          class="elevation-1 space mb-4"
          dense
        >
          <template v-slot:item.scenarioId="{ item }">
            <span :style="item.active ? `color:${scenarioColor(item)};` : ''">{{
              item.scenarioId
            }}</span>
          </template>
          <template v-slot:item.name="{ item }">
            <span :style="item.active ? `color:${scenarioColor(item)};` : ''">{{
              item.name
            }}</span>
          </template>
          <template v-slot:item.filters.powerBrand="{ item }">
            {{ item.filters.powerBrand | truncateArray }}
          </template>
          <template v-slot:item.filters.country="{ item }">
            {{ item.filters.country | truncateArray }}
          </template>
          <template v-slot:item.active="{ item }">
            <v-simple-checkbox
              class="mr-4"
              :value="item.active"
              :ripple="false"
              :disabled="!userOwnsProject || loadingUpdate"
              @input="setScenarioActive({ ...item, active: $event })"
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click.stop="openDeleteDialog(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete scenario</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-row v-if="userOwnsProject" justify="end">
          <v-btn
            color="primary"
            class="mr-3"
            :disabled="!applyEnabled"
            @click="emitApply"
            >Apply</v-btn
          >
          <v-btn
            color="primary"
            class="mr-3"
            outlined
            :disabled="!addScenarioEnabled"
            @click="openCreateDialog"
            ><v-icon left>add_circle_outline</v-icon>Add scenario</v-btn
          >
        </v-row>
        <scenario-dialog-form
          :show.sync="showScenarioDialog"
          :scenario="editingScenario"
          :filtersSuggestion="projectConfiguration"
          :readOnly="!userOwnsProject"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-dialog v-model="showDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Delete
            {{ deletingScenario ? deletingScenario.name : '' }} scenario</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                Do you want to <u>permanently delete</u> this scenario?
              </v-col>
              <v-col
                cols="12"
                class="subtitle-2"
                v-show="
                  deletingScenario && deletingScenario.filters.powerBrand.length
                "
                >Brands</v-col
              ><v-col cols="12">
                {{
                  deletingScenario
                    ? deletingScenario.filters.powerBrand
                    : [] | arrayToString
                }}</v-col
              >
              <v-col
                cols="12"
                class="subtitle-2"
                v-show="
                  deletingScenario && deletingScenario.filters.country.length
                "
                >Countries</v-col
              ><v-col cols="12">
                {{
                  deletingScenario
                    ? deletingScenario.filters.country
                    : [] | arrayToString
                }}</v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="confirmDelete(deletingScenario)"
            :loading="deleteLoading"
            >Delete</v-btn
          >
          <v-btn
            text
            @click="showDeleteDialog = false"
            :disabled="deleteLoading"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ScenarioDialogForm from './ScenarioDialogForm';
import { mutationTypes } from '@/store';
import { scenarioColorHash } from '@/global/scenarios';

export default {
  name: 'ScenarioList',
  components: {
    ScenarioDialogForm,
  },
  props: ['projectId', 'userOwnsProject'],
  data() {
    return {
      scenariosDirty: false,
      showScenarioDialog: false,
      editingScenario: null,
      noScenariosText: 'No scenarios created',
      showDeleteDialog: false,
      deletingScenario: null,
      deleteLoading: false,
    };
  },
  computed: {
    headers() {
      // Constant headers for data table
      return [
        { text: 'Id', value: 'scenarioId' },
        { text: 'Name', value: 'name' },
        { text: 'Brands', value: 'filters.powerBrand' },
        { text: 'Countries', value: 'filters.country' },
        { text: 'Onset', value: 'filters.onsetDate' },
        { text: 'Active', value: 'active', align: 'center' },
        {
          text: 'Delete',
          value: 'delete',
          sortable: false,
          align: 'center',
        },
      ];
    },
    scenariosActive() {
      return this.scenarios.filter(f => f.active).length;
    },
    scenariosInactive() {
      return this.scenarios.filter(f => !f.active).length;
    },
    ...mapState('scenario', {
      loading: state => state.loadingScenarios,
      scenarios: state => state.all,
      loadingUpdate: state => state.loadingUpdate,
    }),
    ...mapState('project', {
      projectConfiguration: state => state.project.config.preferences,
    }),
    applyEnabled() {
      return !!this.scenariosDirty;
    },
    addScenarioEnabled() {
      // Only add scenarios if project has been saved (has projectId)
      return this.projectId && !isNaN(this.projectId);
    },
  },
  watch: {
    projectId(newId, oldId) {
      if (newId != oldId) {
        this.loadScenarios().then(() => (this.scenariosDirty = false));
      }
    },
    scenarios() {
      // If scenarios were cleaned for a new project, it's not dirty.
      this.scenariosDirty = !(
        this.projectId === null && this.scenarios.length === 0
      );
    },
  },
  created() {
    this.loadScenarios();
  },
  methods: {
    ...mapActions('scenario', ['getCurrentProjectScenarios', 'delete']),
    ...mapMutations('scenario', {
      setScenarioActive: mutationTypes.scenario.SET_SCENARIO_ACTIVE,
    }),
    loadScenarios() {
      return this.getCurrentProjectScenarios().catch(() => null);
    },
    emitApply() {
      this.scenariosDirty = false;
      this.$emit('apply');
    },
    openCreateDialog() {
      this.editingScenario = null;
      this.showScenarioDialog = true;
    },
    openEditDialog(scenario) {
      this.editingScenario = scenario;
      this.showScenarioDialog = true;
    },
    openDeleteDialog(scenario) {
      this.deletingScenario = scenario;
      this.deleteLoading = false;
      this.showDeleteDialog = true;
    },
    confirmDelete(scenario) {
      this.deleteLoading = true;
      this.delete(scenario.scenarioId)
        .then(() => (this.deleteLoading = this.showDeleteDialog = false))
        .catch(() => (this.deleteLoading = this.showDeleteDialog = false));
    },
    scenarioColor(scenario) {
      return scenarioColorHash({
        id: scenario.scenarioId,
        name: scenario.name,
      });
    },
  },
  filters: {
    arrayToString(array) {
      return array.join(', ');
    },
    truncateArray(array, limit = 5) {
      const ellipsis = array.length > limit ? '...' : '';
      return array.slice(0, limit).join(', ') + ellipsis;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.container {
  width: 100%;
}

.panels > .v-expansion-panel {
  border-bottom: 1px solid var(--v-primary-base);
}

.panels > .v-expansion-panel:first-child {
  border-top: 1px solid var(--v-primary-base);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.panels > .v-expansion-panel:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.button {
  width: 125px;
}
</style>
