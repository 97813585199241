import { calculateCagr } from '@/global/groundForecast';
import {
  PILLAR,
  CATEGORY,
  SEGMENT,
  SUB_SEGMENT,
  AREA,
  REGION,
  COUNTRY,
} from '@/global/filters';

const blue = { r: 25, g: 118, b: 210 };
const white = { r: 255, g: 255, b: 255 };
const yellow = { r: 253, g: 216, b: 53 };
const red = { r: 255, g: 0, b: 0 };

export const GROUND_FORECAST = 'groundForecast';
export const FORECAST = 'forecast';

const productDimension = [
  {
    dimension: PILLAR,
    label: 'Pillar',
    property: 'pillar',
  },
  {
    dimension: CATEGORY,
    label: 'Category',
    property: 'category',
  },
  {
    dimension: SEGMENT,
    label: 'Segment',
    property: 'segment',
  },
  {
    dimension: SUB_SEGMENT,
    label: 'Subsegment',
    property: 'subsegment',
  },
];

const marketDimension = [
  {
    dimension: AREA,
    label: 'Area',
    property: 'area',
  },
  {
    dimension: REGION,
    label: 'Region',
    property: 'region',
  },
  {
    dimension: COUNTRY,
    label: 'Country',
    property: 'country',
  },
];

// linear interpolation between two values a and b
// u controls amount of a/b and is in range [0.0,1.0]
function lerp(a, b, u) {
  return (1 - u) * a + u * b;
}

function normalize(val, max, min) {
  return (val - min) / (max - min);
}

export function percentageOfDeviation(currYear, lastYear) {
  if (lastYear == 0) return 0;

  return Math.abs((currYear - lastYear) / lastYear);
}

export function calculateHeatMapColor(percentageValue) {
  let k = 0.06;

  let value = 1 / (1 + Math.exp(-k * percentageValue * 100));

  let startColor = red;
  let endColor = white;

  if (value > 0.5) {
    startColor = white;
    endColor = blue;
    value = normalize(value, 1, 0.5);
  } else if (value > 0.25) {
    startColor = yellow;
    endColor = white;
    value = normalize(value, 0.5, 0.25);
  } else {
    value = normalize(value, 0.5, 0);
  }

  // value = normalize(value, value > 0.5 ? 1 : 0.5, value > 0.5 ? 0.5 : 0);

  var r = parseInt(lerp(startColor.r, endColor.r, value));
  var g = parseInt(lerp(startColor.g, endColor.g, value));
  var b = parseInt(lerp(startColor.b, endColor.b, value));

  return 'rgb(' + r + ',' + g + ',' + b + ')';
}

export const calculateCagrs = (lastNotForecast, yearsValues) => {
  //calculate cagrs
  const cagrPeriod = 2;

  return {
    histCagr: calculateCagr(
      yearsValues[`i${lastNotForecast - cagrPeriod}`],
      yearsValues[`i${lastNotForecast}`],
      2
    ),

    t3Cagr: calculateCagr(
      yearsValues[`i${lastNotForecast}`],
      yearsValues[`i${lastNotForecast + 3}`],
      3
    ),
    t5Cagr: calculateCagr(
      yearsValues[`i${lastNotForecast}`],
      yearsValues[`i${lastNotForecast + 5}`],
      5
    ),
  };
};

export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function getGroupsBy(xs, key) {
  return xs.reduce(function(rv, x) {
    if (!rv.includes(x[key])) rv.push(x[key]);
    return rv;
  }, []);
}

function getCatalogProperty(value, catalog) {
  const result = catalog.find(c => {
    return c.dimension === value;
  });

  return result ? result.property : null;
}

export function getProductDimProperty(value) {
  return getCatalogProperty(value, productDimension);
}

export function getMarketDimProperty(value) {
  return getCatalogProperty(value, marketDimension);
}

function drillCatalog(value, catalog) {
  const index = catalog.findIndex(c => {
    return c.dimension === value;
  });

  return {
    previous: catalog[index - 1] || null,
    next: catalog[index + 1] || null,
  };
}

export function drillProductDimension(prodDim) {
  return drillCatalog(prodDim, productDimension);
}

export function drillMarketDimension(marketDim) {
  return drillCatalog(marketDim, marketDimension);
}
