export const MARKET_BASE = 'MARKET_BASE';
export const MARKET_INNOVATION = 'MARKET_INNOVATION';
export const MARKET_OVERALL = 'TOTAL_MARKET';
export const RB_BASE = 'RB_BASE';
export const RB_INNOVATION = 'RB_INNOVATION';
export const RB_OVERALL = 'RB_OVERALL';

export const GLOBAL = 'GLOBAL';
export const AREA = 'AREA';
export const REGION = 'REGION';
export const COUNTRY = 'COUNTRY';

export const DOSES = 'DOSES';
export const UNITS = 'UNITS';
export const VALUE = 'VALUE';
export const VOLUME = 'VOLUME';

export const PILLAR = 'PILLAR';
export const CATEGORY = 'CATEGORY';
export const SEGMENT = 'SEGMENT';
export const SUB_SEGMENT = 'SUB_SEGMENT';

export const staticCatalogs = {
  scope: [
    { key: MARKET_BASE, value: 'Category Base' },
    { key: MARKET_INNOVATION, value: 'Category Innovation' },
    { key: MARKET_OVERALL, value: 'Total Category' },
    { key: RB_BASE, value: 'RB Base' },
    { key: RB_INNOVATION, value: 'RB Innovation' },
    { key: RB_OVERALL, value: 'Total RB' },
  ],
  productDimension: [
    {
      key: PILLAR,
      value: 'Pillar',
    },
    {
      key: CATEGORY,
      value: 'Category',
    },
    {
      key: SEGMENT,
      value: 'Segment',
    },
    {
      key: SUB_SEGMENT,
      value: 'Subsegment',
    },
  ],
  marketDimension: [
    {
      key: AREA,
      value: 'Area',
    },
    {
      key: REGION,
      value: 'Region',
    },
    {
      key: COUNTRY,
      value: 'Country',
    },
  ],
  fact: [
    {
      key: DOSES,
      value: 'Doses',
    },
    {
      key: UNITS,
      value: 'Units',
    },
    {
      key: VOLUME,
      value: 'Volume',
    },
    {
      key: VALUE,
      value: 'Value',
    },
  ],
};
