import { paginateQueryParams } from './utils';
import API from './API.service';

export const projectListService = {
  getPaginated,
  copyProject,
};

function getPaginated(paginationOptions) {
  const paginationParams = paginateQueryParams(paginationOptions);
  return API.get('/project/list', paginationParams);
}

function copyProject(projectId) {
  return API.post(`/project/${projectId}/copy`);
}
