import _ from 'lodash';

export const sortByName = (a, b) => {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const name2CamelCase = i => {
  i.name = _.startCase(i.name);
  return i;
};

export const idFilter = target => i => i.id === target;

export const normalize = list => list.map(name2CamelCase).sort(sortByName);
