<template>
  <main-layout>
    <v-stepper v-model="step" vertical>
      <h2 class="font-weight-regular ml-5 mt-5">Guided Analysis</h2>
      <v-stepper-step :complete="step > 1" step="1" editable>
        Start a new project
      </v-stepper-step>

      <v-stepper-content step="1">
        <div class="mt-3 mb-3 project-name">
          <v-text-field
            v-model="projectName"
            label="Project Name"
            :rules="projectNameRules"
            outlined
            dense
          ></v-text-field>
        </div>
        <v-btn
          color="primary"
          @click="step = 2"
          :disabled="!allowToContinue(2)"
        >
          Continue
        </v-btn>
        <v-btn text @click="cancel">Cancel</v-btn>
      </v-stepper-content>
      <v-stepper-step
        :complete="step > 2"
        step="2"
        :editable="allowToContinue(2)"
        >Configure the data source</v-stepper-step
      >
      <v-stepper-content step="2">
        <div class="data-source mb-5">
          <v-container fluid class="py-0 px-0">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="gbu"
                  :items="gbuList"
                  item-value="id"
                  item-text="name"
                  :loading="loadingDS"
                  @change="gbuChanged"
                  label="Global BU"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="scope"
                  :items="scopeList"
                  item-value="id"
                  item-text="name"
                  :disabled="!gbu"
                  :loading="loadingDS"
                  @change="scopeChanged"
                  label="Scope"
                  no-data-text="Please select a GBU"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="dumpDate"
                  :items="dumpDateList"
                  :disabled="!scope"
                  :loading="loadingDS"
                  @change="dumpDateChanged"
                  label="Nielsen GTC Date"
                  no-data-text="Please select a Scope"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-btn color="primary" @click="step = 3" :disabled="!allowToContinue(3)"
          >Continue</v-btn
        >
        <v-btn text @click="step = 1">Cancel</v-btn>
      </v-stepper-content>

      <!-- <v-stepper-step
        :complete="step > 3"
        step="3"
        :editable="allowToContinue(3)"
        >Select external variables outlook</v-stepper-step
      > -->
      <!-- <v-stepper-content step="3">
        <div class="outlook mb-5">
          <v-container fluid class="py-0 px-0">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="gdpOutlook"
                  :items="gdpOutlookList"
                  :loading="loadingCatalogs"
                  :disabled="!isDataSourceSelected || loadingCatalogs"
                  item-value="id"
                  item-text="name"
                  label="GDP Outlook"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="newbornOutlook"
                  :items="newbornOutlookList"
                  :loading="loadingCatalogs"
                  :disabled="!isDataSourceSelected || loadingCatalogs"
                  item-value="id"
                  item-text="name"
                  label="Newborn Outlook"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-btn
            color="primary"
            @click="step = 4"
            :disabled="!allowToContinue(4)"
            >Continue</v-btn
          >
          <v-btn text @click="step = 2">Cancel</v-btn>
        </div>
      </v-stepper-content> -->

      <v-stepper-step
        :complete="step > 3"
        step="3"
        :editable="allowToContinue(3)"
        >Select a FACT</v-stepper-step
      >
      <v-stepper-content step="3">
        <div class="fact mt-3 mb-5">
          <v-select
            v-model="fact"
            :items="factList"
            item-value="id"
            item-text="name"
            label="Fact"
            :loading="loadingCatalogs"
            :disabled="!isDataSourceSelected || loadingCatalogs"
            hide-details
            outlined
            dense
          ></v-select>
        </div>
        <v-btn
          color="primary"
          @click="createProject"
          :disabled="!allowToContinue(4) && creatingProject"
          :loading="creatingProject"
          >Create Project</v-btn
        >
        <v-btn text @click="step = 2">Cancel</v-btn>
      </v-stepper-content>
    </v-stepper>
  </main-layout>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import useDataSource from '@/composables/data-sources';
import { AREA, PILLAR } from '@/global/filters';
import store, { mutationTypes } from '@/store';
import _ from 'lodash';

export default {
  // eslint-disable-next-line no-unused-vars
  setup(props, ctx) {
    const {
      gbu,
      scope,
      dumpDate,
      gdpOutlook,
      newbornOutlook,
      fact,
      gbuList,
      scopeList,
      dumpDateList,
      gdpOutlookList,
      newbornOutlookList,
      factList,
      loading: loadingDS,
      loadingCatalogs,
      isDataSourceSelected,
      gbuChanged,
      scopeChanged,
      dumpDateChanged,
    } = useDataSource();

    const step = ref(1);
    const projectName = ref('');
    const creatingProject = ref(false);

    const allowToContinue = nextStep => {
      switch (nextStep) {
        case 2: {
          return !_.isEmpty(projectName.value);
        }
        case 3:
          return isDataSourceSelected.value;
        // case 4: {
        //   return (
        //     gdpOutlook.value &&
        //     gdpOutlook.value > 0 &&
        //     newbornOutlook.value &&
        //     newbornOutlook.value > 0
        //   );
        // }
        case 4: {
          return !_.isEmpty(fact.value);
        }
      }
    };

    const setProject = payload =>
      store.commit(`project/${mutationTypes.project.SET_PROJECT}`, payload);

    const storeProject = () => store.dispatch('project/storeProject');
    const createNewProject = () => store.dispatch('project/createNewProject');

    const gdpOutlookUnified = computed(() => {
      return gdpOutlookList.value.filter(
        o => o.name.toLowerCase() === 'unified'
      )[0].id;
    });

    const newbornOutlookUnified = computed(() => {
      return newbornOutlookList.value.filter(
        o => o.name.toLowerCase() === 'unified'
      )[0].id;
    });

    const createProject = async () => {
      creatingProject.value = true;

      await createNewProject();

      setProject({
        name: projectName.value,
        config: {
          preferences: {
            gbuId: gbu.value,
            scopeId: scope.value,
            dumpDate: dumpDate.value,
            gdpOutlookId: gdpOutlookUnified.value, //gdpOutlook.value,
            newbornOutlookId: newbornOutlookUnified.value, //newbornOutlook.value,
            factId: fact.value,
            //default values that we don't ask for it
            productDimension: PILLAR,
            marketDimension: AREA,
            area: [],
            powerBrand: [],
            region: [],
            country: [],
            pillar: [],
            category: [],
            segment: [],
            subsegment: [],
          },
        },
      });

      storeProject().then(data => {
        ctx.root.$router.push(
          `/guidedanalysis/${data.projectId}`,
          null,
          () => (creatingProject.value = false)
        );
      });
    };

    const cancel = () => {
      ctx.root.$router.push('/guidedanalysis/list');
    };

    return {
      step,
      projectName,
      creatingProject,
      allowToContinue,
      createProject,
      cancel,
      //data sources
      gbu,
      scope,
      dumpDate,
      gdpOutlook,
      newbornOutlook,
      fact,
      gbuList,
      scopeList,
      dumpDateList,
      gdpOutlookList,
      newbornOutlookList,
      factList,
      isDataSourceSelected,
      loadingDS,
      loadingCatalogs,
      gbuChanged,
      scopeChanged,
      dumpDateChanged,
      gdpOutlookUnified,
      newbornOutlookUnified,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-name {
  width: 400px;
}
.data-source {
  width: 500px;
}
.fact {
  width: 300px;
}
.outlook {
  width: 500px;
}
</style>
