<template>
  <v-main fluid class="pa-0">
    <v-row justify="end">
      <div class="mt-1 viewType mr-7 mb-5">
        <v-select
          v-model="viewType"
          :items="viewTypeList"
          item-value="key"
          item-text="value"
          label="View type"
          :disabled="!viewTypeEnabled"
          hide-details
          style="z-index:12"
          outlined
          dense
        ></v-select>
      </div>
    </v-row>
    <div v-if="!pivots.length">
      <apexchart
        height="350"
        :options="getChartOptions(buildTitle(filters))"
        :series="getSeries(filters)"
      ></apexchart>
    </div>
    <div v-if="pivots.length">
      <apexchart
        class="mt-5"
        v-for="(p, index) in pivots"
        height="350"
        :options="getChartOptions(buildTitle(filters.concat([p])))"
        :series="getSeries(filters.concat([p]))"
        :key="index"
      ></apexchart>
    </div>
    <v-overlay :value="overlayer" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import { reactive, toRefs, computed } from '@vue/composition-api';
import { dataItemFilter, valueLookup } from '@/global/common';
import VueApexCharts from 'vue-apexcharts';
import store from '@/store';
import { numberFormat } from '@/global/common';
import { scenarioColorHash } from '@/global/scenarios';
import {
  getProductDimProperty,
  getMarketDimProperty,
} from '@/global/guideAnalysis';
import {
  MAT,
  HALF_MAT,
  YTD,
  HALF_YTD,
  MONTHLY,
  QUARTERLY,
  viewTypeList,
  pointToDateTime,
} from '@/global/groundForecast';
import _ from 'lodash';

export default {
  name: 'LineChart',
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const {
      state: { project: sto },
    } = store;

    const state = reactive({
      rendering: false,
      overlayer: computed(() => sto.loadingLineChart),
      data: computed(() => sto.lineChartData),
      viewType: computed({
        get() {
          return sto.project.config.lineChartView;
        },
        set(value) {
          store.dispatch(`project/setLineChartViewType`, value);
        },
      }),
      viewTypeEnabled: computed(() => !sto.loadingLineChart),
      preferences: computed(() => sto.project.config.preferences),
      productDimension: computed(() => state.preferences.productDimension),
      marketDimension: computed(() => state.preferences.marketDimension),
      xAxesCategories: computed(() => {
        if (sto.lineChartData && sto.lineChartData.timeSeries)
          return sto.lineChartData.timeSeries.flatMap(i => i.points.join('/'));
        return [];
      }),
      timePoints: computed(() => {
        if (sto.lineChartData && sto.lineChartData.timeSeries)
          return sto.lineChartData.timeSeries.map(i => i.points);
        return [];
      }),
      lineChartCols: computed(() => sto.project.config.lineChartCols),
      lineChartRows: computed(() => sto.project.config.lineChartRows),
      lcColumns: computed(() =>
        state.lineChartCols.map(c => ({
          propName: getMarketDimProperty(state.marketDimension),
          value: c,
        }))
      ),
      lcRows: computed(() =>
        state.lineChartRows.map(c => ({
          propName: getProductDimProperty(state.productDimension),
          value: c,
        }))
      ),
      filters: computed(() => {
        const filter = [];
        if (state.lcColumns.length == 1) filter.push(...state.lcColumns);
        if (state.lcRows.length == 1) filter.push(...state.lcRows);
        return filter;
      }),
      pivots: computed(() => {
        if (state.lcRows.length > 1) return state.lcRows;
        else if (state.lcColumns.length > 1) return state.lcColumns;
        return [];
      }),
    });

    const getScenarios = () =>
      !_.isEmpty(sto.lineChartData) && !_.isEmpty(sto.lineChartData.data)
        ? sto.lineChartData.data.scenarios
        : {};

    const getEndHistoricalIndex = () => {
      switch (state.viewType) {
        case MAT:
        case YTD:
          return 2;
        case HALF_MAT:
        case HALF_YTD:
          return 5;
        case MONTHLY:
          return 35;
        case QUARTERLY:
          return 11;
      }
    };

    const getSeries = filters => {
      const histData = [];
      const fcstData = [];
      const scenariosData = {};
      const allScenariosData = {};

      const data =
        sto.lineChartData && sto.lineChartData.data
          ? sto.lineChartData.data
          : null;

      if (!data) return [];

      const groundForecast = data.groundForecast.filter(gf => {
        for (let i in filters) {
          if (gf[filters[i].propName] !== filters[i].value) return false;
        }
        return true;
      });

      const timeSeries = sto.lineChartData.timeSeries;
      const scenarios = getScenarios();
      const scenariosIDs = Object.keys(scenarios);

      if (!timeSeries) return [];

      // initialize the variables with null.
      // If the value doesn't exist it wont be presented on the chart
      timeSeries
        .flatMap(i => 'p'.concat(i.points.join('-')))
        .forEach((t, index) => {
          histData[index] = null;
          fcstData[index] = null;
          allScenariosData[index] = null;
          scenariosIDs.forEach(id => {
            if (!scenariosData[id]) scenariosData[id] = [];
            scenariosData[id][index] = null;
          });
        });

      const endHistorical = getEndHistoricalIndex();

      //add values
      groundForecast.forEach(g => {
        const s4g = {};
        scenariosIDs.forEach(id => {
          s4g[id] = scenarios[id].filter(dataItemFilter(g))[0] || null;
        });

        const combinedScenario =
          data.forecast.filter(dataItemFilter(g))[0] || null;

        g.values.forEach((v, index) => {
          if (endHistorical >= index) {
            if (!histData[index]) histData[index] = 0;
            histData[index] += v.value;
          } else {
            if (!fcstData[index]) fcstData[index] = 0;
            fcstData[index] += v.value;
          }

          Object.keys(s4g).forEach(id => {
            const sv = valueLookup(v, s4g[id]);

            if (!scenariosData[id]) scenariosData[id] = [];
            if (!scenariosData[id][index]) scenariosData[id][index] = 0;

            if (sv) {
              scenariosData[id][index] += sv;
            } else {
              scenariosData[id][index] += v.value;
            }
          });

          if (combinedScenario) {
            const csv = valueLookup(v, combinedScenario);
            if (!allScenariosData[index]) allScenariosData[index] = 0;
            if (csv) {
              allScenariosData[index] += csv;
            } else {
              allScenariosData[index] += v.value;
            }
          } else {
            allScenariosData[index] += v.value;
          }
        });
      });

      return createPlotData(
        histData,
        fcstData,
        scenariosData,
        allScenariosData
      );
    };

    const createPlotData = (
      histData,
      fcstData,
      scenariosData,
      allScenariosData
    ) => {
      const histXY = [];
      const fcstXY = [];
      const scenariosXY = {};
      const allScenariosXY = [];

      state.timePoints.forEach((p, i) => {
        //we add the day for some browsers
        const dateTime = pointToDateTime(p, state.viewType);

        histXY.push({
          y: histData[i],
          x: dateTime,
        });
        fcstXY.push({
          y: fcstData[i],
          x: dateTime,
        });

        Object.keys(scenariosData).forEach(id => {
          if (!scenariosXY[id]) scenariosXY[id] = scenariosXY[id] = [];
          scenariosXY[id].push({
            y: scenariosData[id][i],
            x: dateTime,
          });
        });

        allScenariosXY.push({
          y: allScenariosData[i],
          x: dateTime,
        });
      });

      const result = [
        { name: 'Historical', data: histXY },
        { name: 'Forecasted', data: fcstXY },
      ];

      Object.keys(scenariosXY).map(id =>
        result.push({
          name: store.getters['scenario/getScenarioName'](id),
          data: scenariosXY[id],
        })
      );
      if (!_.isEmpty(scenariosXY))
        result.push({ name: 'All Combined', data: allScenariosXY });

      return result;
    };

    const buildTitle = filters => {
      let title = filters
        .reverse()
        .map(f => f.value)
        .join(' - ');
      if (_.isEmpty(title)) title = 'ALL';
      return title;
    };

    const getColors = () => {
      const colors = ['#FEB019', '#2E93fA'];
      Object.keys(getScenarios()).forEach(id => {
        colors.push(scenarioColorHash({ id }));
      });
      colors.push('#607d8b');

      return colors;
    };

    const getDashArray = () => {
      if ([MAT, YTD].includes(state.viewType)) return [];
      const colors = [0, 6];
      Object.keys(getScenarios()).forEach(() => colors.push(6));
      colors.push(6);

      return colors;
    };

    const getChartOptions = title => {
      return {
        theme: {
          palette: 'palette2',
        },
        colors: getColors(),
        chart: {
          id: 'chart2',
          type: [MAT, YTD].includes(state.viewType) ? 'bar' : 'line',
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: false,
          },
          toolbar: {
            autoSelected: 'zoom',
          },
        },
        title: {
          text: title,
          align: 'left',
        },
        noData: {
          text: 'No data available',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 3,
          dashArray: getDashArray(),
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.25,
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return numberFormat(value, 0);
            },
          },
        },
        tooltip: {
          x: {
            show: false,
            format: [MAT, YTD].includes(state.viewType) ? 'yyyy' : 'MM yyyy',
          },
        },
      };
    };

    return {
      ...toRefs(state),
      getSeries,
      getChartOptions,
      buildTitle,
      viewTypeList,
    };
  },
};
</script>

<style scoped>
.viewType {
  width: 148px;
}
</style>
