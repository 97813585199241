import Vue from 'vue';
import Vuex from 'vuex';

import { app } from './app.module';
import { users } from './users.module';
import { projectList } from './projectList.module';
import { filterCatalog } from './filterCatalog.module';
import { project, mutationTypes as projectMT } from './guideAnalysis.module';
import { scenario, publicMutations as scenarioMT } from './scenario.module';

Vue.use(Vuex);

export const mutationTypes = {
  project: projectMT,
  scenario: scenarioMT,
};

export default new Vuex.Store({
  modules: {
    app,
    users,
    projectList,
    project,
    filterCatalog,
    scenario,
  },
});
