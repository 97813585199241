export const dataItemFilter = item => f => {
  return (
    item.area === f.area &&
    item.region === f.region &&
    item.country === f.country &&
    item.powerbrand === f.powerbrand &&
    item.gbu === f.gbu &&
    item.pillar === f.pillar &&
    item.category === f.category &&
    item.segment === f.segment &&
    item.subsegment === f.subsegment
  );
};

export const dataTimePointFilter = v => f => {
  return (
    v.time.points[0] == f.time.points[0] &&
    (v.time.points.length > 1 ? v.time.points[1] == f.time.points[1] : true)
  );
};

export const filterDataItem = (source, lookupValue, defaultValue = null) => {
  if (!source || !lookupValue) return null;
  return source.filter(dataItemFilter(lookupValue))[0] ?? defaultValue;
};

export const filterDataPoint = (source, lookupValue, defaultValue = null) => {
  if (!source || !lookupValue) return null;
  return source.filter(dataTimePointFilter(lookupValue))[0] ?? defaultValue;
};

export const numberFormat = (value, fractionDigits = 2) => {
  if (value == null || value === '') return '-';
  else if (value && !isNaN(value)) {
    return new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value);
  } else return value;
};

export function valueLookup(value, forecast) {
  if (forecast == null) return null;
  const r = filterDataPoint(forecast.values, value);
  return r != null ? r.value : null;
}
