import ColorHash from 'color-hash';

export function scenarioColorHash(scenario) {
  if (!scenario) return '';
  return colorHash(scenario.id);
}

export function colorHash(value) {
  return new ColorHash().hex(value);
}
