<template>
  <v-app id="inspire"></v-app>
</template>

<script>
import { authService } from '@/services';

export default {
  name: 'LoginForm',
  props: { logout: { type: Boolean, default: false } },
  data: () => ({}),
  created: function() {
    if (this.logout) {
      authService.logout();
    } else if (authService.checkAuthenticated()) {
      // Authentication success, go home.
      this.$router.push('/');
    } else {
      // Not authenticated, do it.
      authService.login();
    }
  },
};
</script>
