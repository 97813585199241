<template>
  <v-menu
    v-model="datePickerOpen"
    :close-on-content-click="false"
    :disabled="disabled || readonly"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="value"
        @input="updateDate"
        :label="label"
        :hint="hint"
        :disabled="disabled"
        :readonly="readonly"
        :rules="[requireValidDateRule]"
        prepend-icon="event"
        hide-details="auto"
        outlined
        dense
        v-on="on"
        @keydown.tab="datePickerOpen = false"
        @keydown.enter.prevent="datePickerOpen = true"
        @click:prepend="datePickerOpen = true"
        v-bubble.focus.blur
      ></v-text-field>
    </template>
    <v-date-picker
      :value="datePickerValue"
      @input="updateDate"
      :show-current="false"
      :min="min"
      :type="format"
      show-week
      no-title
      scrollable
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: [String],
    format: {
      type: String,
      default: 'date',
      validator: v => ['date', 'month'].includes(v),
    },
    label: String,
    hint: String,
    min: String,
    disabled: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      datePickerOpen: false,
      datePickerValue: this.value,
    };
  },
  created() {
    if (this.requireValidDateRule(this.value, 'YYYY-MM-DD') !== true) {
      this.datePickerValue = null;
    } else {
      this.updateDate(this.getValueOrMin(this.value));
    }
  },
  watch: {
    min() {
      this.updateDate(this.getValueOrMin(this.value));
    },
  },
  methods: {
    getValueOrMin(value) {
      if (this.min != null && (value == null || value < this.min)) {
        value = this.min;
      }
      if (this.format == 'month' && value.length > 7) {
        value = value.substr(0, 7);
      }
      return value;
    },
    updateDate(value) {
      if (this.requireValidDateRule(value) === true) {
        value = this.getValueOrMin(value);
        this.datePickerValue = value;
      }
      this.datePickerOpen = false;
      // Emit default event for enabling v-model in this component
      this.$emit('input', value);
    },
    requireValidDateRule(value, forceFormat) {
      if (!value) return 'Date is required';

      const fmt = forceFormat
        ? forceFormat
        : this.format === 'month'
        ? 'YYYY-MM'
        : 'YYYY-MM-DD';

      if (!moment(value, fmt, true).isValid()) {
        return `Must be a valid date in the format ${fmt}`;
      }
      return true;
    },
  },
};
</script>
