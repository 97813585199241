<template>
  <main-layout withRightMenu>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card min-height="82vh">
        <v-container fluid>
          <v-row justify="space-between">
            <v-col cols="4" class="ml-5">
              <v-text-field
                v-model="projectForm.name"
                label="Project Name"
                :rules="projectNameRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="pink"
                @click.stop="showRightMenu(true)"
                class="mr-3"
                :disabled="rightMenu.show"
                text
              >
                <v-icon left>edit</v-icon>
                Edit configuration
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mx-5 my-0">
            <configuration-summary title="Analysis" />
          </v-row>
          <v-row justify="center" class="ma-5">
            <scenario-list
              :projectId="project ? project.projectId : null"
              :userOwnsProject="true"
              @apply="applyScenario"
            />
          </v-row>
          <v-row justify="center" class="ma-5">
            <heat-map />
          </v-row>
          <v-row justify="center" class="ma-5 mt-10">
            <v-card width="100%">
              <v-tabs v-model="tab" @change="tabChanged">
                <v-tab key="lineChart">
                  <span v-if="!loadingLineChart">Chart</span>
                  <v-progress-circular
                    v-if="loadingLineChart"
                    indeterminate
                    size="24"
                    width="2"
                    >Chart</v-progress-circular
                  >
                </v-tab>
                <v-tab key="table">
                  <span v-if="!loadingTable">Table</span>
                  <v-progress-circular
                    v-if="loadingTable"
                    indeterminate
                    size="24"
                    width="2"
                    >Table</v-progress-circular
                  >
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item key="lineChart">
                  <line-chart v-if="rerenderLinechart" />
                </v-tab-item>
                <v-tab-item key="table">
                  <project-table />
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-row>
          <v-row justify="space-between" class="ml-3">
            <v-col>
              <v-checkbox
                v-model="projectForm.published"
                label="Share project"
                class="mt-1 ml-5"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-row justify="end" class="mr-4">
                <v-btn
                  color="red darken-3"
                  class="mr-3  button"
                  outlined
                  hidden
                  :disabled="!allowToDelete"
                  @click="confirmDelete = true"
                  ><v-icon left>mdi-trash-can-outline</v-icon>Delete</v-btn
                >
                <v-btn
                  class="mr-3"
                  color="blue darken-3 button"
                  outlined
                  :disabled="!allowToNew"
                  @click="createNew"
                  ><v-icon left>mdi-shape-rectangle-plus</v-icon> New</v-btn
                >
                <v-btn
                  color="success button"
                  :loading="storing"
                  :disabled="!allowToSave"
                  @click="store"
                  ><v-icon left>mdi-content-save-outline</v-icon>Save</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
          <!-- To debug... -->
          <!-- <v-row justify="center">
            <div style="width:500px;overflow-wrap:break-word;">
              {{ dirty /*JSON.stringify(configuration)*/ }}
            </div>
          </v-row> -->
        </v-container>
      </v-card>
    </v-form>
    <template v-slot:rightmenu>
      <project-configuration
        v-if="rightMenu.show"
        @apply="applySettings"
        @cancel="showRightMenu(false)"
      />
    </template>

    <confirmation-message
      key="pendingChanges"
      :show="pendingChangesDialog"
      icon="warning"
      title="Pending changes!"
      :message="
        `Are you sure you want to discard all pending changes for project '${this.project.name}'?`
      "
      button1-text="Discard"
      @button1="dialogContinue"
      button2-text="Cancel"
      @button2="pendingChangesDialog = false"
    />

    <confirmation-message
      key="alertForcastChanged"
      :show="forecastChanged"
      icon="warning"
      title="Forecast has changed!"
      message="Some of the baseline forecasts have been updated by the Data &
          Analytics team since you last saved the project. Your scenarios are
          unaffected."
      button1-text="Close"
      @button1="() => setForecastChanged(false)"
    />

    <confirmation-message
      key="confirmationDelete"
      :show="confirmDelete"
      icon="warning"
      title="Delete permanently"
      :message="
        `Are you sure you want to delete the project '${projectForm.name}'?.\n This action cannot not be undone.`
      "
      button1-text="Cancel"
      @button1="confirmDelete = false"
      button2-text="Delete"
      button2-color="error darken-1"
      button2-type="solid"
      @button2="deleteCurrentProject"
    />
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </main-layout>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ProjectConfiguration from '@/components/forecast/partials/ProjectConfiguration';
import ConfigurationSummary from '@/components/common/ConfigurationSummary';
import ProjectTable from '@/components/forecast/partials/ProjectTable';
import LineChart from './partials/LineChart';
import ConfirmationMessage from '@/components/common/ConfirmationMessage';
import HeatMap from './partials/HeatMap';
import ScenarioList from '@/components/scenario/ScenarioList';
import { viewTypeList } from '@/global/groundForecast';
import { mutationTypes } from '@/store';
import _ from 'lodash';

export default {
  name: 'GuideAnalysis',
  components: {
    ProjectConfiguration,
    ConfigurationSummary,
    ConfirmationMessage,
    ProjectTable,
    HeatMap,
    LineChart,
    ScenarioList,
  },
  data: function() {
    return {
      title: 'Guide Analysis',
      pendingChangesDialog: false,
      valid: true,
      unwatchRef: null,
      projectForm: null,
      tab: 'table',
      dialogContinue: () => {},
      confirmDelete: false,
      rerenderLinechart: true,
    };
  },
  created: function() {
    this.setRightMenuSettings({
      title: 'Configuration',
      width: 350,
    });

    this.loadCurrentProject(false);

    if (!_.isEmpty(this.paramProjectId)) {
      if (this.dirty) {
        this.showPendingChangesDialog(() => {
          this.loadOrCreateProject();
        });
      } else {
        this.loadOrCreateProject();
      }
    }
  },
  computed: {
    paramProjectId() {
      if (this.$route.params && this.$route.params.projectId)
        return this.$route.params.projectId;
      return null;
    },
    allowToSave() {
      return (
        !_.isEmpty(this.configuration) &&
        this.valid &&
        this.dirty &&
        !this.loadingForecast
      );
    },
    allowToDelete() {
      return this.project && this.project.projectId && !this.loadingForecast;
    },
    allowToNew() {
      return !this.loadingForecast;
    },
    getViewTypeList() {
      return viewTypeList;
    },
    ...mapState({
      rightMenu: state => state.app.rightMenu,
    }),
    ...mapState('project', {
      project: state => state.project,
      configuration: state => state.project.config.preferences,
      forecast: state => state.forecast,
      loading: state => state.loading,
      loadingForecast: state => state.loadingForecast,
      loadingTable: state => state.loadingTable,
      loadingLineChart: state => state.loadingLineChart,
      storing: state => state.storing,
      dirty: state => state.dirty,
      forecastChanged: state => state.forecastChanged,
    }),
  },
  mounted() {
    this.pendingChangesDialog = false;
  },
  methods: {
    forceRerenderLineChart() {
      // Remove my-component from the DOM
      this.rerenderLinechart = false;
      this.$nextTick(() => {
        // Add the component back in
        this.rerenderLinechart = true;
      });
    },
    tabChanged(num) {
      if (num == 0)
        //charline
        this.forceRerenderLineChart();
    },
    loadCurrentProject(track = true) {
      this.stopTracking();
      this.projectForm = this.project;
      if (track) this.startTracking();
    },
    loadOrCreateProject() {
      if (this.paramProjectId === 'new') {
        this.new();
      } else {
        this.loadExistingProject(this.$route.params.projectId);
      }
    },
    async loadExistingProject(projectId) {
      this.showRightMenu(false);
      await this.loadProject(projectId);
      this.loadCurrentProject();
    },
    new() {
      this.stopTracking();
      this.createNewProject().then(() => {
        this.$refs.form.resetValidation();
        this.loadCurrentProject();
        this.showRightMenu(true);
      });
    },
    async deleteCurrentProject() {
      await this.deleteProject(this.projectForm.projectId);
      this.confirmDelete = false;
      this.$router.push('/');
    },

    showPendingChangesDialog(continueCallBack) {
      this.$nextTick(() => {
        this.pendingChangesDialog = true;
      });
      this.dialogContinue = () => {
        this.pendingChangesDialog = false;
        continueCallBack();
      };
    },
    startTracking() {
      this.unwatchRef = this.$watch(
        'projectForm',
        _.debounce(this.projectTracker, 150),
        {
          deep: true,
        }
      );
    },
    stopTracking() {
      if (this.unwatchRef != null) this.unwatchRef();
    },
    projectTracker(newValue) {
      this.setProject(newValue);
      this.projectForm = newValue;
    },
    exportToCSV() {
      this.exportForecastToCSV();
    },
    store() {
      if (this.$refs.form && this.$refs.form.validate()) {
        this.stopTracking();
        this.storeProject(this.projectForm)
          .then(data => {
            this.$notify({
              title: this.title,
              text: `Project ${data.name} saved!`,
              type: 'success',
            });
          })
          .catch(() => {
            this.$notify({
              title: this.title,
              text: `It was not possible to save the project!`,
              type: 'error',
            });
          })
          .finally(() => this.startTracking());
      }
    },
    createNew() {
      const goToWizard = () => this.$router.push('/guidedanalysis/wizard');

      if (this.dirty) {
        this.showPendingChangesDialog(goToWizard);
      } else {
        goToWizard();
      }
      goToWizard;
    },
    applyScenario() {
      this.applySettings(this.configuration);
    },
    applySettings: function(config) {
      this.applyConfiguration(config).then(() => {
        this.showRightMenu(false);
      });
    },
    ...mapMutations('project', {
      setProject: mutationTypes.project.SET_PROJECT,
      setForecastChanged: mutationTypes.project.FORECAST_CHANGED,
    }),
    ...mapActions('app', ['showRightMenu', 'setRightMenuSettings']),
    ...mapActions('project', [
      'storeProject',
      'loadProject',
      'createNewProject',
      'deleteProject',
      'applyConfiguration',
      'setViewType',
    ]),
  },
};
</script>

<style scoped>
.button {
  width: 100px;
}
.viewType {
  width: 148px;
}
</style>
