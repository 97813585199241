import { ref, computed, watch } from '@vue/composition-api';
import { sortByName, name2CamelCase, idFilter, normalize } from './utils';
import { filterCatalog } from '@/store-mapping';
import _ from 'lodash';

export default function useDataSource() {
  const {
    getDataSources,
    getCatalogs,
    clearCatalogs,
    state: fc,
  } = filterCatalog;

  const gbu = ref(null);
  const scope = ref(null);
  const dumpDate = ref(null);
  const gdpOutlook = ref(null);
  const newbornOutlook = ref(null);
  const fact = ref(null);

  const dataSources = computed(() => fc.dataSources);
  const catalogs = computed(() =>
    !_.isEmpty(fc.catalogs)
      ? fc.catalogs
      : { gdpOutlook: [], newbornOutlook: [], fact: [] }
  );
  const loading = computed(() => fc.loadingDataSources);
  const loadingCatalogs = computed(() => fc.loadingOutlook);

  const gbuList = computed(() =>
    !_.isEmpty(dataSources.value) ? dataSources.value.sort(sortByName) : []
  );

  const scopeList = computed(() =>
    !_.isEmpty(gbuList.value) && gbu.value != null
      ? gbuList.value
          .filter(idFilter(gbu.value))[0]
          .scopes.map(name2CamelCase)
          .sort(sortByName)
      : []
  );

  const dumpDateList = computed(() =>
    !_.isEmpty(scopeList.value) && scope.value
      ? scopeList.value
          .filter(idFilter(scope.value))[0]
          .dump_dates.sort()
          .reverse()
      : []
  );

  const gdpOutlookList = computed(() => normalize(catalogs.value.gdpOutlook));

  const newbornOutlookList = computed(() =>
    normalize(catalogs.value.newbornOutlook)
  );

  const factList = computed(() => normalize(catalogs.value.fact));

  const isDataSourceSelected = computed(
    () =>
      gbu.value &&
      gbu.value > 0 &&
      scope.value &&
      scope.value > 0 &&
      !_.isEmpty(dumpDate.value)
  );

  getDataSources();

  const gbuChanged = () => {
    scope.value = null;
    dumpDate.value = null;
    gdpOutlook.value = null;
    newbornOutlook.value = null;
    fact.value = null;
  };

  const scopeChanged = () => {
    dumpDate.value = null;
    gdpOutlook.value = null;
    newbornOutlook.value = null;
    fact.value = null;
  };

  const dumpDateChanged = () => {
    gdpOutlook.value = null;
    newbornOutlook.value = null;
    fact.value = null;
  };

  watch(dumpDate, () => {
    if (isDataSourceSelected.value) {
      getCatalogs({
        gbuId: gbu.value,
        scopeId: scope.value,
        dumpDate: dumpDate.value,
      });
    } else {
      clearCatalogs();
    }
  });

  return {
    dataSources,
    gbu,
    scope,
    dumpDate,
    gdpOutlook,
    newbornOutlook,
    fact,
    gbuList,
    scopeList,
    dumpDateList,
    gdpOutlookList,
    newbornOutlookList,
    factList,
    gbuChanged,
    scopeChanged,
    dumpDateChanged,
    loading,
    loadingCatalogs,
    isDataSourceSelected,
  };
}
