<template>
  <v-card outlined>
    <span
      class="caption grey--text text--darken-1 ml-1 my-n2 px-1 white"
      style="position: absolute"
    >
      {{ label }}
    </span>
    <v-card-text>
      <v-text-field
        v-model="search"
        label="Search..."
        class="pt-0"
        clearable
        single-line
        prepend-inner-icon="mdi-magnify"
        :disabled="disabled"
      ></v-text-field>
      <v-progress-linear :active="loading" indeterminate></v-progress-linear>
      <v-treeview
        v-if="!loading"
        ref="treeView"
        selectable
        dense
        open-on-click
        :search="search"
        :items="treeModel"
        :value="selectedLeafs"
        @input="onSelectionChange"
        return-object
        ><template v-slot:label="{ item }">
          <span :title="item.name">{{ item.name }}</span>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    catalog: [Array],
    catalogLevels: [Array],
    value: [Object],
    label: {
      type: String,
      default: 'Tree selection',
    },
    loading: [Boolean],
    disabled: [Boolean],
  },
  data() {
    return {
      search: '',
      treeModel: [],
      leafNodesByIds: new Map(),
      selectedLeafs: [],
      valueOut: this.makeEmptyValueOut(),
    };
  },
  created() {
    this.updateModel();
  },
  watch: {
    catalog() {
      this.updateModel();
    },
    catalogLevels(newLevels, oldLevels) {
      // Explicit check needed because the way objects are hooked in upper
      // elements, this watcher is triggered even without any level change.
      if (
        newLevels.length != oldLevels.length ||
        !newLevels.every(e => oldLevels.includes(e))
      ) {
        this.updateModel();
      }
    },
    value(newValues) {
      const areEqual = this.catalogLevels.every(
        levelName =>
          newValues[levelName] &&
          this.valueOut[levelName] &&
          newValues[levelName].every(e => this.valueOut[levelName].includes(e))
      );
      if (!areEqual) {
        this.updateModel();
      }
    },
    search(newSearch) {
      if (newSearch) {
        this.$refs.treeView.updateAll(true); // expand all
      } else {
        this.$refs.treeView.updateAll(false); // collapse all
      }
    },
  },
  methods: {
    makeEmptyValueOut() {
      return this.catalogLevels.reduce((obj, name) => {
        obj[name] = [];
        return obj;
      }, {});
    },
    updateModel() {
      // Not using computed property due to parent references in treeModel.
      this.leafNodesByIds = new Map();
      this.treeModel = this.makeTreeModel(this.catalog);
      this.selectedLeafs = Array.from(this.leafNodesByIds.values()).filter(
        leaf => leaf.selected
      );
    },
    makeTreeModel(catalog, currLevel = 0, parent = null) {
      return catalog.map(item => {
        // Create a unique id for each node that's not too long.
        const id = `${currLevel}${item.name.replace(/\W/g, '').slice(0, 28)}`;
        // Use value prop to determine main selections.
        const selected =
          this.value[this.catalogLevels[currLevel]] &&
          this.value[this.catalogLevels[currLevel]].includes(item.name);
        const node = { id, name: item.name, parent, selected };

        let children = [];
        const childLevel = currLevel + 1;
        if (
          this.catalogLevels.length > childLevel &&
          item[this.catalogLevels[childLevel]]
        ) {
          children = this.makeTreeModel(
            item[this.catalogLevels[childLevel]],
            childLevel,
            node
          );
        } else {
          this.leafNodesByIds.set(node.id, node);
        }

        // Propagate main selections to children.
        const allChildrenAreUnselected = children => {
          return children.every(
            child => !child.selected && allChildrenAreUnselected(child.children)
          );
        };
        const bubbleSelectionDown = children => {
          children.forEach(child => {
            child.selected = true;
            bubbleSelectionDown(child.children);
          });
        };
        if (selected && allChildrenAreUnselected(children)) {
          bubbleSelectionDown(children);
        }

        node.children = children;
        return node;
      });
    },
    onSelectionChange(selectedLeafNodes) {
      const bubbleSelectionUp = (node, sel) => {
        node.selected = sel;
        if (node.parent) bubbleSelectionUp(node.parent, sel);
      };

      // First, mark all the selected, then mark the unselected.
      const notVisitedLeafs = new Set(this.leafNodesByIds.keys());
      for (const node of selectedLeafNodes) {
        notVisitedLeafs.delete(node.id);
        bubbleSelectionUp(node, true);
      }
      notVisitedLeafs.forEach(id =>
        bubbleSelectionUp(this.leafNodesByIds.get(id), false)
      );

      // Find out until which level the tree must be specified.
      const findDeepest = (children, lvl = 0, deepest = 0) =>
        children.reduce((acc, node) => {
          if (node.selected) {
            return Math.max(lvl, acc);
          } else if (!node.selected && node.children.length > 0) {
            return Math.max(findDeepest(node.children, lvl + 1, deepest), acc);
          }
          return Math.max(deepest, acc);
        }, deepest);
      const deepestLevelNeeded = findDeepest(this.treeModel);

      // Collect the selected ones from upper to lower levels.
      this.valueOut = this.makeEmptyValueOut();
      for (const node of selectedLeafNodes) {
        bubbleSelectionUp(node, true);
      }
      const getSelection = (children, lvl = 0) =>
        children.forEach(node => {
          if (node.selected) {
            this.valueOut[this.catalogLevels[lvl]].push(node.name);
          }
          if (deepestLevelNeeded > lvl && node.children.length > 0) {
            getSelection(node.children, lvl + 1);
          }
        });
      if (this.catalogLevels.length > 0) getSelection(this.treeModel);

      // Emit v-model in expected object form.
      this.$emit('input', this.valueOut);
    },
  },
};
</script>
