<template>
  <v-dialog
    v-model="showDialog"
    :loading="loading"
    persistent
    max-width="400"
    class="pr-10 pl-10 button"
  >
    <v-card>
      <v-card-title>
        Upload CSV Fine Tuning
      </v-card-title>
      <v-card-subtitle class="mt-2">
        Import a CSV to add the fine tuning values. Your CSV should include a
        header row, values should be separate by " , ".</v-card-subtitle
      >
      <v-card-text>
        <v-file-input v-model="fileName" truncate-length="15"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="ml-3"
          outlined
          :disabled="!allowApply"
          @click="importFineTuningCSV"
        >
          <v-icon left>mdi-file-delimited-outline</v-icon>Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { reactive, toRefs, watch } from '@vue/composition-api';
import { MONTHLY } from '@/global/groundForecast';

export default {
  name: 'UploadFineTuning',
  props: {
    show: Boolean,
  },
  setup(props, { emit }) {
    const state = reactive({
      fileName: null,
      showDialog: false,
      loading: false,
      allowApply: false,
    });

    watch(
      () => props.show,
      value => {
        state.showDialog = value;
      }
    );

    watch(
      () => state.fileName,
      value => {
        state.allowApply = value != null;
      }
    );

    const csvToArray = (str, delimiter = ',') => {
      // slice from start of text to the first \n index
      // use split to create an array from string by delimiter
      const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      const rows = str.slice(str.indexOf('\n') + 1).split('\n');
      // Map the rows
      // split values from each row into an array
      // use headers.reduce to create an object
      // object properties derived from headers:values
      // the object passed as an element of the array
      const arr = [];

      rows.forEach(row => {
        const values = row.replace('\r', '').split(delimiter);

        if (values.length <= 1) return;

        const el = headers.reduce((object, header, index) => {
          //Check if it is a forecast point time (2021-M4-F)
          if (/\d{4}-M\d{1,2}-F/.test(header)) {
            const ym = header.replace(/[^0-9-]/g, '').split('-');
            //Remove all the 0 values
            if (!isNaN(values[index]) && values[index] != '0') {
              if (!('values' in object)) {
                object['values'] = [];
              }
              object['values'].push({
                percentage: values[index],
                date: `${ym[0]}-${ym[1].padStart(2, '0')}-01`,
              });
            }
          } else {
            object[header] = values[index];
          }
          return object;
        }, {});

        if ('values' in el && el['values'].length > 0) arr.push(el);
      });
      // return the array
      return arr;
    };

    const importFineTuningCSV = () => {
      let reader = new FileReader();
      reader.readAsText(state.fileName);
      reader.onloadstart = () => (state.loading = true);
      reader.onload = function() {
        try {
          const data = csvToArray(reader.result);

          state.showDialog = false;
          emit('upload', { list: data, view: MONTHLY });
        } catch {
          this.$notify({
            title: this.title,
            text: `It was not possible to parse the CSV!`,
            type: 'error',
          });
        }

        state.loading = false;
      };
    };

    const close = () => {
      state.showDialog = false;
      emit('closed');
    };

    return {
      ...toRefs(state),
      importFineTuningCSV,
      close,
    };
  },
};
</script>
