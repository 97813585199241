import API from './API.service';

export const catalogService = {
  get,
};

function get(catalog) {
  let endpoint = `/catalog/${catalog}`;
  return API.get(endpoint);
}
