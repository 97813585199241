<template>
  <div>
    <v-overlay absolute opacity="0.05" :value="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        width="3"
        size="50"
      ></v-progress-circular>
    </v-overlay>
    <apexchart
      height="250px"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { scenarioService } from '@/services';
import { pointToDateTime } from '@/global/groundForecast';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    scenario: [Object],
  },
  data() {
    return {
      loading: false,
      series: [],
      options: {
        colors: [this.$vuetify.theme.currentTheme.accent],
        stroke: {
          width: 2,
        },
        tooltip: {
          x: {
            format: 'MMM yyyy',
          },
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return `${Math.round(value * 100)}%`;
            },
          },
        },
      },
    };
  },
  watch: {
    scenario(newScenario) {
      this.updateCurveIfValid(newScenario);
    },
  },
  created() {
    this.updateCurveIfValid(this.scenario);
  },
  methods: {
    updateCurveIfValid: _.debounce(function(scenario) {
      const isValid =
        scenario.filters &&
        moment(scenario.filters.onsetDate, 'YYYY-MM-DD', true).isValid() &&
        moment(
          scenario.filters.newNormalEndDate,
          'YYYY-MM-DD',
          true
        ).isValid() &&
        Object.entries(scenario.config).every(([, value]) => !isNaN(value));
      if (isValid) {
        this.loading = true;
        scenarioService
          .getCurve(scenario)
          .then(data => {
            this.processCurve(data);
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
    }, 600),
    processCurve(data) {
      const dataPairs = data.map(rowObj => {
        // rowObj == { "TimeKey{granularity=MONTHLY, points=[2020, 5]}": 0.9876 }
        const [timeKey, value] = Object.entries(rowObj)[0];

        const yearMonth = timeKey
          .match(/\[(.*)\]/)[1]
          .replace(/\s/g, '')
          .split(','); // [year, month]
        const datetime = pointToDateTime(yearMonth);
        return [datetime, value];
      });

      this.series = [
        {
          name: 'Impact',
          data: dataPairs,
        },
      ];
    },
  },
};
</script>
