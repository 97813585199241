<template>
  <v-container class="container pt-0">
    <v-row dense justify="end">
      <div class="caption">Forecasted 3YR CAGR</div>
    </v-row>
    <v-row dense align-content="center">
      <v-col
        v-for="i in [-1, -0.5, -0.2, -0.1, -0.05, 0, 0.05, 0.1, 0.2, 0.5, 1]"
        :key="i"
        class="overline py-0 text-center hitmapRange"
        :style="`background:${calculateHeatMapColor(i)};width:100%;`"
      >
        {{ i * 100 }}%
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { calculateHeatMapColor } from '@/global/guideAnalysis';
export default {
  name: 'HeatMapMeter',
  setup() {
    return {
      calculateHeatMapColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 440px;
}

.hitmapRange {
  height: 15px;
  line-height: 15px;
  color: var(--v-secondary-lighten1);
  // opacity: 0.3;
  cursor: default;
  // &:hover {
  //   // opacity: 1;
  // }
}
</style>
