export const MAT = 'MAT';
export const HALF_MAT = 'HALF_MAT';
export const YTD = 'YTD';
export const HALF_YTD = 'HALF_YTD';
export const MONTHLY = 'MONTHLY';
export const QUARTERLY = 'QUARTERLY';

export const viewTypeList = [
  {
    key: MAT,
    value: 'MAT',
  },
  {
    key: YTD,
    value: 'YTD',
  },
  {
    key: HALF_MAT,
    value: 'H1/H2 MAT',
  },
  {
    key: HALF_YTD,
    value: 'H1/H2 TD',
  },
  {
    key: MONTHLY,
    value: 'Monthly',
  },
  {
    key: QUARTERLY,
    value: 'Quarterly',
  },
];

export const calculateCagr = (start, end, year) => {
  const carg = (end / start) ** (1 / year) - 1;
  return !Number.isNaN(carg) ? carg : 0;
};

export const calculateGrowth = (start, end) => {
  const growth = end / start - 1;
  return !Number.isNaN(growth) ? growth : 0;
};

function lerp(a, b, u) {
  return (1 - u) * a + u * b;
}

export function calculateImputedColor(percentageValue) {
  let value = 1 - 1 / (1 + percentageValue);

  const startColor = { r: 255, g: 236, b: 179 }; //amber light
  const endColor = { r: 255, g: 179, b: 0 }; //amber dark

  var r = parseInt(lerp(startColor.r, endColor.r, value));
  var g = parseInt(lerp(startColor.g, endColor.g, value));
  var b = parseInt(lerp(startColor.b, endColor.b, value));

  return 'rgb(' + r + ',' + g + ',' + b + ')';
}

export function pointToDateTime(p, viewType) {
  if ([HALF_YTD, HALF_MAT].includes(viewType)) {
    p[1] *= 6;
  } else if (QUARTERLY === viewType) {
    p[1] *= 3;
  }

  return new Date(
    p.length > 1 ? p.join('-') + '-01' : p[0].toString()
  ).getTime();
}
