<template>
  <v-toolbar flat>
    <v-toolbar-title>{{ title }} </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-chip class="ma-2" outlined label filter>
      GBU:<span class="ml-1 font-italic">{{ gbuName }}</span>
    </v-chip>
    <v-chip class="ma-2" outlined label filter>
      Scope:<span class="ml-1 font-italic">{{ scopeName }}</span>
    </v-chip>
    <v-chip class="ma-2" outlined label>
      Fact:<span class="ml-1 font-italic">{{ fact }}</span>
    </v-chip>
    <v-chip class="ma-2" outlined label>
      Mkt. Agg. Level:<span class="ml-1 font-italic">
        {{ marketDimension || '-' }}</span
      >
    </v-chip>
    <v-chip class="ma-2" outlined label>
      Prod. Agg. Level:<span class="ml-1 font-italic">
        {{ productDimension || '-' }}</span
      >
    </v-chip>
    <v-chip class="ma-2" outlined label>
      Nielsen GTC Date:<span class="ml-1 font-italic">
        {{ nielsenDate || '-' }}</span
      >
    </v-chip>
  </v-toolbar>
</template>

<script>
import { computed, watch } from '@vue/composition-api';
import { staticCatalogs } from '@/global/filters';
import { idFilter } from '@/composables/utils';
import { filterCatalog } from '@/store-mapping';
import store from '@/store';
import _ from 'lodash';

export default {
  name: 'SettingsSummary',
  props: {
    title: String,
  },
  setup() {
    const { getDataSources, getCatalogs, state: fc } = filterCatalog;

    const dataSources = computed(() => fc.dataSources);
    const preferences = computed(
      () => store.state.project.project.config.preferences
    );
    const catalogs = computed(() =>
      !_.isEmpty(fc.catalogs) ? fc.catalogs : { fact: [] }
    );

    const gbu = computed(() => {
      return !_.isEmpty(dataSources.value)
        ? dataSources.value.filter(idFilter(preferences.value.gbuId))[0]
        : {};
    });

    const gbuName = computed(() => {
      return !_.isEmpty(gbu.value) ? gbu.value.name : preferences.value.gbuId;
    });

    const scope = computed(() => {
      return !_.isEmpty(gbu.value)
        ? gbu.value.scopes.filter(idFilter(preferences.value.scopeId))[0]
        : null;
    });

    const scopeName = computed(() => {
      return !_.isEmpty(scope.value)
        ? _.startCase(scope.value.name)
        : preferences.value.scopeId;
    });

    const fact = computed(() => {
      const cat = catalogs.value.fact.filter(
        idFilter(preferences.value.factId)
      )[0];

      return !_.isEmpty(cat) ? _.startCase(cat.name) : preferences.value.factId;
    });

    const marketDimension = computed(() => {
      return getStaticCatalogValue('marketDimension');
    });

    const productDimension = computed(() => {
      return getStaticCatalogValue('productDimension');
    });
    const nielsenDate = computed(() => {
      return preferences.value.dumpDate;
    });

    const getStaticCatalogValue = field => {
      const param = staticCatalogs[field].filter(
        f => f.key === preferences.value[field]
      )[0] || { value: '' };
      return param.value;
    };

    watch(preferences, () => getCatalogs(preferences.value), {
      deep: true,
    });

    getDataSources();

    return {
      gbu,
      gbuName,
      scope,
      scopeName,
      fact,
      marketDimension,
      productDimension,
      nielsenDate,
      preferences,
    };
  },
};
</script>
