import Vue from 'vue';
import VueRouter from 'vue-router';
import { authService } from '@/services';
import LoginForm from '@/components/auth/LoginForm.vue';
import GuidedAnalysis from '@/components/guidedAnalysis/GuidedAnalysis.vue';
import GuidedAnalysisWizard from '@/components/guidedAnalysis/Wizard.vue';
import GuidedAnalysisList from '@/components/guidedAnalysis/List.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/guidedanalysis/list' },
  { path: '/login', component: LoginForm, meta: { layout: 'login-layout' } },
  {
    path: '/logout',
    component: LoginForm,
    props: { logout: true },
    meta: { layout: 'login-layout' },
  },
  {
    path: '/project/:projectId?',
    redirect: '/guidedanalysis/:projectId?',
  },
  {
    path: '/pro',
    component: GuidedAnalysisList,
    props: { fineTuningEnabled: true },
  },
  {
    path: '/guidedanalysis/list',
    component: GuidedAnalysisList,
    props: { fineTuningEnabled: false },
  },
  { path: '/guidedanalysis/wizard', component: GuidedAnalysisWizard },
  {
    path: '/guidedanalysis/:projectId?',
    component: GuidedAnalysis,
    props: { fineTuningEnabled: false },
  },
  { path: '*', redirect: '/' },
];

let routerInstance = null;

function createInstance() {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach((to, from, next) => {
    // Redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = authService.checkAuthenticated();

    if (authRequired && !loggedIn) {
      return next('/login');
    }
    next();
  });

  return router;
}

// Using a singleton pattern to export the router.
// With that, the router can be lazy initialized.
export default {
  getInstance: function() {
    if (!routerInstance) {
      routerInstance = createInstance();
    }
    return routerInstance;
  },
};
