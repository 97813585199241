var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-data-table',{staticClass:"elevation-1 myclass",attrs:{"headers":_vm.headers,"items":_vm.dataTable,"loading":_vm.loadingForecast,"hide-default-header":"","hide-default-footer":"","fixed-header":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('h3',{staticClass:"ml-5"},[_vm._v(" MAT: "),_c('span',{staticClass:"ml-1 font-italic"},[_vm._v(_vm._s((_vm.lastMonth + " " + _vm.matYear)))])])]),_c('v-row',[_c('v-col',{attrs:{"align-self":"start"}},[_c('v-select',{attrs:{"items":_vm.dataSourceList,"item-value":"id","item-text":"name","hide-details":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.GROUND_FORECAST)?_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.id !== _vm.GROUND_FORECAST && item.id !== _vm.FORECAST)?_c('span',{style:(("color:" + (_vm.scenarioColorHash(item))))},[_vm._v(" "+_vm._s(((item.id) + "-" + (item.name)))+" ")]):_vm._e(),(item.id === _vm.FORECAST)?_c('span',{staticClass:"blue-grey--text"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.id == _vm.GROUND_FORECAST)?_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.id !== _vm.GROUND_FORECAST && item.id !== _vm.FORECAST)?_c('span',{style:(("color:" + (_vm.scenarioColorHash(item))))},[_vm._v(" "+_vm._s(((item.id) + "-" + (item.name)))+" ")]):_vm._e(),(item.id === _vm.FORECAST)?_c('span',{staticClass:"blue-grey--text"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}}]),model:{value:(_vm.dataSource),callback:function ($$v) {_vm.dataSource=$$v},expression:"dataSource"}})],1),_c('v-spacer'),_c('v-col',[_c('heat-map-meter')],1)],1)],1)]},proxy:true},{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',{staticClass:"header"},[(!_vm.loadingForecast)?_c('th',{staticClass:"text-center"},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[(_vm.stack.length > 0)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.drillOut()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-circle-outline")]),_vm._v("Go back ")],1):_vm._e()],1)],1):_vm._e(),_vm._l((headers),function(h){return _c('th',{key:h.value,staticClass:"text-center"},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[(h.value !== 'all' && !_vm.loadingForecast)?_c('v-checkbox',{key:h.value,staticClass:"mt-0",attrs:{"dense":"","hide-details":"","value":h.value,"disabled":_vm.checkDisableLineChartCols(h.value)},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.drillDataCellEnabled(null, h.value))?_c('v-btn',{staticClass:"mt-n1",attrs:{"text":""},on:{"click":function($event){return _vm.drillDataCell(null, h.value)}}},[_vm._v(_vm._s(h.text)+" ")]):_c('span',{staticClass:"font-weight-medium text-sm-body-2",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(h.text)+" ")])]},proxy:true}],null,true),model:{value:(_vm.lineChartCols),callback:function ($$v) {_vm.lineChartCols=$$v},expression:"lineChartCols"}}):(h.value === 'all')?_c('span',{staticClass:"text-center font-weight-bold font-italic text-uppercase text-sm-body-2"},[_vm._v(_vm._s(h.text))]):_vm._e()],1)],1)})],2)])]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{key:index},[_c('v-checkbox',{key:item[_vm.groupRowsBy],staticClass:"mt-0",attrs:{"dense":"","hide-details":"","value":item[_vm.groupRowsBy],"disabled":_vm.checkDisableLineChartRows(item[_vm.groupRowsBy])},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{attrs:{"align":"start"}},[(_vm.drillDataCellEnabled(item[_vm.groupRowsBy]))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.drillDataCell(item[_vm.groupRowsBy])}}},[_c('span',[_vm._v(_vm._s(item[_vm.groupRowsBy])+" ")])]):_c('span',{staticClass:"font-weight-medium text-sm-body-2",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item[_vm.groupRowsBy])+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm.lineChartRows),callback:function ($$v) {_vm.lineChartRows=$$v},expression:"lineChartRows"}})],1),_vm._l((_vm.dynamicColumns),function(dc){return _c('td',{key:dc,style:((" text-align: center; background:" + (_vm.calculateHeatMapColor(
                item[(dc + "-cagrs")].t3Cagr
              )) + ";"))},[(_vm.drillDataCellEnabled(item[_vm.groupRowsBy], dc))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.drillDataCell(item[_vm.groupRowsBy], dc)}}},[_c('heat-map-cell',{key:dc,attrs:{"item":item,"property":dc}})],1):_c('heat-map-cell',{key:dc,attrs:{"item":item,"property":dc}})],1)}),_c('td',{staticClass:"text-center font-weight-medium font-italic"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("numberFormat")(item.all,0)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("numberFormat")(item.all,0)))])])],1)],2)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"font-weight-bold text-uppercase font-italic text--secondary pl-9",on:{"click":function($event){return _vm.drillDataCell()}}},[_vm._v(" "+_vm._s(_vm.tableSumary[_vm.groupRowsBy])+" ")]),(!_vm.loadingForecast)?_vm._l((_vm.dynamicColumns),function(dc){return _c('td',{key:dc,staticClass:"font-weight-medium font-italic"},[_c('v-layout',{key:dc,attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("numberFormat")(_vm.tableSumary[dc],0))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("numberFormat")(_vm.tableSumary[dc],0)))])])],1)],1)}):_vm._e(),(!_vm.loadingForecast)?_c('td',{staticClass:"font-weight-medium font-italic"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("numberFormat")(_vm.tableSumary.all,0)))])]}}],null,false,1025668490)},[_c('span',[_vm._v(_vm._s(_vm._f("numberFormat")(_vm.tableSumary.all,0)))])])],1)],1):_vm._e()],2)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }