<template>
  <v-select
    v-if="type === 'STRING'"
    :value="value"
    @input="updateValue"
    :items="accepted"
    :label="label"
    :hint="hint"
    :disabled="disabled || readonly"
    :rules="requiredRules"
    @keydown.enter.prevent
    v-bubble.focus.blur
    hide-details="auto"
    outlined
    dense
  ></v-select>
  <v-text-field
    v-else-if="type === 'NUMBER'"
    :value="value"
    @input="updateValue"
    :label="label"
    :hint="hint"
    :disabled="disabled"
    :readonly="readonly"
    :rules="requiredNumberRules"
    v-bubble.focus.blur
    type="number"
    hide-details="auto"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: [Number, String, Date],
    type: {
      type: String,
      required: true,
      validator: v => ['NUMBER', 'STRING'].includes(v),
    },
    accepted: [Array, Object],
    format: String,
    label: String,
    hint: String,
    disabled: Boolean,
    readonly: Boolean,
  },
  methods: {
    updateValue(val) {
      // Emit default event for enabling v-model in this component
      this.$emit(
        'input',
        this.format == 'float' ? val ?? parseFloat(val) : val
      );
    },
  },
};
</script>
