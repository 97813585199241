<template>
  <v-row justify="center">
    <v-dialog :value="show" fullscreen scrollable persistent max-width="600px">
      <v-card>
        <v-toolbar dark color="primary" style="flex: none">
          <v-btn icon dark :disabled="waitingResult" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Scenario Builder</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            text
            v-if="!readOnly"
            :loading="waitingResult"
            :disabled="waitingResult || !formValid"
            @click="confirm"
            >{{ scenario ? 'Save' : 'Add' }}</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="formValid" @submit.prevent="confirm">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="predefinedScenarioId"
                    :items="Array.from(predefinedMap.values())"
                    item-text="title"
                    item-value="id"
                    label="Scenario"
                    placeholder="Choose a scenario"
                    :loading="loadingPredefined"
                    :disabled="waitingResult || readOnly"
                    :rules="[v => !!v]"
                    @change="applyDefaultParameters"
                    @keydown.enter.prevent
                    hide-details="auto"
                    outlined
                    dense
                  ></v-select>
                  <div class="caption mt-2">
                    {{
                      this.predefinedScenario
                        ? this.predefinedScenario.scenario_description
                        : ''
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row v-if="!!predefinedScenario">
                  <v-col cols="12" md="3">
                    <v-row>
                      <v-col cols="12"
                        ><span class="subtitle-1">Apply scenario to</span
                        ><v-divider></v-divider
                      ></v-col>
                      <v-col cols="12">
                        <hierarchy-filters
                          :filters.sync="filters"
                          :projectConfig="configForFilters"
                        ></hierarchy-filters>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col cols="12"
                        ><span class="subtitle-1">Scenario parameters</span
                        ><v-divider></v-divider
                      ></v-col>
                      <v-col cols="6">
                        <date-field
                          label="Onset date"
                          v-model="onsetDate"
                          :disabled="waitingResult"
                          :readonly="readOnly"
                        ></date-field>
                      </v-col>
                      <v-col cols="6">
                        <date-field
                          label="New normal end month"
                          format="month"
                          v-model="newNormalEndMonth"
                          :min="minEndDateLimit"
                          :disabled="waitingResult"
                          :readonly="readOnly"
                        ></date-field>
                      </v-col>
                      <v-col cols="12">
                        <h3 class="mt-2">Impact overview</h3>
                        <scenario-curve
                          :scenario="currentScenario"
                        ></scenario-curve>
                      </v-col>
                      <v-col cols="6">
                        <scenario-parameter
                          v-for="param in predefinedParameters[0]"
                          :key="param.parameter_name"
                          :type="param.type"
                          :format="param.format"
                          :accepted="param.accepted"
                          :label="param.parameter_name | splitAndCapitalize"
                          v-model="parameters[param.parameter_name]"
                          :disabled="waitingResult"
                          :readonly="readOnly"
                          @focus="focusedParam = param"
                          @blur="focusedParam = null"
                        ></scenario-parameter
                      ></v-col>
                      <v-col cols="6">
                        <scenario-parameter
                          v-for="param in predefinedParameters[1]"
                          :key="param.parameter_name"
                          :type="param.type"
                          :format="param.format"
                          :accepted="param.accepted"
                          :label="param.parameter_name | splitAndCapitalize"
                          v-model="parameters[param.parameter_name]"
                          :disabled="waitingResult"
                          :readonly="readOnly"
                          @focus="focusedParam = param"
                          @blur="focusedParam = null"
                        ></scenario-parameter>
                        <scenario-parameter
                          type="NUMBER"
                          format="float"
                          label="New normal"
                          v-model="newNormal"
                          :disabled="waitingResult"
                          :readonly="readOnly"
                          @focus="
                            focusedParam = {
                              parameter_description:
                                'New normal: extra sales [%] on top of the ground forecasts after the scenario ends (ie. lasting effect).',
                            }
                          "
                          @blur="focusedParam = null"
                        ></scenario-parameter>
                      </v-col>
                      <v-col cols="12"
                        ><p>
                          {{
                            focusedParam
                              ? focusedParam.parameter_description
                              : ''
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-container>
            <!-- Enable sending the form with Enter -->
            <input hidden type="submit" />
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ScenarioParameter from './ScenarioParameter';
import ScenarioCurve from './ScenarioCurve';
import HierarchyFilters from '@/components/custom/HierarchyFilters';
import DateField from '@/components/custom/DateField';
import { SUB_SEGMENT } from '@/global/filters';
import moment from 'moment';
import _ from 'lodash';

function partition(array, predicate) {
  return array.reduce(
    (acc, e) => {
      acc[predicate(e) ? 0 : 1].push(e);
      return acc;
    },
    [[], []]
  );
}

export default {
  name: 'ScenarioDialogForm',
  components: { DateField, ScenarioParameter, ScenarioCurve, HierarchyFilters },
  // If prop 'scenario' is null, dialog will be in scenario creation mode.
  // Prop 'readOnly' being true only makes sense with 'scenario' not null.
  props: ['show', 'scenario', 'filtersSuggestion', 'readOnly'],
  data() {
    return {
      predefinedScenarioId: null,
      onsetDate: '',
      newNormalEndMonth: '',
      newNormal: 0,
      filters: {},
      parameters: {},
      focusedParam: '',
      formValid: null,
    };
  },
  watch: {
    show(show) {
      if (show) {
        // At first show, the form has not yet been created by the dialog.
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
        if (this.scenario) {
          // Editing existing scenario.
          this.predefinedScenarioId = this.scenario.predefinedScenarioId;
          this.onsetDate = this.scenario.filters.onsetDate;
          this.newNormalEndMonth = moment(
            this.scenario.filters.newNormalEndDate
          ).format('YYYY-MM');
          this.newNormal = this.scenario.filters.newNormal;
          this.filters = this.scenario.filters;
          this.parameters = this.scenario.config;
          // Backward compatilibility (for scenarios created ~< 13/May)
          if ({}.hasOwnProperty.call(this.parameters, 'new_normal')) {
            this.newNormal = this.parameters.new_normal;
            delete this.parameters.new_normal;
          }
        } else {
          // New scenario creation, reset whole form.
          Object.assign(this.$data, this.$options.data());
          if (this.filtersSuggestion) {
            this.filters = this.filtersSuggestion;
          }
          this.applyDefaultParameters();
        }
      }
    },
  },
  computed: {
    ...mapState('scenario', {
      loadingPredefined: state => state.loadingPredefined,
      predefinedMap: state => state.predefined,
      loadingCreate: state => state.loadingCreate,
      loadingUpdate: state => state.loadingUpdate,
    }),
    ...mapState('project', {
      projectConfiguration: state => state.project.config.preferences,
      projectId: state => state.project.projectId,
    }),
    configForFilters() {
      // For scenario filters there is no marketDimension (user chooses among the
      // whole hierarchy) and user can always choose down to SUB_SEGMENT filter.
      return {
        gbuId: this.projectConfiguration.gbuId,
        scopeId: this.projectConfiguration.scopeId,
        dumpDate: this.projectConfiguration.dumpDate,
        productDimension: SUB_SEGMENT,
      };
    },
    predefinedScenario() {
      return this.predefinedMap.get(this.predefinedScenarioId);
    },
    predefinedParameters() {
      if (this.predefinedScenario && this.predefinedScenario.parameters) {
        let [durationParams, otherParams] = partition(
          this.predefinedScenario.parameters,
          param => param.parameter_name.includes('uration')
        );
        return [durationParams, otherParams];
      }
      return [[], []];
    },
    newNormalEndDate() {
      return moment(this.newNormalEndMonth)
        .endOf('month')
        .format('YYYY-MM-DD');
    },
    currentScenario() {
      let config = {};
      for (const [name, val] of Object.entries(this.parameters)) {
        let format = this.predefinedParameters
          .flat()
          .find(pre => pre.parameter_name == name).format;
        config[name] = format == 'float' ? parseFloat(val ? val : 0) : val;
      }
      return {
        config,
        filters: {
          onsetDate: this.onsetDate,
          newNormalEndDate: this.newNormalEndDate,
          newNormal: parseFloat(this.newNormal ? this.newNormal : 0),
        },
        predefinedScenarioId: this.predefinedScenarioId,
      };
    },
    minEndDateLimit() {
      if (this.predefinedParameters[0].length > 0) {
        const totalWeeks = this.predefinedParameters[0]
          .map(param => parseFloat(this.parameters[param.parameter_name]))
          .map(weeks => (weeks ? weeks : 0))
          .reduce((a, b) => a + b, 0);
        if (totalWeeks > 0 && this.onsetDate) {
          const minDate = moment(this.onsetDate).add(totalWeeks, 'week');
          if (minDate.isValid()) return minDate.format('YYYY-MM-DD');
        }
      }
      return this.onsetDate;
    },
    waitingResult() {
      return this.loadingCreate || this.loadingUpdate;
    },
  },
  methods: {
    ...mapActions('scenario', {
      createScenario: 'create',
      updateScenario: 'update',
    }),
    applyDefaultParameters() {
      let parameters = {};
      for (const param of this.predefinedParameters.flat()) {
        parameters[param.parameter_name] = param.default_value;
      }
      this.parameters = parameters;
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
    buildScenarioCommonPayload() {
      const scenario = {
        ...this.currentScenario,
        projectId: this.projectId,
        scope: this.projectConfiguration
          ? this.projectConfiguration.scope
          : null,
      };
      // Adding this.filters here, instead of inside currentScenario(),
      // so the currentScenario computed property is not refreshed when
      // filters change (less refreshes to the scenario-curve).
      scenario.filters = {
        ...this.filters,
        onsetDate: scenario.filters.onsetDate,
        newNormalEndDate: scenario.filters.newNormalEndDate,
        newNormal: parseFloat(scenario.filters.newNormal),
      };
      return scenario;
    },
    confirm() {
      if (!this.$refs.form.validate()) return;

      let payload = this.buildScenarioCommonPayload();
      let name = this.predefinedMap.get(this.predefinedScenarioId).title;
      if (this.scenario) {
        this.updateScenario({
          ...payload,
          scenarioId: this.scenario.scenarioId,
          active: this.scenario.active,
        })
          .then(() => {
            this.$notify({
              title: 'Update Scenario',
              text: `${name} scenario updated!`,
              type: 'success',
            });
            this.close();
          })
          .catch(() => null); // notification already handled in API
      } else {
        this.createScenario({ ...payload, active: true })
          .then(() => {
            this.$notify({
              title: 'New Scenario',
              text: `${name} scenario added!`,
              type: 'success',
            });
            this.close();
          })
          .catch(() => null); // notification already handled in API
      }
    },
    close() {
      // Emit event for :show.sync usage in parent.
      this.$emit('update:show', false);
    },
  },
  filters: {
    splitAndCapitalize(string) {
      return _.startCase(string);
    },
  },
};
</script>
