import API from './API.service';

export const projectService = {
  create,
  get,
  update,
  remove,
  getAll,
  getGroundForecast,
  getLatestGroundForecastId,
  getForecastTable,
  getFineTuningCSV,
  getForecastTableCSV,
  getForecastTableExcel,
  getForecastTableZIP,
  setFinetuning,
  removeFinetuning,
};

function create(project) {
  let endpoint = `/project`;

  return API.post(endpoint, project);
}

function get(projectId) {
  let endpoint = `/project/${projectId}`;
  return API.get(endpoint);
}

function update(project) {
  let endpoint = `/project/${project.projectId}`;
  return API.post(endpoint, project);
}

function remove(projectId) {
  let endpoint = `/project/${projectId}`;
  return API.delete(endpoint);
}

function getAll() {
  let endpoint = `/project/list`;
  return API.get(endpoint);
}

function getGroundForecast(params) {
  let endpoint = `/ground-forecast`;
  return API.post(endpoint, params);
}

function getLatestGroundForecastId({ gbuId, scopeId, dumpDate }) {
  let endpoint = `/forecast-table/latest-ground-forecast-id?gbuId=${gbuId}&scopeId=${scopeId}&dumpDate=${dumpDate}`;
  return API.get(endpoint);
}

function getForecastTable(groundForecastParameters) {
  let endpoint = `/forecast-table`;
  return API.post(endpoint, groundForecastParameters);
}

function getForecastTableCSV(groundForecastParameters) {
  let endpoint = `/forecast-table/csv`;
  return API.post(endpoint, groundForecastParameters);
}

function getForecastTableZIP(groundForecastParameters) {
  let endpoint = `/forecast-table/zip`;
  return API.postFile(endpoint, groundForecastParameters);
}

function getForecastTableExcel(groundForecastParameters) {
  let endpoint = `/forecast-table/excel`;
  return API.postFile(endpoint, groundForecastParameters);
}

function getFineTuningCSV(groundForecastParameters) {
  let endpoint = `/finetuning/csv`;
  return API.post(endpoint, groundForecastParameters);
}

function setFinetuning(scopeId, factId, dumpDate, fineTuningParameters) {
  let endpoint = `/finetuning/${scopeId}/${factId}/${dumpDate}`;
  return API.postFile(endpoint, fineTuningParameters);
}

function removeFinetuning(scopeId, factId, dumpDate, fineTuningParameters) {
  let endpoint = `/finetuning/${scopeId}/${factId}/${dumpDate}`;
  return API.delete(endpoint, fineTuningParameters);
}
