<template>
  <v-layout justify-center>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="font-weight-medium">
          {{ defaultValue || 0 | numberFormat(0) }}
        </span>
      </template>
      <table>
        <tr>
          <td>Ground:</td>
          <td class="text-right">
            {{ defaultValue | numberFormat(0) }}
          </td>
        </tr>
        <tr>
          <td>Hist. CAGR:</td>
          <td class="text-right">
            {{ histCagr || 0 | percentage }}
          </td>
        </tr>
        <tr>
          <td>3Y CAGR:</td>
          <td class="text-right">
            {{ t3Cagr || 0 | percentage }}
          </td>
        </tr>
        <tr>
          <td>5Y CAGR:</td>
          <td class="text-right">
            {{ t5Cagr || 0 | percentage }}
          </td>
        </tr>
      </table>
    </v-tooltip>
  </v-layout>
</template>

<script>
import { computed } from '@vue/composition-api';
export default {
  name: 'heatMapCell',
  props: {
    item: Object,
    property: String,
  },
  setup(props) {
    return {
      defaultValue: computed(() => props.item[props.property]),
      histCagr: computed(() => props.item[`${props.property}-cagrs`].histCagr),
      t3Cagr: computed(() => props.item[`${props.property}-cagrs`].t3Cagr),
      t5Cagr: computed(() => props.item[`${props.property}-cagrs`].t5Cagr),
    };
  },
};
</script>
