<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" small left color="error" class="checkType-icon">
        mdi-alert-outline
      </v-icon>
    </template>
    <span>For all underlying forecasts:</span>
    <ul>
      <li v-for="(d, index) in data" :key="d + '_' + index">
        {{ d }}
      </li>
    </ul>
  </v-tooltip>
</template>

<script>
import { computed } from '@vue/composition-api';
export default {
  name: 'CheckTypeAlerts',
  props: {
    data: Array,
  },
  setup(props) {
    return {
      defaultValue: computed(() => props.checkTypes),
    };
  },
};
</script>
